<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-select
          placeholder="扩展搜索"
          v-model="parmes.versionType"
          filterable
          clearable
          @change="getMessage(true)"
        >
          <el-option
            v-for="(item, index) in selectList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button
          size="mini"
          icon="el-icon-plus"
          @click="
            fromInit();
            from_type = `add`;
            show = true;
          "
        >
          新增
        </el-button>

        <el-button size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <template v-slot:user-edit="{ data }">
        <div class="flex-center-center">
          {{ data.rows[data.keys.key] }}

          <el-button
            style="width: 29px; margin-left: 10px; flex-shrink: 0"
            @click="data.keys.click(data.rows, data.keys)"
            v-if="data.rows.role == 'main'"
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
          ></el-button>
        </div>
      </template>
      <template v-slot:bnts="{ data }">
        <div class="flex-center-center">
          <el-button
            v-show="
              value.show == undefined ? true : value.show(data.rows, data.keys)
            "
            v-for="(value, index) in data.keys.bnts"
            :key="index"
            style="width: 29px; margin-left: 10px; flex-shrink: 0"
            @click="value.click(data.rows, data.keys)"
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
          ></el-button>
        </div>
      </template>
    </div-table>

    <el-dialog
      :title="from_type == 'add' ? `新增` : `修改`"
      :visible.sync="show"
      width="50%"
    >
      <div style="max-height: 80vh; overflow-y: auto; padding-right: 15px">
        <el-form lable-postition="right" ref="form" label-width="120px">
          <el-form-item
            v-for="(value, index) in from_items"
            v-show="value.show == undefined ? true : value.show(value)"
            :label="value.label"
            :key="index"
          >
            <el-input
              v-if="value.type == 'textarea'"
              type="textarea"
              style="margin-bottom: 10px"
              v-model="from[value.key]"
              placeholder="请输入"
            ></el-input>

            <el-select
              v-else-if="value.type == 'select'"
              style="width: 100%"
              placeholder="扩展搜索"
              v-model="from[value.key]"
              filterable
              clearable
              @change="value.change && value.change()"
            >
              <el-option
                v-for="(item, index) in value.selectList || []"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-date-picker
              style="width: 100%"
              v-else-if="value.type == 'date'"
              v-model="from[value.key]"
              type="date"
              placeholder="Pick a Date"
              value-format="yyyy-MM-dd hh:mm:ss"
            />
            <el-input
              v-else
              style="margin-bottom: 10px"
              v-model="from[value.key]"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
class tableObj {
  constructor(obj = {}) {
    obj = {
      fromList: [],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      loading: false,
      ...obj,
    };

    Object.keys(obj).forEach((key) => {
      this[key] = obj[key];
    });
  }
}
import divTable from "../components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data() {
    return {
      parmes: {},
      selectList: [
        {
          label: `app`,
          value: `app`,
        },
        {
          label: `core`,
          value: `core`,
        },
      ],
      userMessage: {},
      loading: false,
      fromList: [
        {
          label: "操作系统",
          key: "os",
        },
        {
          label: "操作系统位数",
          key: "osBit",
        },
        {
          label: "cpu架构",
          key: "cpu",
        },
        {
          label: "升级类型",
          key: "versionType",
        },
        {
          label: "内核版本号",
          key: "coreVersion",
        },
        {
          label: "内核版本对应的真实内核版本",
          key: "coreRealVersion",
        },
        {
          label: "版本号",
          key: "version",
        },
        {
          label: "升级标题",
          key: "upgradeTitle",
        },
        {
          label: "升级内容",
          key: "upgradeDesc",
          slot: "copy",
        },
        {
          label: "发布时间",
          key: "releaseTime",
        },
        {
          label: "下载地址",
          key: "downloadUrl",
          slot: "copy",
        },
        {
          label: "是否强制升级",
          key: "forceUpgrade",
          transValue: (value) => {
            let map = {
              1: `是`,
              2: `否`,
            };
            return map[value] || `-`;
          },
        },
        {
          label: "创建时间",
          key: "createTime",
        },
        {
          label: "更新时间",
          key: "updateTime",
        },
        // {
        //   width: "200px",
        //   label: `操作`,
        //   slot: "bnts",
        //   bnts: [
        //     {
        //       icon: "el-icon-plus",
        //       title: `添加用户`,
        //       show: (obj) => {
        //         return true;
        //       },
        //       click: (obj) => {
        //         this.show = true;
        //         this.from = obj;
        //       },
        //     },
        //   ],
        // },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,

      checkValue: "staff",
      checkValueList: ["staff", "manager", "admin"],

      child_table: new tableObj(),

      from_type: "add",
      show: false,
      from: {},
      from_items: [
        {
          label: "操作系统",
          key: "os",
        },
        {
          label: "操作系统位数",
          key: "osBit",
        },
        {
          label: "cpu架构",
          key: "cpu",
        },
        {
          label: "升级类型",
          key: "versionType",
          type: "select",
          selectList: [
            {
              label: `app`,
              value: `app`,
            },
            {
              label: `core`,
              value: `core`,
            },
          ],
        },
        {
          label: "内核版本号",
          key: "coreVersion",
        },
        {
          label: "内核版本对应的真实内核版本号",
          key: "coreRealVersion",
        },
        {
          label: "版本号",
          key: "version",
        },
        {
          label: "升级标题",
          key: "upgradeTitle",
        },
        {
          label: "升级内容",
          key: "upgradeDesc",
        },
        {
          label: "发布时间",
          key: "releaseTime",
          type: "date",
        },
        {
          label: "下载地址",
          key: "downloadUrl",
        },
        {
          label: "是否强制升级",
          key: "forceUpgrade",
          type: "select",
          selectList: [
            {
              label: `是`,
              value: `1`,
            },
            {
              label: `否`,
              value: `2`,
            },
          ],
        },
        {
          label: "创建时间",
          key: "createTime",
          type: "date",
        },
        {
          label: "更新时间",
          key: "updateTime",
          type: "date",
        },
      ],
    };
  },
  async mounted() {
    this.getMessage();
    this.reSetParmes();
  },

  watch: {},
  methods: {
    fromInit() {
      this.from = {
        name: "",
        categoryId: "",
      };
    },
    async submit() {
      const { data, code, msg } = await this.$apis.post(
        `/${this.from_type == `add` ? `admin_add_version` : ``}`,
        this.from
      );
      console.log(data, code);
      if (code == 0) {
        this.$message.success(`成功`);
        this.getMessage();
        this.show = false;
      } else {
        this.$message.error(msg);
      }
    },
    reSetParmes() {
      this.parmes = {
        name: "",
        categoryId: ``,
      };
    },
    handleClose(done) {
      return done();
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
      } = await this.$apis.get(`/admin_version_list`, {
        page_index: this.pageValue,
        page_size: this.pageSize,
        ...this.parmes,
      });
      if (code == 0) {
        this.fromData = rows;

        this.total = total;
      }

      this.loading = false;
    },
    // 获取用户信息
    async getUserMessage(id) {
      const {
        code,
        data: { user },
      } = await this.$apis.get(`/admin_get_main_user_by_id`, {
        id: id,
      });

      console.log(code, user);
      if (code == 0) {
        return {
          info: true,
          data: user,
        };
      }
      return {
        info: false,
      };
    },
    // 用户用户下级信息
    async getChildMessage(boole = true) {
      let url = this.checkMap[this.checkValue].url;
      let id = this.userMessage.id;
      this.child_table.loading = true;
      let info_admin = this.checkValue == "admin";

      const { code, data } = await this.$apis.get(url, {
        id,
      });

      if (code == 0) {
        this.child_table.fromList = [];
        this.child_table.fromData = [];

        let array = info_admin ? data?.admin : data;
        let fromListMap = [];

        Object.keys(array[0] || []).forEach((key) => {
          fromListMap.push({
            label: key,
            key: key,
          });
        });
        this.child_table.fromList = fromListMap;
        this.child_table.fromData = array;
      }
      this.child_table.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
