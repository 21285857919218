<template>
  <div class="panel-ranking">
    <div>
      <div class="table-title">用户活跃列表</div>
      <div-table H="300px" :fromList="columns1" :fromData="userList" :pageSize="pageSize" :pageValue="pageValue"
        :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
        @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      </div-table>
    </div>

    <div class="table" v-for="table in tableList">
      <div class="table-title">{{ table.title }}</div>
      <div-table :fromList="table.columns" :fromData="table.list" :info-page="false" H="300px"></div-table>
    </div>
  </div>
</template>

<script>
import divTable from '../components/div-table.vue'

const columns1 = [
  {
    key: 'userName',
    label: '账号名称',
    minWidth: '150px'
  },
  {
    key: 'email',
    label: '邮箱名称',
    minWidth: '150px'
  },
  {
    key: 'operateTime',
    label: '活跃时间',
    minWidth: '250px'
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px'
  }
]
const columns = [
  {
    key: 'userName',
    label: '用户名',
    minWidth: '150px'
  },
  {
    key: 'leftAmount',
    label: '余额($)',
    minWidth: '150px'
  },
  {
    key: 'totalAmount',
    label: '总金额($)',
    minWidth: '150px'
  },
  {
    key: 'totalCount',
    label: '窗口数量',
    minWidth: '150px'
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px'
  }
]

export default {
  name: 'PanelRanking',
  components: {
    divTable,
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    tableList ({ data }) {
      return [
        {
          title: '创建窗口前20',
          columns,
          list: data?.create_win_top_user_list || []
        },
        {
          title: '已打开窗口前20',
          columns,
          list: data?.open_win_top_user_list || []
        },
        {
          title: '今日打开次数前20名',
          columns: columns.map(item => ({ ...item, label: item.key === 'totalCount' ? '打开次数' : item.label })),
          list: data?.open_times_top_user_list || []
        }
      ]
    }
  },
  data () {
    return {
      columns1,
      userList: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    }
  },
  mounted () {
    this.getMessage();
  },
  methods: {
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_user_today_active_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.userList = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 15px;

  &-title {
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: bold;
    color: #36A3F7;
  }
}
</style>