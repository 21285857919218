var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      style: {
        backgroundColor:
          _vm.sideTheme === "theme-dark"
            ? _vm.variables.menuBackground
            : _vm.variables.menuLightBackground,
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "sidebar-title",
                      staticStyle: { transform: "translateX(-26px)" },
                      style: {
                        color:
                          _vm.sideTheme === "theme-dark"
                            ? _vm.variables.logoTitleColor
                            : _vm.variables.logoLightTitleColor,
                      },
                    },
                    [_vm._v(" Roxy ")]
                  ),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _c("div", { staticClass: "flex-d" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "sidebar-title",
                        style: {
                          color:
                            _vm.sideTheme === "theme-dark"
                              ? _vm.variables.logoTitleColor
                              : _vm.variables.logoLightTitleColor,
                        },
                      },
                      [_vm._v(" Roxy浏览器管理后台 ")]
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }