var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar },
                    })
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: require("../../assets/images/user.png") },
                    }),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.dialogFormVisible = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("修改密码")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", [_vm._v("退出登录")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogFormVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "旧密码", prop: "old_pwd" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", autocomplete: "off" },
                    model: {
                      value: _vm.ruleForm.old_pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "old_pwd", $$v)
                      },
                      expression: "ruleForm.old_pwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "pass" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", autocomplete: "off" },
                    model: {
                      value: _vm.ruleForm.pass,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "pass", $$v)
                      },
                      expression: "ruleForm.pass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "checkPass" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", autocomplete: "off" },
                    model: {
                      value: _vm.ruleForm.checkPass,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "checkPass", $$v)
                      },
                      expression: "ruleForm.checkPass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }