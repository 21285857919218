import Vue from "vue";
import Router from "vue-router";

/* Layout */
import Layout from "@/layout";

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
// export const constantRoutes = [
//   {
//     path: "/redirect",
//     component: Layout,
//     hidden: true,
//     children: [
//       {
//         path: "/redirect/:path(.*)",
//         component: () => import("@/views/redirect"),
//       },
//     ],
//   },
//   {
//     path: "/login",
//     component: () => import("@/views/login"),
//     hidden: true,
//   },
//   {
//     path: "/register",
//     component: () => import("@/views/register"),
//     hidden: true,
//   },
//   {
//     path: "/404",
//     component: () => import("@/views/error/404"),
//     hidden: true,
//   },
//   {
//     path: "/401",
//     component: () => import("@/views/error/401"),
//     hidden: true,
//   },
//   {
//     path: "",
//     component: Layout,
//     redirect: "index",
//     children: [
//       {
//         path: "index",
//         component: () => import("@/views/index"),
//         name: "Index",
//         meta: { title: "首页", icon: "dashboard", affix: true },
//       },
//     ],
//   },
//   {
//     path: "/user",
//     component: Layout,
//     hidden: true,
//     redirect: "noredirect",
//     children: [
//       {
//         path: "profile",
//         component: () => import("@/views/system/user/profile/index"),
//         name: "Profile",
//         meta: { title: "个人中心", icon: "user" },
//       },
//     ],
//   },
// ];

// [
//   {
//     path: "/",
//     name: "/home",
//     meta: { title: "index", icon: "dashboard" },
//     children: [
//       {
//         path: "/index",
//         meta: { title: "用户1", icon: "dashboard", affix: true },
//         name: "/index",
//       },
//       {
//         path: "/user",
//         meta: { title: "用户2", icon: "dashboard", affix: true },
//         name: "user",
//       },
//     ],
//   },
// ]
export const constantRoutes = [
  {
    hidden: true,
    name: `login`,
    path: "/login",
    component: () => import("@/views/login"),
  },
  {
    hidden: true,
    name: `404`,
    path: "/404",
    component: () => import("@/views/error/404"),
  },
  {
    hidden: true,
    name: `401`,
    path: "/401",
    component: () => import("@/views/error/401"),
  },
  // {
  //   path: "/",
  //   name: "/",
  //   component: Layout,
  //   meta: { title: "windows配置", icon: "system" },
  //   children: [
  //     {
  //       path: "list",
  //       component: () => import("@/views/huoshan/windows-list"),
  //       name: "list",
  //       noCache: true,
  //       meta: { title: "windows列表", icon: "system", affix: true },
  //     },
  //   ],
  // },
  {
    path: "/data",
    component: Layout,
    meta: { title: "数据分析", icon: "druid" },
    children: [
      {
        path: "statistics",
        component: () => import("@/views/huoshan/data-statistics/index.vue"),
        name: "data-statistics",
        noCache: true,
        meta: { title: "数据统计", icon: "druid", affix: true },
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    meta: { title: "用户", icon: "peoples" },
    children: [
      {
        path: "list",
        component: () => import("@/views/huoshan/user"),
        name: "list",
        noCache: true,
        meta: { title: "用户管理", icon: "user", affix: true },
      },
    ],
  },
  {
    path: "/agent",
    component: Layout,
    children: [
      {
        path: "manage",
        component: () => import("@/views/huoshan/agent"),
        name: "agent-manage",
        noCache: true,
        meta: { title: "代理商管理", icon: "server", affix: true },
      },
      // {
      //   path: "traffic-packet",
      //   component: () => import("@/views/huoshan/traffic-packet"),
      //   name: "traffic-packet",
      //   noCache: true,
      //   meta: { title: "动态流量包管理", icon: "server", affix: true },
      // },
    ],
  },
  {
    path: "/windows",
    component: Layout,
    meta: { title: "窗口管理", icon: "monitor" },
    children: [
      {
        path: "windows-list",
        component: () => import("@/views/huoshan/window/windows-list"),
        name: "windows-list",
        noCache: true,
        meta: { title: "窗口列表", icon: "monitor", affix: true },
      },
      {
        path: "organize-config",
        component: () => import("@/views/huoshan/window/organize-config"),
        name: "organize-config",
        noCache: true,
        meta: { title: "分组查看", icon: "list", affix: true },
      },
      {
        path: "tag-list",
        component: () => import("@/views/huoshan/window/tag-list"),
        name: "tag-list",
        noCache: true,
        meta: { title: "标签查看", icon: "code", affix: true },
      },
      {
        path: "service-platform",
        component: () => import("@/views/huoshan/window/service-platform"),
        name: "service-platform",
        noCache: true,
        meta: { title: "业务平台", icon: "international", affix: true },
      },
      {
        path: "import-record",
        component: () => import("@/views/huoshan/window/import-record"),
        name: "import-record",
        noCache: true,
        meta: { title: "窗口导入记录", icon: "list", affix: true },
      },
    ],
  },
  {
    path: "/plug",
    component: Layout,
    meta: { title: "插件管理", icon: "component" },
    children: [
      {
        path: "systemPlug-list",
        component: () => import("@/views/huoshan/plug/systemPlug-list"),
        name: "systemPlug-list",
        noCache: true,
        meta: { title: "系统插件管理", icon: "component", affix: true },
      },
      {
        path: "userPlug-list",
        component: () => import("@/views/huoshan/plug/userPlug-list"),
        name: "userPlug-list",
        noCache: true,
        meta: { title: "用户插件管理", icon: "component", affix: true },
      },
    ],
  },
  {
    path: "/proxy",
    component: Layout,
    meta: { title: "代理管理", icon: "server" },
    children: [
      {
        path: "proxy-config",
        component: () => import("@/views/huoshan/proxy-config"),
        name: "proxy-config",
        noCache: true,
        meta: { title: "用户代理管理", icon: "server", affix: true },
      },
      // {
      //   path: "traffic-packet",
      //   component: () => import("@/views/huoshan/traffic-packet"),
      //   name: "traffic-packet",
      //   noCache: true,
      //   meta: { title: "动态流量包管理", icon: "server", affix: true },
      // },
    ],
  },
  {
    path: "/log",
    component: Layout,
    meta: { title: "系统日志", icon: "job" },
    children: [
      {
        path: "login-log",
        component: () => import("@/views/huoshan/login-log"),
        name: "login-log",
        noCache: true,
        meta: { title: "登录日志", icon: "dict", affix: true },
      },
      {
        path: "operate-log",
        component: () => import("@/views/huoshan/operate-log"),
        name: "operate-log",
        noCache: true,
        meta: { title: "操作日志", icon: "log", affix: true },
      },
    ],
  },
  {
    path: "/version",
    component: Layout,
    meta: { title: "版本管理", icon: "star" },
    children: [
      {
        path: "version-list",
        component: () => import("@/views/huoshan/version-list"),
        name: "version-list",
        noCache: true,
        meta: { title: "版本控制", icon: "star", affix: true },
      },
      {
        path: "white-list",
        component: () => import("@/views/huoshan/white-list"),
        name: "white-list",
        noCache: true,
        meta: { title: "白名单管理", icon: "skill", affix: true },
      },
    ],
  },
  {
    path: "/config",
    component: Layout,
    meta: { title: "系统设置", icon: "system" },
    children: [
      {
        path: "config-system",
        component: () => import("@/views/huoshan/config-system"),
        name: "config-system",
        noCache: true,
        meta: { title: "软件设置", icon: "system", affix: true },
      },
      {
        path: "dictionary",
        component: () => import("@/views/huoshan/dictionary"),
        name: "dictionary",
        noCache: true,
        meta: { title: "字典管理", icon: "dict", affix: true },
      },
      {
        path: "announcements",
        component: () => import("@/views/huoshan/announcements"),
        name: "announcements",
        noCache: true,
        meta: { title: "公告管理", icon: "date-range", affix: true },
      },
    ],
  },

  {
    path: "/agent_user",
    component: Layout,
    meta: { title: "名下用户", userType: 2, icon: "peoples", affix: true },
    children: [
      {
        path: "",
        component: () => import("@/views/huoshan/agent-user/agent-user.vue"),
        name: "agent-user",
        noCache: true,
        meta: { title: "名下用户", userType: 2, icon: "peoples", affix: true },
      },
    ],
  },
  {
    path: "/self-info",
    component: Layout,
    meta: { title: "提成分成", userType: 2, icon: "money", affix: true },
    children: [
      {
        path: "",
        component: () => import("@/views/huoshan/agent-user/self-info.vue"),
        name: "self-info",
        noCache: true,
        meta: { title: "提成分成", userType: 2, icon: "money", affix: true },
      },
    ],
  },
];

// 动态路由，基于用户权限动态去加载
// export const dynamicRoutes = [];

// // 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// let routerReplace = Router.prototype.replace;
// // push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch((err) => err);
// };
// // replace
// Router.prototype.replace = function push(location) {
//   return routerReplace.call(this, location).catch((err) => err);
// };
export const dashboardName = 'data-statistics'

Vue.use(Router);

let router = new Router({
  // mode: "history", // 去掉url中的#
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem(`token`, to.query.token)
  }
  let token = localStorage.token;

  let { name } = to;
  if (token == undefined && to.name != "login") {
    next({ name: "login" });
  } else if (name == undefined) {
    if (localStorage.getItem(`user_type`) === '2') {
      next({ name: `agent-user` });
    } else {
      next({ name: dashboardName });
    }
  } else {
    next();
  }
});

export default router;
