<template>
  <div class="down-up-store" v-loading="loding">
    <div
      class="img-store"
      @click="upfile(value)"
      style="width: 100%; height: 142px"
    >
      <img :src="value[imgKey]" />
      <div class="men flex-center-center">修改</div>
    </div>
    <el-input v-model="value[imgKey]"></el-input>
  </div>
</template>

<script>
import axios from "axios";
// let baseUrl =
//   location.href.indexOf(`localhost`) != -1 ? `` : `https://app-eye.com`;
let baseUrl = `https://app-eye.com`;
export default {
  name: "div-img-store",
  data() {
    return {
      loding: false,
    };
  },
  props: {
    value: {
      typeof: Object,
      default: () => ({}),
    },
    imgKey: {
      typeof: String,
      default: "img",
    },
  },
  methods: {
    upfile(value) {
      var fileStore = document.createElement(`input`);
      fileStore.type = "file";
      fileStore.multiple = true;
      fileStore.accept = `image/*`;
      fileStore.click();
      fileStore.addEventListener("change", async () => {
        var formData = new FormData();
        formData.append("file", fileStore.files[0]);
        this.loding = true;
        try {
          const message = await this.$apis.file(formData);
          if (message.code == 200) {
            value[this.imgKey] = message.url;
            this.$emit("up-file", value[this.imgKey]);
          } else {
            this.$message({ message: message.msg, type: "warning" });
          }

          this.loding = false;
        } catch (error) {
          console.log(error);
          this.loding = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.down-up-store {
  width: 100%;
  margin-bottom: 10px;
  min-width: 180px;
  flex-shrink: 0;
  .down-up-store:hover {
    cursor: pointer;
  }
  .img-store {
    position: relative;
    overflow: hidden;
    margin: 10px auto;
    border: dashed 2px var(--theme-color);
    border-radius: 5px;
    padding: 1px;
    .men {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0%;
      height: 0%;
      border-radius: 50%;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0);
      cursor: pointer;
    }
    img {
      object-fit: cover;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .img-store:hover .men {
    width: 100vw;
    height: 100vw;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
