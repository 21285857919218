<template>
  <div
    :class="{ 'has-logo': showLogo }"
    :style="{
      backgroundColor:
        settings.sideTheme === 'theme-dark'
          ? variables.menuBackground
          : variables.menuLightBackground,
    }"
  >
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="
          settings.sideTheme === 'theme-dark'
            ? variables.menuBackground
            : variables.menuLightBackground
        "
        :text-color="
          settings.sideTheme === 'theme-dark'
            ? variables.menuColor
            : variables.menuLightColor
        "
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters2"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";

import { constantRoutes } from "@/router/index";
export default {
  components: { SidebarItem, Logo },
  data() {
    const userType = window.localStorage.getItem(`user_type`);

    // const menus = constantRoutes.filter((item) => {
    //   if (userType === '2') {
    //     return item.meta?.userType == userType;
    //   }

    //   if (item.meta?.userType) {
    //     return item.meta?.userType == userType;
    //   }

    //   return true
    //   })

    //   if (userType === '2') {
    //     console.log('menus', this.$route.path)
    //     console.log('menus', menus)
    //     this.$router.replace(menus[0].path);
    //   }

    return {
      // sidebarRouters2: menus,
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    ...mapGetters(["sidebarRouters", "sidebar"]),
    sidebarRouters2() {
      const userType = window.localStorage.getItem(`user_type`);

      return this.user.menus.filter((item) => {
      if (userType === '2') {
        return item.meta?.userType == userType;
      }

      if (item.meta?.userType) {
        return item.meta?.userType == userType;
      }

      return true
      })
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>

<style lang="scss">
.el-submenu__title {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}
</style>
