<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="用户名" v-model.trim="parmes.userName" style="width: 180px; margin-left: 15px"
          @keydown.enter.native="getMessage(true)" clearable></el-input>
        <el-button  type="success"
          size="mini" style="margin-left: 20px"
          icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
        type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">

        <el-button type="primary" size="mini" icon="el-icon-plus" @click="showDialog({})">新增员工</el-button>

      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    <template v-slot:operate="{ data }">
        <div class="operate flex-start-center">
          <el-link
            :underline="false"
            type="warning"
            style="margin-right: 20px"
            @click="showDialog(data.rows)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            style="margin-right: 20px"
            @click="deleteRule(data.rows.id)"
            >删除</el-link
          >
        </div>
      </template>
    </div-table>
    <el-dialog :title="form.id ? '编辑员工' : '新增员工'" :visible.sync="dialogFormVisible" @close="form = {}">
      <el-form :model="form"  :rules="rules"  ref="ruleForm">
        <el-form-item label="角色" :label-width="formLabelWidth"  prop="role">
          <el-select v-model="form.role" placeholder="请选择角色">
            <el-option v-for="item in roleList" :key="item" :label="item.role" :value="item.role"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名" type="number" :label-width="formLabelWidth" prop="userName">
          <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" type="number" :label-width="formLabelWidth" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // 保留两位小数
});

export default {
  components: {
    divTable,
  },
  data() {
    return {
      formLabelWidth: '150px',
      window_details_obj: {},
      window_details_keys: [],
      drawer: false,
      rules: {
        subLevel: [
          { required: true, message: '请输入订阅等级', trigger: 'blur' },
        ],
        minWindows: [
          { required: true, message: '请输入最小窗口数', trigger: 'blur' },
        ],
        maxWindows: [
          { required: true, message: '请输入最大窗口数', trigger: 'blur' },
        ],
        windowPrice: [
          { required: true, message: '请输入每个窗口单价', trigger: 'blur' },
        ],
        extraMembers: [
          { required: true, message: '请输入额外成员数量', trigger: 'blur' },
        ],
        extraWorkspaces: [
          { required: true, message: '请输入额外空间数量', trigger: 'blur' },
        ],
      },
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,
      dialogFormVisible: false,
      form: {},
      windowsMessage: {},
      roleList: [],
      subLevelList: [
        'Basic',
        'Pro',
        'Business',
        'Enterprise',
      ],

      fromList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: `用户名`,
          key: `userName`,
        },
        {
          label: `角色`,
          key: `role`,
        },
        {
          label: `创建时间`,
          key: `createTime`,
          width: 180,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
          width: 180,
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: 120,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
     this.getRoleList();
    this.getMessage();
  },

  watch: {},
  methods: {
    getRoleList() {
     return this.$apis.get(`/admin_get_role`).then(res => {
        this.roleList = res.data.rows
      });
    },
    showDialog(row){
      this.dialogFormVisible = true;
      this.form = {
        ...row,
        password: ""
      };
      console.log('this.form', this.form)
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    reSetParmes() {
      this.parmes = {
        userName: "",
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis
        .get(`/admin_get_employee_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
    handleSubmit(){
      this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            const url = this.form.id ? `/admin_mdf_employee` : `/admin_add_employee`
            const {code, msg} = await this.$apis.post(url, {
              ...this.form,
            })
            if(code == 0){
              this.$message.success(msg);
              this.dialogFormVisible = false;
              this.getMessage();
            } else {
              this.$message.error(msg);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    deleteRule(id){
      this.$confirm('确定删除该员工吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await this.$apis.post(`/admin_del_employee`, {id})
        if(code == 0){
          this.$message.success(msg);
          this.getMessage();
        } else {
          this.$message.error(msg);
        }
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
