var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
      (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
      !_vm.item.alwaysShow
        ? [
            _vm.onlyOneChild.meta
              ? _c(
                  "app-link",
                  {
                    attrs: {
                      to: _vm.resolvePath(
                        _vm.onlyOneChild.path,
                        _vm.onlyOneChild.query
                      ),
                    },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "flex-start-center",
                        class: { "submenu-title-noDropdown": !_vm.isNest },
                        attrs: {
                          index: _vm.resolvePath(_vm.onlyOneChild.path),
                        },
                      },
                      [
                        _c("item", {
                          attrs: {
                            icon:
                              _vm.onlyOneChild.meta.icon ||
                              (_vm.item.meta && _vm.item.meta.icon),
                            title: _vm.onlyOneChild.meta.title,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _c(
            "el-submenu",
            {
              ref: "subMenu",
              attrs: {
                index: _vm.resolvePath(_vm.item.path),
                "popper-append-to-body": "",
              },
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _vm.item.meta
                    ? _c("item", {
                        attrs: {
                          icon: _vm.item.meta && _vm.item.meta.icon,
                          title: _vm.item.meta.title,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.item.children, function (child, index) {
                return _c("sidebar-item", {
                  key: child.path + index,
                  staticClass: "nest-menu",
                  attrs: {
                    "is-nest": true,
                    item: child,
                    "base-path": _vm.resolvePath(child.path),
                  },
                })
              }),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }