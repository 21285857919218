<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('messages')">
        <div class="card-panel-icon-wrapper icon-message">
          <svg-icon icon-class="message" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            活跃用户 / 总用户
          </div>
          <count-to :start-val="0" :end-val="sysInfo.online_user_num || 0" :duration="2000" class="card-panel-num" />
          <span class="card-panel-num">/</span>
          <count-to :start-val="0" :end-val="sysInfo.total_user || 0" :duration="2000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('newVisitis')">
        <div class="card-panel-icon-wrapper icon-people">
          <svg-icon icon-class="peoples" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            今日新增用户
          </div>
          <count-to :start-val="0" :end-val="sysInfo.new_user_num || 0" :duration="2000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('shoppings')">
        <div class="card-panel-icon-wrapper icon-shopping" style="color: #c82477">
          <svg-icon icon-class="server" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            已打开窗口 / 总窗口
          </div>
          <count-to :start-val="0" :end-val="sysInfo.open_window_num" :duration="2000" class="card-panel-num" />
          <span class="card-panel-num">/</span>
          <count-to :start-val="0" :end-val="sysInfo.total_window" :duration="2000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('shoppings')">
        <div class="card-panel-icon-wrapper icon-shopping">
          <svg-icon icon-class="money" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            近30天收入合计
          </div>
          <span class="card-panel-num">
            <span style="color: red;">$</span>
            <count-to :start-val="0" :end-val="sysInfo.total_amount / 100" :duration="2000" :decimals="2" />
          </span>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  components: {
    CountTo
  },
  props: {
    sysInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    display: flex;
    justify-content: space-around;
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      padding-left: 6px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 15px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 25px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
  }

  .panel-group .card-panel .card-panel-icon-wrapper {
    padding: 30px;
    padding-left: 10px;
  }

  .panel-group .card-panel .card-panel-icon {
    font-size: 24px;
  }

  .panel-group .card-panel .card-panel-description {
    margin: 22px;
    margin-left: 0px;
    margin-right: 10px;
  }

  .panel-group .card-panel .card-panel-description .card-panel-text {
    font-size: 10px;
  }

  .panel-group .card-panel .card-panel-description .card-panel-num {
    font-size: 14px;
  }



  .card-panel-icon-wrapper {
    float: none !important;
    width: 30%;
    height: 30%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
