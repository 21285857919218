<template>
  <div class="pages-store">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item, index) in paths" :key="item.path">
        <a @click="handleBreadcrumbClick(item.path)">
          {{ item.title }}
        </a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex-sw-center" style="margin-top: 20px; height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="搜索路径" v-model.trim="parmes.prefix" style="width: 180px; margin-right: 15px" @keydown.enter.native="search()"></el-input>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="search()">查询</el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click=" reSetParmes(); search();">重置</el-button>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%;">
      <el-table-column prop="directory" label="目录">
        <template slot-scope="scope">
          <a @click="toNext(scope.row)" style="color: #2692ff;">{{ (scope.row.directory || scope.row.file).split('/').filter(Boolean).at(-1) }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="文件类型" width="180">
      </el-table-column>
      <el-table-column prop="size" label="大小" width="180">
        <template slot-scope="scope">
          <span style="margin-right: 10px;">{{ scope.row.size }}</span>
          <el-button type="text" size="small" @click="handleStat(scope.row)">统计</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="pagination.page_index" :page-sizes="[50, 100, 200, 300, 400]" :page-size.sync="pagination.page_size"
      layout="sizes, prev, pager, next" :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      path: '/',
      parmes: {
        prefix: ''
      },
      tableData: [],
      pagination: {
        page_index: 1,
        page_size: 100,
        total: 0
      }
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    paths () {
      return this.path.split('/').reduce((acc, cur) => {
        if (cur) {
          acc.push({
            title: cur,
            path: `${acc[acc.length - 1]?.path || ''}/${cur}`.replaceAll('//', '/')
          })
        } else if (acc.length === 0) {
          acc.push({
            title: '根目录',
            path: '/'
          })
        }
        return acc
      }, [])
    }
  },
  methods: {
    reSetParmes () {
      this.parmes = {
        prefix: "",
      }
    },
    search () {
      this.pagination.page_index = 1
      this.getData({
        prefix: `${this.parmes.prefix}/`.replaceAll('//', '/'),
      })
    },
    async getData (params) {
      const { code, msg, data } = await this.$apis.get('/admin_get_oss_file_list', {
        prefix: `${this.path}/`.replaceAll('//', '/'),
        ...this.pagination,
        ...params
      })
      this.tableData = data.rows
      this.pagination.total = data.total
      console.log(data)
    },

    toNext (row) {
      if (row.type == 'dir') {
        this.path = `/${row.directory}`
        this.getData()
      }
    },
    handleBreadcrumbClick (path) {
      this.path = path
      this.getData()
    },
    async handleStat (row) {
      const { data } = await this.$apis.get('/admin_get_oss_file_size', {
        directory: row.directory
      })

      const index = this.tableData.findIndex(item => item.directory == row.directory)
      this.$set(this.tableData, index, { ...this.tableData[index], size: data })
    }
  }
}
</script>
