// 订单类型(续费/订阅/购买/充值/提现/退款/兑换/赠送:renew/subscribe/purchase/recharge/withdraw/refund/exchange/gift)
export const orderType = [
  {
    label: "续费",
    value: "renew",
  },
  {
    label: "订阅",
    value: "subscribe",
  },
  {
    label: "购买",
    value: "purchase",
  },
  {
    label: "充值",
    value: "recharge",
  },
  {
    label: "提现",
    value: "withdraw",
  },
  {
    label: "退款",
    value: "refund",
  },
  {
    label: "兑换",
    value: "exchange",
  },
  {
    label: "赠送",
    value: "gift",
  },
];

export const orderTypeMap = orderType.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});


// 购买类型：wallet: 钱包workspace: 空间staff: 成员profile: 窗口workspace_staff: 空间和成员
export const purchaseType = [
  {
    label: "钱包",
    value: "wallet",
  },
  {
    label: "空间",
    value: "workspace",
  },
  {
    label: "成员",
    value: "staff",
  },
  {
    label: "窗口",
    value: "profile",
  },
  {
    label: "空间和成员",
    value: "workspace_staff",
  },
];

export const purchaseTypeMap = purchaseType.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});


// 充值类型(在线支付/充值钱包/管理员充值：onlinePay/rechargeWallet/adminRecharge)
export const rechargeType = [
  {
    label: "在线支付",
    value: "onlinePay",
      },
  {
    label: "充值钱包",
    value: "rechargeWallet",
  },
  {
    label: "管理员充值",
    value: "adminRecharge",
  },
];

export const rechargeTypeMap = rechargeType.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

// 充值方式: Balance,Alipay,USDT,Wechat,Card
export const rechargeMethod = [
  {
    label: 'Roxy 钱包',
    value: 'Balance',
  },
  {
    label: '银行卡',
    value: 'Card',
  },
  {
    label: '支付宝',
    value: 'Alipay',
  },
  {
    label: '微信支付',
    value: 'Wechat',
  },
  {
    label: 'USDT',
    value: 'USDT',
  }
]

export const rechargeMethodMap = rechargeMethod.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});
