<template>
 <div class="home pages-store">
  <div class="flex-sw-center" style="height: 50px; width: 100%">

      <div class="flex-start-center" style="margin-left: auto">

        <el-button type="primary" size="mini" icon="el-icon-plus" @click="showDialog({})">新增菜单</el-button>

      </div>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
        prop="name"
        label="菜单名称"
        sortable
        width="180">
      </el-table-column>
      <el-table-column
        prop="route"
        label="菜单路由"
        sortable
        width="180">
      </el-table-column>
      <el-table-column
        prop="type"
        label="类型"
        sortable
        width="180">
        <template slot-scope="scope">
          {{ menuTypeMap[scope.row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="apiPath"
        label="API路径">
      </el-table-column>
      <el-table-column
        prop="comPath"
        label="菜单组件路径">
      </el-table-column>
      <el-table-column
        prop="icon"
        label="菜单 icon">
        <template slot-scope="scope">
          <svg-icon v-if="scope.row.icon" :icon-class="scope.row.icon" />
        </template>
      </el-table-column>
      <el-table-column
        prop="sort"
        label="菜单序号">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间">
      </el-table-column>
      <el-table-column width="150" label="操作" >
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="showDialog(scope.row)">编辑</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete( scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
    </el-table>


    <el-dialog :title="form.id ? '编辑菜单' : '新增菜单'" :visible.sync="dialogFormVisible" @close="form = {}">
      <el-form :model="form"  :rules="rules"  ref="ruleForm">
        <el-form-item label="上级菜单" :label-width="formLabelWidth"  prop="parentId">
          <el-tree-select ref="treeSelect" v-model="form.parentId" :treeParams="treeParams" :styles="{width: '100%'}" @searchFun="searchFun"  />
        </el-form-item>
        <el-form-item :label="isButton ? '按钮名称' : '菜单名称'" :label-width="formLabelWidth"  prop="name">
          <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <el-form-item v-if="!isButton" label="菜单路由" :label-width="formLabelWidth"  prop="route">
          <el-input v-model="form.route" placeholder="请输入菜单路由"></el-input>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth"  prop="type">
          <el-radio-group v-model="form.type">
            <el-radio label="dir">目录</el-radio>
            <el-radio label="page">页面</el-radio>
            <el-radio label="button">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!isButton" label="图标" :label-width="formLabelWidth" prop="icon">
          <el-popover
            placement="bottom"
            width="400"
            trigger="click">
            <icon-select :activeIcon="form.icon" @selected="handleIconSelected" ></icon-select>
            <el-button slot="reference">
              <svg-icon v-if="form.icon" :icon-class="form.icon" />
              <span v-else>请选择图标</span>
            </el-button>
          </el-popover>
        </el-form-item>
        <el-form-item v-if="isPage" label="菜单组件路径" :label-width="formLabelWidth" prop="comPath">
          <el-cascader v-model="form.comPath" :options="cascaderOptions" filterable clearable style="width: 100%"></el-cascader>
        </el-form-item>
        <el-form-item v-if="isButton" label="API路径" type="number" :label-width="formLabelWidth" prop="apiPath">
          <el-input v-model="form.apiPath" placeholder="请输入API路径"></el-input>
        </el-form-item>
        <el-form-item v-if="!isButton" label="菜单序号" :label-width="formLabelWidth" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入菜单序号"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
  </template>
<script>

const menuTypeMap = {
  "dir": "目录",
  "page": "页面",
  "button": "按钮",
}

  import { cascaderOptions } from "@/router";
  import IconSelect from "@/components/IconSelect/index.vue";
  import ElTreeSelect from 'el-tree-select';

  const menuTypeList = [
    { label: '目录', value: 'dir' },
    { label: '页面', value: 'page' },
    { label: '按钮', value: 'button' },
  ]

    export default {
      components: {
        IconSelect,
        ['el-tree-select']: ElTreeSelect[0]
      },
      data() {
        return {
          form: {
            type: 'page',
          },
          menuTypeList,
          formLabelWidth: '120px',
          tableData: [],
          menuTypeMap,
          dialogFormVisible: false,
          cascaderOptions,
        }
      },
      computed: {
        isPage() {
          return this.form.type === 'page'
        },
        isDir() {
          return this.form.type === 'dir'
        },
        isButton() {
          return this.form.type === 'button'
        },
        rules() {
          return {
            name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
            route: [{ required: this.isPage, message: '请输入菜单路由', trigger: 'blur' }],
            type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
            apiPath: [{ required: this.isButton, message: '请输入API路径', trigger: 'blur' }],
            comPath: [{ required: this.isPage, message: '请选择页面组件路径', trigger: 'blur' }],
          }
        },
        treeParams() {
          return {
            "check-strictly": true,
            "default-expand-all": false,
            "expand-on-click-node": false,
            "clickParent": false,
            "filterable": true,
            "data": [
              {
                "id": 0,
                "name": "根目录",
                "type": "dir",
                "parentId": 0,
                "children": this.tableData
              }
            ],
            props:  {
              "label": "name",
              "disabled": "disabled",
              "value": "id"
            }
          }
        }
      },
      mounted() {
        this.getMessage()
      },
      methods: {
        searchFun(value) {
          this.$refs.treeSelect.$refs.tree.filter(value)
        },
        async getMessage() {
          const { code, data, msg } = await this.$apis
            .get(`/admin_get_role_menu`, {
              ...this.parmes,
            })
          if (code === 0) {
            this.tableData = data
          } else {
            this.$message.error(msg)
          }
        },
        handleSubmit(){
          this.$refs.ruleForm.validate(async (valid) => {
              if (valid) {
                const url = this.form.id ? `/admin_mdf_role_menu` : `/admin_add_role_menu`
                const { comPath,children, ...rest } = this.form
                const {code, msg} = await this.$apis.post(url, {
                  ...rest,
                  comPath: comPath.join('/')
                })
                if(code == 0){
                  this.$message.success(msg);
                  this.dialogFormVisible = false;
                  this.getMessage();
                } else {
                  this.$message.error(msg);
                }
              } else {
                console.log('error submit!!');
                return false;
              }
            });
        },
        showDialog(row) {
          this.dialogFormVisible = true
          this.form = {
            ...row,
            type: row.type || 'dir'
          }
          this.form.comPath = row.comPath?.split('/')
          console.log('this.form.comPath',this.form.comPath)
        },
        handleIconSelected(icon) {
          this.form.icon = icon
          this.$forceUpdate()
        },
        handleDelete(id){
          this.$confirm('确定删除该菜单吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {code, msg} = await this.$apis.post(`/admin_del_role_menu`, {id})
            if(code == 0){
              this.$message.success(msg);
              this.getMessage();
            } else {
              this.$message.error(msg);
            }
          }).catch(() => {
            this.$message.info('已取消删除');
          });
        },
      },
    }
  </script>