<template>
  <div :class="classes">
    <div v-for="(item, index) in pieChartList" :key="index">
      <pie-chart height="500px" :config="item" @pie-click="handlePieClick" :updateSize="$attrs.updateSize" />
    </div>
    <pie-detail :seriesName="pieData.seriesName" :title="pieDetailTitle" :columns="columns" :data="tableData" :visible.sync="visible"
      @get-message="getMessage"></pie-detail>
  </div>
</template>

<script>
import PieChart from './components/PieChart'
import pieDetail from './components/pieDetail'
import { getVersionPieData,getLanguagePieData,getChannelSourcePieData } from "./lineData";
import { isMobile } from '@/utils';

const versionColumns = [
  {
    key: 'userName',
    label: '用户名',
    minWidth: '150px',
  },
  {
    key: 'platform',
    label: '平台名称',
    minWidth: '150px',
  },
  {
    key: 'appVersion',
    label: 'app版本号',
    minWidth: '150px',
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px',
  }
]

const channelSourcesPeopleColumns = [
  {
    key: 'realIp',
    label: '用户IP',
    minWidth: '150px',
  },
  {
    key: 'channelSource',
    label: '渠道来源',
    minWidth: '150px',
  },
  {
    key: 'ipLocation',
    label: '位置',
    minWidth: '150px',
  },
  {
    key: 'createTime',
    label: '创建时间',
    minWidth: '250px',
  }
]

const languageColumns = [
  {
    key: 'userName',
    label: '用户名',
    minWidth: '150px',
  },
  {
    key: 'email',
    label: '邮箱',
    minWidth: '150px',
  },
  {
    key: 'language',
    label: '语言',
    minWidth: '150px',
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px',
  }
]

export default {
  name: 'PanelMonitor',
  components: {
    PieChart,
    pieDetail
  },
  computed: {
    classes () {
      return isMobile() ? 'panel-monitor-mobile' : 'panel-monitor'
    },
    columns () {
      const { seriesName } = this.pieData
      if (seriesName === '渠道来源人数') {
        return channelSourcesPeopleColumns
      } else if (seriesName === '中英人数') {
        return languageColumns
      }
      return versionColumns
    },
    pieDetailTitle() {
      const { seriesName } = this.pieData
      if (seriesName === '渠道来源人数') {
        return '渠道来源人数详情'
      } else if (seriesName === '中英人数') {
        return '中英人数详情'
      } else {
        return '平台数据详情'
      }
    }
  },
  data () {
    return {
      pieChartList: [],
      pieData: {},
      visible: false,
      tableData: {}
    }
  },
  async mounted () {
    this.getPieData()
  },
  methods: {
    handlePieClick (params) {
      // console.log('params', params)
      this.pieData = params
      this.getMessage()
    },
    async getMessage (params = {}) {
      const { seriesName } = this.pieData
      const _params = {
        page_index: 1,
        page_size: 15,
        ...params
      }
      let tableData = {}

      if (seriesName === '渠道来源人数') {
        const { name: domain } = this.pieData.data
        Object.assign(_params, { domain })
        const { data } = await this.$apis.get(`/admin_get_channel_sources_people_details`, _params);
        tableData = data
      } else if (seriesName === '中英人数') {
        const { name: language } = this.pieData.data
        Object.assign(_params, { language })
        const { data } = await this.$apis.get(`/admin_get_language_personnel_details`, _params);
        tableData = data
      } else {
        const { platform, appVersion } = this.pieData.data
        Object.assign(_params, { platform, appVersion })
        const { data } = await this.$apis.get(`/admin_app_version_detailed_list`, _params);
        tableData = data
      }

      this.tableData = tableData
      this.visible = true
    },
    async getPieData () {
      const {
        code,
        data,
        msg,
      } = await this.$apis
        .get(`/admin_count_app_version_users_list`, { page_index: 1, page_size: 10000 });

        const {data: languagePeople} = await this.$apis.get(`/admin_get_language_personnel_total`);
        const {data: channelSourcesPeople} = await this.$apis.get(`/admin_get_channel_sources_people`);

      const { macos, windows } = (data?.rows || []).reduce((acc, item) => {
        acc[item.platform.toLocaleLowerCase()].push(item)
        return acc
      }, { macos: [], windows: [] })

      this.pieChartList = [
        getVersionPieData('Mac OS', macos),
        getVersionPieData('Windows', windows),
        getLanguagePieData(Object.entries(languagePeople).map(([name, value]) => ({ name, value }))),
        getChannelSourcePieData(channelSourcesPeople.map((item) => ({ name: item.domain, value: item.total_people }))),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-monitor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel-monitor-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
</style>
