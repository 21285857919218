var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home pages-store" },
    [
      _c(
        "div",
        {
          staticClass: "flex-sw-center",
          staticStyle: { height: "50px", width: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-start-center",
              staticStyle: { "margin-left": "auto" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDialog({})
                    },
                  },
                },
                [_vm._v("新增菜单")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            border: "",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "菜单名称",
              sortable: "",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "route",
              label: "菜单路由",
              sortable: "",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型", sortable: "", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.menuTypeMap[scope.row.type]) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "apiPath", label: "API路径" },
          }),
          _c("el-table-column", {
            attrs: { prop: "comPath", label: "菜单组件路径" },
          }),
          _c("el-table-column", {
            attrs: { prop: "icon", label: "菜单 icon" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.icon
                      ? _c("svg-icon", {
                          attrs: { "icon-class": scope.row.icon },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { prop: "sort", label: "菜单序号" } }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "updateTime", label: "更新时间" },
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.form.id ? "编辑菜单" : "新增菜单",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              _vm.form = {}
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级菜单",
                    "label-width": _vm.formLabelWidth,
                    prop: "parentId",
                  },
                },
                [
                  _c("el-tree-select", {
                    ref: "treeSelect",
                    attrs: {
                      treeParams: _vm.treeParams,
                      styles: { width: "100%" },
                    },
                    on: { searchFun: _vm.searchFun },
                    model: {
                      value: _vm.form.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentId", $$v)
                      },
                      expression: "form.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.isButton ? "按钮名称" : "菜单名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入菜单名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              !_vm.isButton
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单路由",
                        "label-width": _vm.formLabelWidth,
                        prop: "route",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入菜单路由" },
                        model: {
                          value: _vm.form.route,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "route", $$v)
                          },
                          expression: "form.route",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "dir" } }, [
                        _vm._v("目录"),
                      ]),
                      _c("el-radio", { attrs: { label: "page" } }, [
                        _vm._v("页面"),
                      ]),
                      _c("el-radio", { attrs: { label: "button" } }, [
                        _vm._v("按钮"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isButton
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "图标",
                        "label-width": _vm.formLabelWidth,
                        prop: "icon",
                      },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "400",
                            trigger: "click",
                          },
                        },
                        [
                          _c("icon-select", {
                            attrs: { activeIcon: _vm.form.icon },
                            on: { selected: _vm.handleIconSelected },
                          }),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _vm.form.icon
                                ? _c("svg-icon", {
                                    attrs: { "icon-class": _vm.form.icon },
                                  })
                                : _c("span", [_vm._v("请选择图标")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isPage
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单组件路径",
                        "label-width": _vm.formLabelWidth,
                        prop: "comPath",
                      },
                    },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.cascaderOptions,
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.comPath,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comPath", $$v)
                          },
                          expression: "form.comPath",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isButton
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "API路径",
                        type: "number",
                        "label-width": _vm.formLabelWidth,
                        prop: "apiPath",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入API路径" },
                        model: {
                          value: _vm.form.apiPath,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "apiPath", $$v)
                          },
                          expression: "form.apiPath",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isButton
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单序号",
                        "label-width": _vm.formLabelWidth,
                        prop: "sort",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入菜单序号" },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }