var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.type == "url"
        ? _c("el-upload", {
            ref: "upload",
            staticStyle: { display: "none" },
            attrs: {
              action: _vm.uploadUrl,
              "before-upload": _vm.handleBeforeUpload,
              "on-success": _vm.handleUploadSuccess,
              "on-error": _vm.handleUploadError,
              name: "file",
              "show-file-list": false,
              headers: _vm.headers,
            },
          })
        : _vm._e(),
      _c("div", { ref: "editor", staticClass: "editor", style: _vm.styles }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }