import axios from "axios";
import { isDev } from "../constants";

axios.interceptors.response.use(
  (res) => {
    // console.log(res);
    return { code: 200, data: {}, ...(res.data || {}), };
  },
  (error) => {
    return {
      code: 400,
      data: {},
      msg: "服务器异常",
    };
  }
);

axios.interceptors.request.use((req) => {
  req.headers["token"] = localStorage.getItem("token");
  return req;
});

class Apis {
  constructor() {
    this.axios = axios;
    this.baseUrl = isDev ? `https://admintest.roxybrowser.co/fb-admin-api` : `/fb-admin-api`; // 本地
    // this.baseUrl = `/fb-admin-api`; // 生产
    // location.href.indexOf(`localhost`) != -1
    //   ? `http://localhost:8080`
    //   : `https://app-eye.com/python`;
  }
  transAdd (obj) {
    let str = "";
    let keys = Object.keys(obj);
    keys.forEach((key, index) => {
      if (index == 0)
      {
        str += `?${key}=${obj[key]}`;
      } else
      {
        str += `&${key}=${obj[key]}`;
      }
    });
    return str;
  }
  get (url, params) {
    return this.axios.get(
      `${this.baseUrl}${url}${this.transAdd(params || {})}`
    );
  }
  post (url, params) {
    // return this.axios.post(`${this.baseUrl}${url}`, { ...params });
    return this.axios({
      url: `${this.baseUrl}${url}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    });
  }

  file (formData) {
    return this.axios({
      url: `${this.baseUrl}/api/Upload`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }
}

export default window.$axios = new Apis();
