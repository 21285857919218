var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-ranking" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "table-title" }, [_vm._v("用户活跃列表")]),
          _c("div-table", {
            attrs: {
              H: "300px",
              fromList: _vm.columns1,
              fromData: _vm.userList,
              pageSize: _vm.pageSize,
              pageValue: _vm.pageValue,
              pageSizeList: _vm.pageSizeList,
              total: _vm.total,
            },
            on: {
              "up-page-size": function ($event) {
                _vm.pageSize = $event
              },
              "up-page-value": function ($event) {
                _vm.pageValue = $event
              },
              "get-message": function ($event) {
                return _vm.getMessage($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.tableList, function (table) {
        return _c(
          "div",
          { staticClass: "table" },
          [
            _c("div", { staticClass: "table-title" }, [
              _vm._v(_vm._s(table.title)),
            ]),
            _c("div-table", {
              attrs: {
                fromList: table.columns,
                fromData: table.list,
                "info-page": false,
                H: "300px",
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }