<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-select v-model="parmes.subLevel" placeholder="订阅等级" clearable @change="getMessage(true)">
          <el-option v-for="item in subLevelList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-button  type="success"
          size="mini" style="margin-left: 20px"
          icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
        type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">

        <el-button type="primary" size="mini" icon="el-icon-plus" @click="showDialog({})">新增配置</el-button>

      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    <template v-slot:operate="{ data }">
        <div class="operate flex-start-center">
          <el-link
            :underline="false"
            type="warning"
            style="margin-right: 20px"
            @click="showDialog(data.rows)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            style="margin-right: 20px"
            @click="deleteRule(data.rows.id)"
            >删除</el-link
          >
        </div>
      </template>
    </div-table>
    <el-dialog :title="form.id ? '编辑订阅价格规则' : '新增订阅价格规则'" :visible.sync="dialogFormVisible" @close="form = {}">
      <el-form :model="form"  :rules="rules"  ref="ruleForm">
        <el-form-item label="订阅等级" :label-width="formLabelWidth"  prop="subLevel">
          <el-select v-model="form.subLevel" placeholder="请选择订阅等级">
            <el-option v-for="item in subLevelList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最小窗口数" type="number" :label-width="formLabelWidth" prop="minWindows">
          <el-input v-model="form.minWindows" placeholder="请输入最小窗口数"></el-input>
        </el-form-item>
        <el-form-item label="最大窗口数" type="number" :label-width="formLabelWidth" prop="maxWindows">
          <el-input v-model="form.maxWindows" placeholder="请输入最大窗口数"></el-input>
        </el-form-item>
        <el-form-item label="窗口单价($)" type="number" :label-width="formLabelWidth" prop="windowPrice">
          <el-input v-model="form.windowPrice" placeholder="请输入每个窗口单价"></el-input>
        </el-form-item>
        <el-form-item label="额外成员数量" type="number" :label-width="formLabelWidth" prop="extraMembers">
          <el-input v-model="form.extraMembers" placeholder="请输入额外成员数量"></el-input>
        </el-form-item>
        <el-form-item label="额外空间数量" type="number" :label-width="formLabelWidth" prop="extraWorkspaces">
          <el-input v-model="form.extraWorkspaces" placeholder="请输入额外空间数量"></el-input>
        </el-form-item>
        <el-form-item v-if="form.id" label="状态" type="number" :label-width="formLabelWidth" prop="isActive">
          <el-switch v-model="form.isActive" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // 保留两位小数
});

export default {
  components: {
    divTable,
  },
  data() {
    return {
      formLabelWidth: '150px',
      window_details_obj: {},
      window_details_keys: [],
      drawer: false,
      rules: {
        subLevel: [
          { required: true, message: '请输入订阅等级', trigger: 'blur' },
        ],
        minWindows: [
          { required: true, message: '请输入最小窗口数', trigger: 'blur' },
        ],
        maxWindows: [
          { required: true, message: '请输入最大窗口数', trigger: 'blur' },
        ],
        windowPrice: [
          { required: true, message: '请输入每个窗口单价', trigger: 'blur' },
        ],
        extraMembers: [
          { required: true, message: '请输入额外成员数量', trigger: 'blur' },
        ],
        extraWorkspaces: [
          { required: true, message: '请输入额外空间数量', trigger: 'blur' },
        ],
      },
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,
      dialogFormVisible: false,
      form: {},
      windowsMessage: {},
      subLevelList: [
        'Basic',
        'Pro',
        'Business',
        'Enterprise',
      ],

      fromList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: `订阅等级`,
          key: `subLevel`,
        },
        {
          label: `最小窗口数`,
          key: `minWindows`,
        },
        {
          label: `最大窗口数`,
          key: `maxWindows`,
        },
        {
          label: `窗口单价`,
          key: `windowPrice`,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `额外成员数`,
          key: `extraMembers`,
        },
        {
          label: `额外空间数`,
          key: `extraWorkspaces`,
        },
        {
          label: `状态`,
          key: `isActive`,
          transValue: (val) => {
            return val == 1 ? "激活" : "未激活";
          },
        },
        {
          label: `创建时间`,
          key: `createTime`,
          width: 180,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
          width: 180,
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: 120,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    showDialog(row){
      this.dialogFormVisible = true;
      this.form = {...row};
      this.form.windowPrice = row.id ? this.form.windowPrice / 100 : "";
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    reSetParmes() {
      this.parmes = {
        subLevel: "",
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis
        .get(`/admin_get_window_pricing_rules`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
    handleSubmit(){
      this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            const url = this.form.id ? `/admin_mdf_window_pricing_rules` : `/admin_add_window_pricing_rules`
            const {code, msg} = await this.$apis.post(url, {
              ...this.form,
              windowPrice: this.form.windowPrice * 100,
            })
            if(code == 0){
              this.$message.success(msg);
              this.dialogFormVisible = false;
              this.getMessage();
            } else {
              this.$message.error(msg);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    deleteRule(id){
      this.$confirm('确定删除该规则吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await this.$apis.get(`/admin_del_window_pricing_rules`, {id})
        if(code == 0){
          this.$message.success(msg);
          this.getMessage();
        } else {
          this.$message.error(msg);
        }
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
