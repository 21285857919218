var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loding,
          expression: "loding",
        },
      ],
      staticClass: "down-up-store",
    },
    [
      _c(
        "div",
        {
          staticClass: "img-store",
          staticStyle: { width: "100%", height: "142px" },
          on: {
            click: function ($event) {
              return _vm.upfile(_vm.value)
            },
          },
        },
        [
          _c("img", { attrs: { src: _vm.value[_vm.imgKey] } }),
          _c("div", { staticClass: "men flex-center-center" }, [
            _vm._v("修改"),
          ]),
        ]
      ),
      _c("el-input", {
        model: {
          value: _vm.value[_vm.imgKey],
          callback: function ($$v) {
            _vm.$set(_vm.value, _vm.imgKey, $$v)
          },
          expression: "value[imgKey]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }