<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <!-- <el-input
          placeholder="业务平台"
          v-model.trim="parmes.name"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button> -->
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button class="h30" type="primary" size="mini" icon="el-icon-plus" @click="
          fromInit();
        from_type = `add`;
        dialogShow = true;
        ">
          新增
        </el-button>
      </div>
    </div>

    <div-table :loading="loading" :fromList="fromList" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue"
      :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event" @up-page-value="pageValue = $event"
      @get-message="getMessage($event)">
      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link :underline="false" type="warning" @click="changeList(data.rows, 'edit')">编辑</el-link>
          <el-link :underline="false" type="danger" @click="changeList(data.rows, 'del')">删除</el-link>
        </div>
      </template>
    </div-table>

    <el-dialog :title="from_type == 'add' ? '新增' : '编辑'" :visible.sync="dialogShow" width="30%">
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item v-for="(value, index) in showPageList(
          dialogList,
          from_type == 'add' ? addList : editList
        )" :key="index" :label="value.label">
          <el-select v-if="value.type == 'select'" v-model="dialogFrom[value.key]" @change="changeType($event)"
            placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <template v-else-if="value.type == 'radio'">
            <el-radio v-model="dialogFrom[value.key]" label="1">有效</el-radio>
            <el-radio v-model="dialogFrom[value.key]" label="0">无效</el-radio>
          </template>

          <el-upload v-else-if="value.key == 'platformIcon'" class="avatar-uploader" action="-" :show-file-list="false"
            :limit="1" :before-upload="beforeUpload">
            <img v-if="dialogFrom.platformIcon" :src="dialogFrom.platformIcon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

          <el-input v-else style="margin-bottom: 10px" v-model="dialogFrom[value.key]" placeholder="请输入"
            :disabled="value.disabled || false"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import aliOss from '@/mixins/ali-oss';
import divTable from "../components/div-table.vue";
export default {
  mixins: [aliOss],
  components: {
    divTable,
  },
  data () {
    return {
      parmes: {},
      loading: false,
      fromList: [
        {
          label: "ID",
          key: "id",
        },
        {
          label: "平台icon",
          key: "platformIcon",
          slot: "copy",
        },
        {
          label: "平台地址",
          key: "platformUrl",
          slot: "copy",
        },
        {
          label: "排序",
          key: "sort",
        },
        {
          label: "创建时间",
          key: "createTime",
        },
        {
          label: "修改时间",
          key: "updateTime",
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "120px",
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "平台地址",
          key: "platformUrl",
          slot: "copy",
        },
        {
          label: "平台ICON",
          key: "platformIcon",
        },
        {
          label: "排序",
          key: "sort",
        },
      ],
      addList: ["platformUrl", "platformIcon", "sort"],
      editList: ["id", "platformUrl", "platformIcon", "sort"],
      dialogFrom: {},
      options: [],
    };
  },
  created () { },
  async mounted () {
    this.getMessage();
  },

  watch: {},
  methods: {
    showPageList (arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    async submit () {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add"
            ? "/admin_add_platform"
            : "/admin_mdf_platform"
          }`,
          { ...this.dialogFrom }
        )
        .catch((err) => ({
          code: 1,
        }))
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.from_type = "edit";
        this.dialogShow = false;
        this.$message.success("操作成功");
        this.reSetParmes();
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    async changeList (row, type) {
      if (type == "edit") {
        this.from_type = "edit";
        this.dialogShow = true;
        this.dialogFrom = Object.assign({}, row);
      } else if (type == "del") {
        this.onDelete(row)
      }
    },
    onDelete (row) {
      this.$confirm("确定删除该条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          const { code, data, msg } = await this.$apis
            .get("/admin_delete_platform", {
              id: row.id,
            })
            .catch((e) => ({
              code: 1,
            }))
            .finally(() => {
              this.loading = false;
            });
          if (code == 0) {
            this.$message.success("操作成功");
            this.getMessage();
          } else {
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reSetParmes () {
      this.parmes = {};
    },
    fromInit (parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = {};
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const { code, data: { rows, total }, msg } = await this.$apis
        .post(`/admin_platform_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    beforeUpload (file) {
      const fileName = `/public/static/platform_icon/${file.name}`
      this.ossClient.put(fileName, file).then(({ res }) => {
        this.dialogFrom.platformIcon = res.requestUrls[0]?.split("?")[0];
        this.dialogFrom = { ...this.dialogFrom };
      }).catch((e) => {
        this.$message.error("上传ICON失败");
        console.error("upload error: %j", e);
      });
      // // 阻止默认上传行为
      return false;
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.operate {
  justify-content: space-around;
}

.h30 {
  height: 30px;
}

.avatar {
  width: 36px;
}
</style>
