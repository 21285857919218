<template>
  <div class="home pages-store">

    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <!-- <el-input
          clearable
          placeholder="用户名搜索"
          v-model.trim="parmes.username"
          style="width: 180px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-input
          clearable
          placeholder="ip"
          v-model.trim="parmes.ip"
          style="width: 180px; margin-left: 15px"
          @keyup.enter="getMessage(true)"
        ></el-input> -->
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button
          size="mini"
          icon="el-icon-plus"
          type="primary"
          @click="
            fromInit();
            from_type = `add`;
            show = true;
          "
        >
          新增
        </el-button>
        <el-button size="mini" icon="el-icon-search" type="warning" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="handleSizeChange"
      @get-message="handleCurrentChange"
      @up-page-value="pageValue = $event"
    >
      <template v-slot:bnts="{ data }">
        <div class="flex-center-center">
          <template  v-for="(value, index) in data.keys.bnts" >
            <el-tooltip class="item" effect="dark" :content="value.title" placement="top">
            <el-button
            v-show="
              value.show == undefined ? true : value.show(data.rows, data.keys)
            "
            :disabled="value.loading && data.rows.loading"
            :key="index"
            :title="value.title"
            style="width: 29px; margin-left: 10px; flex-shrink: 0"
            @click="value.click(data.rows, data.keys)"
            :type="
              value.style == undefined
                ? `primary`
                : value.style(data.rows, data.keys)
            "
            :icon="
              info_funtion(value.icon)
                ? value.icon(data.rows, data.key)
                : value.icon
            "
            circle
            size="mini"
          ></el-button>
        </el-tooltip>
          </template>
        </div>
      </template>
    </div-table>
    <el-drawer
      title="代理详情"
      :visible.sync="drawer"
      :direction="direction"
      size="50%"
    >
      <div
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          class="margin-top"
          :column="4"
          direction="vertical"
          border
        >
          <el-descriptions-item
            v-for="(key, index) in window_details_keys"
            :key="index"
            :label="key.label"
          >
            {{
              (
                (key.transValue == undefined
                  ? window_details_obj[key.key] || ``
                  : key.transValue(window_details_obj[key.key] || ``)) + ``
              ).substr(0, 30) || `-`
            }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
    <el-dialog
      v-loading="from_loading"
      :title="from_type == 'add' ? `新增` : `修改`"
      :visible.sync="show"
      width="50%"
      :close-on-click-modal="false"
    >
      <div style="max-height: 60vh; overflow-y: auto; padding-right: 15px">
        <el-form lable-postition="right" ref="form" label-width="120px">
          <el-form-item
            v-for="(value, index) in from_items"
            v-show="value.show == undefined ? true : value.show(value)"
            :label="value.label"
            :key="index"
          >
            <el-input
              v-if="value.type == 'textarea'"
              type="textarea"
              style="margin-bottom: 10px"
              v-model="from[value.key]"
              placeholder="请输入"
            ></el-input>

            <el-date-picker
              style="width: 100%"
              v-else-if="value.type == 'date'"
              v-model="from[value.key]"
              type="date"
              placeholder="Pick a Date"
              value-format="yyyy-MM-dd hh:mm:ss"
            />
            <div class="flex-d" v-else-if="value.type == 'list'">
              <div class="flex-start-center">
                <el-button
                  :disabled="from[value.key] && from[value.key].length > 100"
                  style="width: 29px; flex-shrink: 0"
                  @click="
                    from[value.key].push({
                      host: ``,
                      port: ``,
                      proxyUserName: ``,
                      proxyPassword: ``,
                    })
                  "
                  type="primary"
                  icon="el-icon-plus"
                  circle
                  size="mini"
                ></el-button>
              </div>
              <div
                class="flex-start-center"
                v-for="(item, item_index) in from[value.key]"
                style="margin-top: 10px"
                :key="item_index"
              >
                <el-input v-model="item.host" placeholder="主机"></el-input>
                <el-input
                  style="margin-left: 10px"
                  v-model="item.port"
                  placeholder="端口"
                ></el-input>
                <el-input
                  style="margin-left: 10px"
                  v-model="item.proxyUserName"
                  placeholder="代理用户名"
                ></el-input>
                <el-input
                  style="margin-left: 10px"
                  v-model="item.proxyPassword"
                  placeholder="代理用户密码"
                ></el-input>

                <el-button
                  @click="from[value.key].splice(item_index, 1)"
                  style="width: 29px; flex-shrink: 0; margin-left: 10px"
                  type="primary"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                ></el-button>
              </div>
            </div>

            <el-select
              v-else-if="value.type == 'select'"
              style="width: 100%"
              v-model="from[value.key]"
              filterable
              clearable
              @change="
                infoEdit = false;
                value.change && value.change();
              "
            >
              <el-option
                v-for="(item, item_index) in value.selectList || []"
                :key="item_index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-else
              style="margin-bottom: 10px"
              v-model="from[value.key]"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
    <infoDialog ref="infoDialog"></infoDialog>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";
import infoDialog from "./infoDialog.vue";

export default {
  components: {
    divTable,
    infoDialog
  },
  data() {
    return {
      window_details_obj: {},
      window_details_keys: [
        {
          label: "ip时长",
          key: "proxyTime",
        },
        {
          label: "代理刷新URL",
          key: "refreshUrl",
        },
        {
          label: "备注",
          key: "remark",
        },
        {
          label: "出口ip",
          key: "lastIp",
        },
        {
          label: "出口国家",
          key: "lastCountry",
        },
        {
          label: "出口州/省",
          key: "lastState",
        },
        {
          label: "出口城市",
          key: "lastCity",
        },
        {
          label: "时区",
          key: "timezone",
        },
        {
          label: "语言",
          key: "ipLanguage",
        },
        {
          label: "经度",
          key: "longitude",
        },
        {
          label: "纬度",
          key: "latitude",
        },
        {
          label: "检测状态",
          key: "checkStatus",
          transValue: (value) => {
            let map = {
              0: `无效`,
              1: `有效`,
            };
            return map[value] || `-`;
          },
        },
      ],
      drawer: false,
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,
      fromList: [
        {
          label: "ID",
          key: "id",
        },
        {
          label: "代理商",
          key: "user_name",
        },
        {
          label: "密码",
          key: "password",
        },
        {
          label: "提成比例",
          key: "prize_rate",
        },
        {
          label: "本月推荐用户数量",
          key: "user_cnt",
        },
        {
          label: "创建时间",
          key: "create_time",
        },
        {
          label: "备注",
          key: "remark",
        },
        {
          width: "140px",
          label: `操作`,
          slot: "bnts",
          bnts: [
            {
              icon: `el-icon-edit`,
              title: `修改`,
              click: (obj) => {
                this.from_type = `edit`;
                this.show = true;
                this.from = this.from_items.reduce((prev, elem) => {
                  if (elem.show()) {
                    prev[elem.key] = obj[elem.key];
                  }
                  return prev;
                }, {});
                this.from.user_id = obj.id;
              },
            },
            // {
            //   icon: `el-icon-delete`,
            //   title: `删除`,
            //   click: (obj) => {
            //     this.backEnterFun(() => {
            //       this.delete_api(obj);
            //     });
            //   },
            // },
            {
              icon: `el-icon-view`,
              title: `查看`,
              loading: true,
              click: (obj) => {
                this.$refs.infoDialog.show(obj.id);
              },
            },
          ],
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,

      from_loading: false,
      from_type: "add",
      show: false,
      from: {},
      from_items: [
        {
          label: "账号",
          key: "user_name",
        },
        {
          label: "密码",
          key: "password",
        },
        {
          label: "邮箱",
          key: "email",
        },
        {
          label: "提成比例",
          key: "prize_rate",
        },
        {
          label: "状态",
          key: "status",
          type: `select`,
          selectList: [
            {
              label: `正常`,
              value: 1,
            },
            {
              label: `冻结`,
              value: 0,
            }
          ]
        },
        {
          label: "账号备注",
          key: "remark",
        }
      ].map((elem) => {
        let add = [
        'user_name',
        'password',
        'email',
        'prize_rate',
        'status',
        'remark',
        ];

        let edit = [
          `id`,
          'password',
        'prize_rate',
        'status',
        'remark',
        ];
        if ([...add, ...edit].indexOf(elem.key) == -1) {
          return { ...elem, show: () => false };
        } else {
          return {
            ...elem,
            show: () => {
              if (this.from_type == `add`) {
                return add.indexOf(elem.key) != -1;
              } else {
                return edit.indexOf(elem.key) != -1;
              }
            },
          };
        }
      }),
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },
  created() {
    this.fromInit();
  },
  watch: {},
  methods: {
    fromInit() {
      let obj = {};
      this.from_items.forEach((elem) => {
        obj[elem.key] =
          (elem.defaultValue instanceof Function
            ? elem.defaultValue()
            : elem.defaultValue) || ``;
      });

      this.from = obj;
    },
    reSetParmes() {
      this.parmes = {
        username: "",
        ip: "",
      };
    },
    async submit() {
      this.from_loading = true;
      try {
        const { data, code, msg } = await this.$apis.get(
          `/${this.from_type == `add` ? `admin_add_main_user` : `admin_mdf_admin`}`,
          this.from
        );
        console.log(data, code);
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
          this.show = false;
        } else {
          this.$message.error(msg);
        }
      } catch (error) {}
      this.from_loading = false;
    },

    info_funtion(fun) {
      return fun instanceof Function;
    },
    async look_api(obj) {
      obj.loading = true;
      try {
        const { code, msg, data } = await this.$apis.post(
          `/admin_check_proxy`,
          obj
        );
        obj.div_status = code == 0 ? 2 : 3;
      } catch (error) {}
      obj.loading = false;
    },
    async delete_api(obj) {
      const { code, msg } = await this.$apis.get(`/admin_delete_proxy`, obj);
      if (code == 0) {
        this.$message.success(`成功`);
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data ,
      } = await this.$apis.get(`/admin_get_admin_list`, {
        page_index: this.pageValue,
        page_size: this.pageSize,
        ...this.parmes,
      });
      if (code == 0) {
        this.fromData = data.map((elem) => ({
          ...elem,
          div_status: elem.checkStatus,
          loading: false,
        }));

      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
    backEnterFun(fun, txt = `确认删除？`) {
      this.$modal
        .confirm(txt)
        .then(() => {
          fun();
        })
        .then(async () => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
