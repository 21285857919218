var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "el-icon-document-copy copy",
    on: { click: _vm.copyText },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }