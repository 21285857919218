<template>
  <div class="chart-store pages-store flex-d">
    <div class="chart" ref="myChart"></div>

    <div class="flex-d flex-start-center">
      <div class="flex-start-center">
        <el-button type="text" @click="upData2(-dayTime())"> 前一天 </el-button>
        <div
          class="flex-center-center"
          style="margin: 0 10px; font-size: 18px; font-weight: bold"
        >
          {{ day }}
        </div>
        <el-button
          type="text"
          :disabled="time > maxtime"
          @click="upData2(dayTime())"
        >
          后一天
        </el-button>
      </div>
      <div class="chart-left" ref="myChart2"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let myChart = ``;
import { randomData, option, data } from "./chart-config/shop";
import option2 from "./chart-config/total";

let timeDefault = new Date().getTime();
export default {
  data() {
    return {
      inter: ``,
      time: timeDefault,
      maxtime: timeDefault,
      dataMap: {},
      data: [],
      myChart2: ``,
    };
  },
  async mounted() {
    let dom = this.$refs.myChart;
    // 基于准备好的dom，初始化echarts实例
    myChart = echarts.init(dom);
    myChart.setOption(option);

    let dom2 = this.$refs.myChart2;

    this.myChart2 = echarts.init(dom2);
    this.upData2();
    clearInterval(this.inter);
    // 绘制图表
    this.inter = setInterval(function () {
      // one
      for (var i = 0; i < 5; i++) {
        data.shift();
        data.push(randomData());
      }
      myChart.setOption({
        series: [
          {
            data: data,
          },
        ],
      });

      //two

      // myChart2.setOption({
      //   title: {
      //     text: "每日数据" + Math.random(),
      //   },
      // });
    }, 1000);
  },
  computed: {
    day() {
      let obj = new Date(this.time);
      return `${obj.getMonth() + 1} 月 ${obj.getDate()} 日`;
    },
  },
  methods: {
    dayTime() {
      return 1000 * 60 * 60 * 24;
    },
    upData2(time = this.dayTime()) {
      this.time += time;

      // console.log(this.dataMap, this.time, this.dataMap[this.time]);

      if (this.dataMap[this.time] != undefined) {
        this.data = this.dataMap[this.time];
      } else {
        this.dataMap[this.time] = this.data = [
          parseInt(3000 * Math.random()),
          parseInt(3000 * Math.random()),
          parseInt(3000 * Math.random()),
        ];
      }
      this.myChart2.setOption({
        ...option2,
        title: {
          text: `${this.day}数据`,
        },
        series: {
          data: this.data,
          type: "bar",
        },
      });
    },
  },
};
</script>

<style lang="scss">
.chart-store {
  .chart {
    width: 100%;
    height: 400px;
  }

  .chart-left {
    width: 100%;
    height: 400px;
  }
}
</style>
