import Vue from "vue";
import Router from "vue-router";
import store from '@/store'

/* Layout */
import Layout from "@/layout";

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
// export const constantRoutes = [
//   {
//     path: "/redirect",
//     component: Layout,
//     hidden: true,
//     children: [
//       {
//         path: "/redirect/:path(.*)",
//         component: () => import("@/views/redirect"),
//       },
//     ],
//   },
//   {
//     path: "/login",
//     component: () => import("@/views/login"),
//     hidden: true,
//   },
//   {
//     path: "/register",
//     component: () => import("@/views/register"),
//     hidden: true,
//   },
//   {
//     path: "/404",
//     component: () => import("@/views/error/404"),
//     hidden: true,
//   },
//   {
//     path: "/401",
//     component: () => import("@/views/error/401"),
//     hidden: true,
//   },
//   {
//     path: "",
//     component: Layout,
//     redirect: "index",
//     children: [
//       {
//         path: "index",
//         component: () => import("@/views/index"),
//         name: "Index",
//         meta: { title: "首页", icon: "dashboard", affix: true },
//       },
//     ],
//   },
//   {
//     path: "/user",
//     component: Layout,
//     hidden: true,
//     redirect: "noredirect",
//     children: [
//       {
//         path: "profile",
//         component: () => import("@/views/system/user/profile/index"),
//         name: "Profile",
//         meta: { title: "个人中心", icon: "user" },
//       },
//     ],
//   },
// ];

// [
//   {
//     path: "/",
//     name: "/home",
//     meta: { title: "index", icon: "dashboard" },
//     children: [
//       {
//         path: "/index",
//         meta: { title: "用户1", icon: "dashboard", affix: true },
//         name: "/index",
//       },
//       {
//         path: "/user",
//         meta: { title: "用户2", icon: "dashboard", affix: true },
//         name: "user",
//       },
//     ],
//   },
// ]
export const constantRoutes = [
  {
    hidden: true,
    name: `login`,
    path: "/login",
    meta: { title: "登录", icon: "dashboard", common: true },
    component: () => import("@/views/login"),
  },
  {
    hidden: true,
    name: `404`,
    path: "/404",
    meta: { title: "登录", icon: "dashboard", common: true },
    component: () => import("@/views/error/404"),
  },
  {
    hidden: true,
    name: `401`,
    path: "/401",
    meta: { title: "登录", icon: "dashboard", common: true },
    component: () => import("@/views/error/401"),
  },
];

// 动态路由，基于用户权限动态去加载
// export const dynamicRoutes = [];

// // 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// let routerReplace = Router.prototype.replace;
// // push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch((err) => err);
// };
// // replace
// Router.prototype.replace = function push(location) {
//   return routerReplace.call(this, location).catch((err) => err);
// };
export const dashboardName = '数据统计'

Vue.use(Router);

let router = new Router({
  // mode: "history", // 去掉url中的#
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem(`token`, to.query.token)
  }
  let token = localStorage.token;

  let { name } = to;
  console.log('to', to)
  if (token == undefined && to.name != "login") {
    next({ name: "login" });
  }
  else if (token && store.state.user.menus.length === 0) {
    store.dispatch("GetMenus").then((accessRoutes) => {
      console.log('accessRoutes', accessRoutes)
      router.addRoutes(accessRoutes) // 动态添加可访问路由表
      const routes = router.getRoutes().filter(route => !route.meta?.common)
      const isExist = routes.some(route => route.path === to.path)
      next({ ...to, name: isExist ? name : routes[0].name, replace: true }) // hack方法 确保addRoutes已完成
    }).catch(error => {
      console.log('error', error)
      localStorage.removeItem(`token`)
      next({ name: "login" });
    })
  }
  else if (name == undefined) {
    if (localStorage.getItem(`user_type`) === '2') {
      next({ name: `agent-user` });
    } else {
      next({ path: '' });
    }
  } else {
    // console.log('store.user.menus', store.state.user.menus)

      next();

  }
});

export default router;

const requireComponents = require.context('@/views/huoshan', true, /\.vue/);

export const views = requireComponents.keys().map(fileName => {
  const reqCom = requireComponents(fileName);
  const reqComName = reqCom.defautl?.name ?? fileName.replace(/\.\/(.*)\.vue/, '$1');
  return {
    name: reqComName,
    fileName,
    component: reqCom.default,
  }
})

console.log('views', views)

// 根据组件路径按 / 分割生成级联选择器选项
export const cascaderOptions = requireComponents.keys().reduce((acc, fileName) => {
  const paths = fileName.replace('./', '').split('/');

  let rootItem = acc.find(item => item.value === paths[0])

  if (!rootItem) {
    rootItem = { label: paths[0], value: paths[0], children: paths[0].endsWith('.vue') ? undefined : [] }
    acc.push(rootItem)
  }

  let currentItem = rootItem
  paths.slice(1).forEach(path => {
    const childItem = currentItem.children.find(n => n.label === path)
     || { label: path, value: path, children: path.endsWith('.vue') ? undefined : [] }
    currentItem.children.push(childItem)
    currentItem = childItem
  })
  return acc;
}, []);

console.log('cascaderOptions', cascaderOptions)

