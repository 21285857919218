<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center" style="gap: 10px;">
        <el-input
        v-if="!userId"
          placeholder="用户名"
          v-model.trim="parmes.userName"
          style="width: 180px"
          clearable
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-input
          placeholder="订单号"
          v-model.trim="parmes.orderNo"
          style="width: 220px"
          clearable
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-select v-model="parmes.orderType" placeholder="订单类型" clearable @change="getMessage(true)">
          <el-option v-for="item in orderType" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="parmes.userSubType" placeholder="订阅类型" clearable @change="getMessage(true)">
          <el-option v-for="item in subscriptionType" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button  type="success"
          size="mini"
          icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
        type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
      </div>

    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    <template #userSubInfoCount="{data}">
      <div>
        <div v-for="(value, key) in data.rows?.userSubInfoCount" :key="key">
          {{ purchaseTypeMap[key] }}：{{ value }}
        </div>
      </div>
    </template>
    </div-table>
    <el-drawer
      title="窗口详情"
      :visible.sync="drawer"
      :direction="direction"
      size="50%"
    >
      <div
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          class="margin-top"
          title="详细信息"
          :column="4"
          direction="vertical"
          border
        >
          <el-descriptions-item
            v-for="(key, index) in window_details_keys"
            :key="index"
            :label="key"
          >
            {{ window_details_obj[key] }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";
import { orderType,orderTypeMap, purchaseTypeMap } from "./constants";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // 保留两位小数
});

// 订阅类型(wallet/workspace/staff/profile/workspace_staff)
const subscriptionType = [
  {
    label: "窗口",
    value: "profile",
  },
  {
    label: "空间和成员",
    value: "workspace_staff",
  },
];

const subscriptionTypeMap = subscriptionType.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});


// 订阅等级(Basic/Pro/Business/Enterprise)
const subscriptionLevel = [
  {
    label: "Basic",
    value: "Basic",
  },
  {
    label: "Pro",
    value: "Pro",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Enterprise",
    value: "Enterprise",
  },
];


// 自动订阅状态: ACTIVE:激活,CANCEL:取消,DELETED:关闭,PENDING:待支付,EXPIRED:超时关闭,PAYMENT_FAILED:扣款失败
const autoSubscriptionStatus = [
  {
    label: "激活",
    value: "ACTIVE",
  },
  {
    label: "取消",
    value: "CANCEL",
  },
  {
    label: "关闭",
    value: "DELETED",
  },
  {
    label: "待支付",
    value: "PENDING",
  },
  {
    label: "超时关闭",
    value: "EXPIRED",
  },
  {
    label: "扣款失败",
    value: "PAYMENT_FAILED",
  },
];

const autoSubscriptionStatusMap = autoSubscriptionStatus.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

// 激活状态、 isActive  1:使用中 0:已使用 2:未激活
const isActiveMap = {
  "1": "使用中",
  "0": "已使用",
  "2": "未激活",
};


export default {
  components: {
    divTable,
  },
  props: {
    userId: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      purchaseTypeMap,
      window_details_obj: {},
      window_details_keys: [],
      drawer: false,
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {
        userId: this.userId,
      },
      loading: false,
      windowsMessage: {},
      orderType,
      subscriptionType,
      fromList: [
        {
          label: `ID`,
          key: `id`,
          visible: !this.userId
        },
        {
          label: `用户名`,
          key: `userName`,
          slot: "copy",
          width: 200,
          visible: !this.userId
        },
        {
          label: `订单类型`,
          key: `orderType`,
          visible: true,
          transValue: (val) => {
            return orderTypeMap[val] || val;
          },
        },
        {
          label: `订阅类型`,
          key: `userSubType`,
          visible: true,
          transValue: (val) => {
            return subscriptionTypeMap[val] || val;
          },
        },
        {
          label: `订阅等级`,
          key: `userSubLevel`,
          visible: true,
        },
        {
          label: `订阅详情`,
          key: `userSubInfoCount`,
          visible: true,
          slot: "userSubInfoCount",
          width: 200,
        },
        {
          label: `订阅月数`,
          key: `subscriptionMonths`,
          visible: true,
        },
        {
          label: `订阅开始时间`,
          key: `startDate`,
          width: 180,
          visible: true,
        },
        {
          label: `订阅结束时间`,
          key: `endDate`,
          width: 180,
          visible: true,
        },
        {
          label: `激活状态`,
          key: `isActive`,
          visible: true,
          transValue: (val) => {
            return isActiveMap[val] || val;
          },
        },
        {
          label: `是否试用`,
          key: `isTrial`,
          visible: true,
          transValue: (val) => {
            return val == 1 ? "是" : "否";
          },
        },
        {
          label: '过期提示',
          key: 'showSubExpiredDlg',
          visible: !this.userId,
          transValue: (val) => {
            return val == 1 ? "是" : "否";
          },
        },
        {
          label: `是否自动续费`,
          key: `isAuto`,
          visible: true,
          transValue: (val) => {
            return val == 1 ? "系统自动" : "人工";
          },
        },
        {
          label: `自动续费状态`,
          key: `autoStatus`,
          visible: true,
          transValue: (val) => {
            return autoSubscriptionStatusMap[val] || val;
          },
        },
        {
          label: `自动续费金额`,
          key: `actualAmount`,
          visible: true,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `创建时间`,
          key: `createTime`,
          width: 180,
          visible: !this.userId,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
          width: 180,
          visible: !this.userId,
        },
      ].filter(item => item.visible),
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    reSetParmes() {
      this.parmes = {
        userId: this.userId,
        userName: "",
        orderNo: "",
        orderType: "",
        userSubType: "",
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis
        .get(`/admin_get_user_subscription`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
