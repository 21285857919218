var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home pages-store" },
    [
      _c(
        "div",
        {
          staticClass: "flex-sw-center",
          staticStyle: { height: "50px", width: "100%" },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", gap: "10px" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px", "margin-right": "15px" },
                attrs: { placeholder: "扩展名" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.getMessage()
                  },
                },
                model: {
                  value: _vm.parmes.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.parmes,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "parmes.name",
                },
              }),
              _c("el-input", {
                staticStyle: { width: "280px", "margin-right": "15px" },
                attrs: { placeholder: "扩展目录" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.getMessage()
                  },
                },
                model: {
                  value: _vm.parmes.pluginDir,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.parmes,
                      "pluginDir",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "parmes.pluginDir",
                },
              }),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-right": "15px" },
                  attrs: { clearable: "", placeholder: "扩展分类" },
                  on: {
                    change: function ($event) {
                      return _vm.getMessage()
                    },
                  },
                  model: {
                    value: _vm.parmes.categoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.parmes, "categoryId", $$v)
                    },
                    expression: "parmes.categoryId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-right": "15px" },
                  attrs: { clearable: "", placeholder: "扩展来源" },
                  on: {
                    change: function ($event) {
                      return _vm.getMessage()
                    },
                  },
                  model: {
                    value: _vm.parmes.source,
                    callback: function ($$v) {
                      _vm.$set(_vm.parmes, "source", $$v)
                    },
                    expression: "parmes.source",
                  },
                },
                _vm._l(_vm.sources, function (item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", gap: "40px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "h30",
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getMessage(true)
                        },
                      },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "h30",
                      attrs: {
                        type: "warning",
                        size: "mini",
                        icon: "el-icon-refresh",
                      },
                      on: {
                        click: function ($event) {
                          _vm.reSetParmes()
                          _vm.getMessage(true)
                        },
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div-table", {
        attrs: {
          loading: _vm.loading,
          fromList: _vm.tableColumns,
          fromData: _vm.fromData,
          pageSize: _vm.pageSize,
          pageValue: _vm.pageValue,
          pageSizeList: _vm.pageSizeList,
          total: _vm.total,
        },
        on: {
          "up-page-size": function ($event) {
            _vm.pageSize = $event
          },
          "up-page-value": function ($event) {
            _vm.pageValue = $event
          },
          "get-message": function ($event) {
            return _vm.getMessage($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "pluginIcon",
            fn: function ({ data }) {
              return [
                _c("el-image", {
                  staticStyle: {
                    width: "30%",
                    height: "auto",
                    cursor: "pointer",
                  },
                  attrs: {
                    src: data.rows.pluginIcon,
                    fit: "cover",
                    title: "点击复制链接",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.copyLink(data.rows.pluginIcon)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "rowWrap",
            fn: function ({ data }) {
              return [
                _c("p", { staticClass: "rowWrap" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        `${data.rows.createTime} / ${data.rows.updateTime}`
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "rowWrapName",
            fn: function ({ data }) {
              return [
                _c("p", { staticClass: "rowWrap" }, [
                  _vm._v(" " + _vm._s(data.rows.pluginName) + " "),
                ]),
              ]
            },
          },
          {
            key: "rowWrapcategoryNames",
            fn: function ({ data }) {
              return [
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(data.rows.categoryNames.join(",") || "-")),
                ]),
              ]
            },
          },
          {
            key: "operate",
            fn: function ({ data }) {
              return [
                _c(
                  "div",
                  { staticClass: "operate flex-center-center" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.changeList(data.rows, "del")
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogShow,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.from_type == "add" ? "新增" : "编辑")),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#ebb563",
                    "font-size": "14px",
                    "line-height": "1.5",
                    "font-weight": "bold",
                    width: "90%",
                    margin: "0 0 -20px 9%",
                  },
                },
                [
                  _vm._v(
                    " 温馨提示：在标准的chrome扩展文件夹中，会包含一个manifest.json文件，此文件中含有扩展名称与版本等信息，请选择manifest.json所在目录的上上层目录，如：fgmbnmjmbjenlhbefngfibmjkpbcljaj。 "
                  ),
                ]
              ),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "lable-postition": "right", "label-width": "120px" },
            },
            _vm._l(
              _vm.showPageList(
                _vm.dialogList,
                _vm.from_type == "add" ? _vm.addList : _vm.editList
              ),
              function (value, index) {
                return _c(
                  "el-form-item",
                  { key: index, attrs: { label: value.label } },
                  [
                    value.type == "select"
                      ? _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder:
                                value.key == "sortNum" ||
                                value.key == "categoryNames"
                                  ? "请输入"
                                  : "自动生成",
                            },
                            model: {
                              value: _vm.dialogFrom.categoryIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialogFrom, "categoryIds", $$v)
                              },
                              expression: "dialogFrom.categoryIds",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        )
                      : value.key == "pluginIcon"
                      ? _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: _vm.uploadUrl,
                              "show-file-list": false,
                              limit: 1,
                              "before-upload": _vm.beforeUpload,
                              "on-success": _vm.handleSuccess,
                              "on-error": _vm.handleError,
                            },
                          },
                          [
                            _vm.dialogFrom.pluginIcon
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.dialogFrom.pluginIcon },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        )
                      : value.key == "pluginRoute"
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  width: "100%",
                                  height: "36px",
                                  "text-align": "center",
                                  "line-height": "36px",
                                  color: "#2692ff",
                                  border: "1px dashed #2692ff",
                                  "border-radius": "6px",
                                  overflow: "hidden",
                                },
                                attrs: { clsss: "upload-demo" },
                              },
                              [
                                _vm.hasPluginRoute
                                  ? _c("input", {
                                      key: _vm.inputKey,
                                      staticStyle: {
                                        position: "absolute",
                                        left: "0",
                                        margin: "auto",
                                        width: "100%",
                                        height: "100%",
                                        opacity: "0",
                                        "z-index": "9",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        type: "file",
                                        webkitdirectory: "",
                                        directory: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.beforeAvatarUpload },
                                    })
                                  : _vm._e(),
                                _vm.hasPluginRoute
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "0",
                                          left: "0",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "点击此处选择扩展，系统会自动上传并识别扩展信息"
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "96%",
                                          "text-overflow": "ellipsis",
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.hasPluginRoute =
                                              !_vm.hasPluginRoute
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.dialogFrom.pluginRoute) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            ),
                            _vm.isShowProgress
                              ? _c("el-progress", {
                                  attrs: { percentage: _vm.progress },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("el-input", {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            placeholder:
                              value.key == "sortNum" ||
                              value.key == "categoryNames"
                                ? "请输入"
                                : "自动生成",
                            disabled: value.disabled || false,
                          },
                          model: {
                            value: _vm.dialogFrom[value.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogFrom, value.key, $$v)
                            },
                            expression: "dialogFrom[value.key]",
                          },
                        }),
                  ],
                  1
                )
              }
            ),
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-end-center",
              staticStyle: { "margin-top": "15px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v(" 提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("plug-upgrade", {
        ref: "upgrade",
        attrs: { visible: _vm.showPlugUpgrade, total: _vm.upgradeTotal },
        on: {
          "update:visible": function ($event) {
            _vm.showPlugUpgrade = $event
          },
          "update:total": function ($event) {
            _vm.upgradeTotal = $event
          },
        },
      }),
      _c("div-table-detail", {
        attrs: {
          list: _vm.fromList,
          data: _vm.tableData,
          visible: _vm.tableDetailShow,
        },
        on: {
          "update:visible": function ($event) {
            _vm.tableDetailShow = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "rowWrap",
            fn: function ({ data }) {
              return [
                _c("span", [
                  _vm._v(_vm._s(`${data.createTime} / ${data.updateTime}`)),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }