const commonConfig = {
  grid: {
    left: 35,
    right: 35,
    bottom: 20,
    top: 60,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    padding: [5, 10]
  },
  yAxis: {
    type: 'value',
    axisTick: {
      show: false
    }
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
}

export const getUserRegisterLineData = (title, data = []) => {
  return {
    ...commonConfig,
    title: {
      text: title,
      color: '#000'
    },
    xAxis: {
      data: data.map((item, index) => item.day),
      boundaryGap: false,
      axisTick: {
        show: false
      }
    },
    legend: {
      data: []
    },
    series: [
      {
        name: title,
        lineStyle: {
          color: '#FF005A',
          width: 2
        },
        itemStyle: {
          color: '#FF005A',
        },
        label: {
          show: true,
          fontSize: 15
        },
        smooth: true,
        type: 'line',
        data: data.map(item => item.data),
        animationDuration: 2800,
        animationEasing: 'cubicInOut'
      },
    ],
  }
}

export const getUserRechargeLineData = (title, data = []) => {
  return {
    ...commonConfig,
    title: {
      text: title,
      color: '#000'
    },
    xAxis: {
      data: data.map((item, index) => item.day),
      boundaryGap: false,
      axisTick: {
        show: false
      }
    },
    legend: {
      data: []
    },
    series: [
      {
        name: title,
        lineStyle: {
          color: '#FF005A',
          width: 2
        },
        itemStyle: {
          color: '#FF005A',
        },
        label: {
          show: true,
          fontSize: 15
        },
        smooth: true,
        type: 'line',
        data: data.map(item => item.data / 100),
        animationDuration: 2800,
        animationEasing: 'cubicInOut'
      },
    ],
  }
}

export const getWindowCreateLineData = (title, data = []) => {
  return {
    ...commonConfig,
    title: {
      text: title
    },
    xAxis: {
      data: data.map((item, index) => item.day),
      boundaryGap: false,
      axisTick: {
        show: false
      }
    },
    legend: {
      data: []
    },
    series: [
      {
        name: title,
        lineStyle: {
          color: '#fac858',
          width: 2
        },
        itemStyle: {
          color: '#fac858',
        },
        label: {
          show: true,
          fontSize: 15
        },
        smooth: true,
        type: 'line',
        data: data.map(item => item.data),
        animationDuration: 2800,
        animationEasing: 'cubicInOut'
      },
    ],
  }
}

export const getRechargeLineData = (title, data = []) => {
  return {
    ...commonConfig,
    title: {
      text: title
    },
    xAxis: {
      data: data.map((item, index) => item.day),
      boundaryGap: false,
      axisTick: {
        show: false
      }
    },
    legend: {
      data: []
    },
    series: [
      {
        name: title,
        lineStyle: {
          color: '#5470c6',
          width: 2
        },
        itemStyle: {
          color: '#5470c6',
        },
        label: {
          show: true,
          fontSize: 15
        },
        smooth: true,
        type: 'line',
        data: data.map(item => item.data / 100),
        animationDuration: 2800,
        animationEasing: 'cubicInOut'
      },
    ],
  }
}

export const getVersionPieData = (title, data = []) => {
  return {
    title: {
      text: title,
      left: 'center',
      textStyle: {
        fontSize: 20,
      }
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      left: 'center',
      bottom: '10',
      textStyle: {
        fontSize: 16,
      }
    },
    series: [
      {
        name: `${title} 版本`,
        type: 'pie',
        radius: '50%',
        label: {
          fontSize: 16
        },
        data: data.map(item => ({ ...item, value: item.count, name: item.appVersion })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}


export const getLanguagePieData = (data = []) => {
  return {
    title: {
      text: '用户语言',
      // subtext: 'Fake Data',
      left: 'center',
      top: '50',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      left: 'center',
      bottom: '10',
      textStyle: {
        fontSize: 16,
      }
    },
    series: [
      {
        name: '中英人数',
        type: 'pie',
        radius: '50%',
        label: {
        formatter: '{c}人',
        // position: 'inside'
      },
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}


export const getChannelSourcePieData = (data = []) => {
  return {
    title: {
      text: '渠道来源人数',
      // subtext: 'Fake Data',
      left: 'center',
      top: '50',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      left: 'center',
      bottom: '10',
      textStyle: {
        fontSize: 16,
      }
    },
    series: [
      {
        name: '渠道来源人数',
        type: 'pie',
        radius: '50%',
        label: {
        formatter: '{c}人',
      },
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}
