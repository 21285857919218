var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home pages-store" },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "self_info", staticStyle: { "font-size": "18px" } },
          [
            _vm._v(" 您的提成比例为 "),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.selfInfo.prize_rate) + "%"),
            ]),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "regUrl",
            staticStyle: { "margin-bottom": "0px", "font-size": "16px" },
          },
          [
            _vm._v(" 您的专属推广链接："),
            _c("a", { staticStyle: { color: "#1682e6" } }, [
              _vm._v(
                "https://roxybrowser.com?roxy_partner=" +
                  _vm._s(_vm.selfInfo.user_name)
              ),
            ]),
            _c("i", {
              staticClass: "el-icon-document-copy",
              staticStyle: {
                color: "blue",
                "margin-left": "3px",
                cursor: "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.clipboardText(
                    `https://roxybrowser.com?roxy_partner=${_vm.selfInfo.user_name}`
                  )
                },
              },
            }),
            _c("br"),
            _vm._v(
              " 凡是通过您链接注册进来的客户，您能看到名下所有账户的注册信息。 "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticStyle: { "margin-top": "30px", "font-weight": "700" } },
        [_vm._v("每月注册信息")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData1, "max-height": "800" },
        },
        [
          _c("el-table-column", { attrs: { prop: "month", label: "月份" } }),
          _c("el-table-column", {
            attrs: { prop: "user_cnt", label: "用户数量" },
          }),
          _c("el-table-column", {
            attrs: { prop: "prize_amount", label: "提成金额(美元)" },
          }),
          _c("el-table-column", {
            attrs: { prop: "prize_time", label: "提成发放时间" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }