<template>
  <div>
    <el-dialog
    :close-on-click-modal="false"
    title="推荐明细"
    :visible.sync="dialogVisible"
     >
    <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
      <el-tab-pane label="按月记录" name="first">
        <el-table
          :data="tableData1"
          style="width: 100%">
          <el-table-column
            prop="month"
            label="月份"
            width="180">
          </el-table-column>
          <el-table-column
            prop="user_cnt"
            label="推荐的用户数量"
            width="180">
          </el-table-column>
          <el-table-column
            prop="prize_amount"
            label="提成金额(美元)">
          </el-table-column>
          <el-table-column
            prop="prize_time"
            label="提成时间">
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button
               v-if="scope.row.prize_amount === -1"
               @click="handleGrantCommissions(scope.row)"
               type="text" size="small">
               发放提成
              </el-button>
              <div v-else>已发放</div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="人员明细" name="second">
        <el-table
          :data="tableData2"
          style="width: 100%">
          <el-table-column
            prop="user_name"
            label="用户名"
            width="180">
          </el-table-column>
          <el-table-column
            prop="win_cnt"
            label="已建窗口数量"
            width="180">
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="注册时间">
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注">
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_index"
          :page-sizes="[15, 50, 100, 200]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>

  <el-dialog title="发放提成" width="500px" :visible.sync="dialogFormVisible">
    <el-form :model="form">
      <el-form-item label="月份">
        <el-input v-model="form.month" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="金额(美元)">
        <el-input v-model="form.amount" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="giveOut">确 定</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user_id: '',
        total: 0,
        page_size: 15,
        page_index: 1,
        activeName: 'first',
        form: {
          month: '',
          amount: '',
          remark: ''
        },
        dialogVisible: false,
        dialogFormVisible: false,
        tableData1: [],
        tableData2: []
      };
    },
    methods: {
      show(user_id) {
        this.dialogVisible = true;
        this.user_id = user_id
        this.getByMonth()
      },
      handleTabClick(tab, event) {
        if (tab.name === 'first') {
          this.getByMonth()
        } else {
          this.getByDetail()
        }
      },
      getByMonth() {
        this.$apis.get('/admin_get_admin_by_month', {user_id: this.user_id}).then(res => {
          this.tableData1 = res.data;
        })
      },
      getByDetail() {
        this.$apis.get('/admin_get_admin_detail', {
          user_id: this.user_id,
          page_size: this.page_size,
          page_index:this.page_index,
        }).then(res => {
          this.tableData2 = res.data.rows;
          this.total = res.data.total;
        })
      },
      handleSizeChange(val) {
        this.page_size = val
        console.log(`每页 ${val} 条`);
        this.getByDetail()
      },
      handleCurrentChange(val) {
        this.page_index = val
        console.log(`当前页: ${val}`);
        this.getByDetail()
      },
      handleGrantCommissions(row) {
        this.form = {
          month: row.month,
          amount: row.prize_amount,
          remark: row.remark
        }
        this.dialogFormVisible = true;

      },
      /** 发放提成 */
      giveOut() {
        this.$apis.get('/admin_send_agent_prize', {
          ...this.form,
          admin_id: this.user_id,
        }).then(res => {
          this.$message({
            message: '发放成功',
            type: 'success'
          });
          this.dialogFormVisible = false;
          this.getByMonth()
        })
      }
    }
  };
</script>
