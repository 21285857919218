var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.title,
        visible: _vm._visible,
        "before-close": _vm.handleClose,
        size: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm._visible = $event
        },
      },
    },
    [
      _vm.data
        ? _c(
            "div",
            {
              ref: "drawer",
              staticClass: "flex-d",
              staticStyle: {
                width: "calc(100% - 30px)",
                margin: "0 15px",
                padding: "5px 10px",
              },
            },
            [
              _c(
                "el-descriptions",
                {
                  attrs: {
                    title: "详细信息",
                    direction: "vertical",
                    column: 2,
                    border: "",
                  },
                },
                _vm._l(_vm._list, function (item, index) {
                  return _c(
                    "el-descriptions-item",
                    {
                      key: index,
                      attrs: {
                        label: item.label,
                        "label-class-name":
                          item.label == "cookie" || item.label == "User Agent"
                            ? "bright"
                            : "label-color",
                      },
                    },
                    [
                      _vm._t(
                        item.slot,
                        function () {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top-start",
                                  content: JSON.stringify(
                                    item.transValue?.(
                                      _vm.data[item.key],
                                      _vm.data
                                    ) || _vm.data[item.key]
                                  ),
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style:
                                      item.trans &&
                                      item.trans(_vm.data[item.key]),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.transValue?.(
                                          _vm.data[item.key],
                                          _vm.data
                                        ) ||
                                          _vm.data[item.key] ||
                                          "-"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        { data: _vm.data }
                      ),
                      !_vm.isEmpty(_vm.data[item.key])
                        ? _c("icon-copy", {
                            staticClass: "icon-copy",
                            attrs: { value: _vm.data[item.key] },
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }