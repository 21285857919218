var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm._l(_vm.pieChartList, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("pie-chart", {
              attrs: {
                height: "500px",
                config: item,
                updateSize: _vm.$attrs.updateSize,
              },
              on: { "pie-click": _vm.handlePieClick },
            }),
          ],
          1
        )
      }),
      _c("pie-detail", {
        attrs: {
          title: "平台数据详情",
          columns: _vm.columns,
          data: _vm.tableData,
          visible: _vm.visible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          "get-message": _vm.getMessage,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }