<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="用户名" v-model.trim="parmes.username" style="width: 180px; margin-left: 15px"
          @keydown.enter.native="getMessage(true)" clearable></el-input>
        <el-input placeholder="邮箱" v-model.trim="parmes.email" style="width: 180px; margin-left: 15px"
          @keydown.enter.native="getMessage(true)" clearable></el-input>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search"
          @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="
          fromInit();
        from_type = `add`;
        show = true;
        ">
          新增
        </el-button>
      </div>
    </div>

    <div-table ref="userTableRef" :H="null" :loading="loading" :fromList="fromList" :fromData="fromData"
      :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total"
      @up-page-size="pageSize = $event" @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template v-slot:maxWindowCount="{ data }">
        {{ data.rows.maxWindowCount ? data.rows.maxWindowCount : "-" }}
      </template>
      <template v-slot:createTime="{ data }">
        <p class="openingTime">{{ data.rows.createTime }}</p>
      </template>
      <template v-slot:operate="{ data }">
        <div class="operate flex-start-center">
          <el-link :underline="false" type="danger" style="margin-right: 20px"
            @click="changeList(data.rows, 'word', '留言')">留言</el-link>
          <el-link :underline="false" type="danger" style="margin-right: 20px"
            @click="changeList(data.rows, 'recharge', '充值')">充值</el-link>
          <el-link :underline="false" type="warning" style="margin-right: 20px"
            @click="changeList(data.rows, 'edit', '编辑用户')">编辑</el-link>
          <el-link :underline="false" type="danger" :style="`margin-right: 20px;color:${data.rows.status == 1 ? 'red' : '#2692ff'
            }`" @click="changeList(data.rows, 'freeze', '冻结')">{{ data.rows.status == 1 ? "冻结" : "解冻" }}</el-link>
        </div>
      </template>
    </div-table>
    <!-- 充值明细 -->

    <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="50%">
      <el-tabs  v-if="drawer" class="flex-d" style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px">
        <el-tab-pane label="账户信息" >
          <div >
        <el-descriptions direction="vertical" border style="margin-bottom: 10px" :column="5">
          <el-descriptions-item v-for="(item, index) in drawerList" :key="index" :label="item.label">
            <el-tooltip class="item" effect="dark" placement="top-start" :content="item.transValue == undefined
                ? JSON.stringify(userMessage[item.key]) || `-`
                : JSON.stringify(
                  item.transValue(userMessage[item.key], userMessage)
                )
              ">
              <span>{{
                item.transValue == undefined
                  ? userMessage[item.key] || "-"
                  : item.transValue(userMessage[item.key], userMessage)
              }}</span>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
      </div>
        </el-tab-pane>
        <el-tab-pane label="支付记录"  >
          <RechargeRecord :userId="userMessage.id" tableHeight="600px" style="all: unset;" />
        </el-tab-pane>
        <el-tab-pane label="权益信息"  >
          <el-descriptions direction="vertical" border style="margin-bottom: 10px" :column="5">
          <el-descriptions-item v-for="(item, index) in benefits" :key="index" :label="item.label">
            {{ benefitData[item.key] }}
          </el-descriptions-item>
        </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="订阅信息"  >
          <UserSubscription :userId="userMessage.id" tableHeight="600px" style="all: unset;" />
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    <el-dialog :title="from_type == 'add' ? '新增用户' : title" :visible.sync="show" width="50%">
      <el-form lable-postition="right" ref="form" :rules="rules" label-width="120px">
        <div v-if="from_type == 'word'">
          <el-form-item label="ID">
            <el-input type="text" style="margin-bottom: 10px" v-model="notifyForm.id" placeholder="请输入"
              :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="留言内容">
            <el-input type="textarea" style="margin-bottom: 10px" v-model="notifyForm.notify"
              placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item v-for="(value, index) in from_items" v-show="value.model == from_type ||
            ((value.model == 'id' || value.key == 'userName') &&
              from_type != 'add') ||
            (from_type == 'add'
              ? (value.model == 'edit' && !value.disabled) ||
              value.key == 'userName'
              : false)
            " :label="value.label" :key="index">
            <el-input v-if="
              value.model == from_type ||
              value.model == 'id' ||
              value.key == 'userName' ||
              from_type == 'add'
            " :type="value.type ? value.type : 'text'" style="margin-bottom: 10px" v-model="from[value.key]"
              placeholder="请输入" :disabled="value.disabled
                  ? from_type != 'add'
                    ? value.disabled
                    : value.key != 'userName'
                  : false
                "></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px" v-show="from_type != 'detail'">
        <el-button type="primary" @click="
          from_type == 'price'
            ? changePrice()
            : from_type == 'recharge'
              ? recharge()
              : submit()
          ">
          提交</el-button>
      </div>
    </el-dialog>
    <!-- 充值明细 -->
    <el-dialog width="50%" :visible.sync="detailShow">
      <div slot="title">
        <span style="color: #2692ff;">{{ from.userName }}</span>
        充值明细
      </div>
      <div-table :loading="rechargeLoading" :fromList="rechargeDetailList" :fromData="rechargeDetailData"
        :infoPage="false">
      </div-table>
    </el-dialog>
    <el-dialog title="充值" :visible.sync="isShowRechargeDialog" width="50%">
      <el-form lable-postition="right" ref="form" :rules="rules" label-width="150px">
        <el-form-item label="充值金额（美元）">
          <el-input type="text" style="margin-bottom: 10px" v-model.number="rechargeForm.amount"
            placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="充值备注">
          <el-input type="textarea" style="margin-bottom: 10px" v-model="rechargeForm.remark"
            placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px" v-show="from_type != 'detail'">
        <el-button type="primary" @click="addRecharge">
          提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
class tableObj {
  constructor(obj = {}) {
    obj = {
      fromList: [],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      loading: false,
      ...obj,
    };

    Object.keys(obj).forEach((key) => {
      this[key] = obj[key];
    });
  }
}
import divTable from "./components/div-table.vue";
import md5 from "./coom/md5.js";
import { formatCurrency } from "@/utils";
import { rechargeTypeMap, rechargeMethodMap } from "./pay-plan/constants";
import RechargeRecord from "./pay-plan/recharge-record.vue";
import UserSubscription from "./pay-plan/user-subscription.vue";
// 用户身份 identity  （创始用户 :old   新用户:new  特邀用户:invited）
const userIdentityMap = {
  old: "创始用户",
  new: "新用户",
  invited: "特邀用户",
}

// linkLevel   （LinkC : A     LinkB : B   LinkA : C    LinkS : D）
const linkLevelMap = {
  A: "LinkC",
  B: "LinkB",
  C: "LinkA",
  D: "LinkS",
}

// 注册类型：regType（直接注册:1  谷歌注册:2  github注册: 3）
const regTypeMap = {
  1: "直接注册",
  2: "谷歌注册",
  3: "github注册",
}

// 最近1个月活跃状态：：lastLoginStatus（没有登录: 0，登录过:1 ）
const lastLoginStatusMap = {
  0: "没有登录",
  1: "登录过",
}

export default {
  components: {
    divTable,
    RechargeRecord,
    UserSubscription
  },
  data() {
    return {
      drawer: false,
      isShowRechargeDialog: false,
      rechargeForm: {},
      direction: "rtl",
      parmes: { role: "main" },
      // 主表格
      userMessage: {},
      loading: false,
      fromList: [
        {
          label: `ID`,
          key: `id`,
          width: `110px`,
          slot: "id",
          enableCopy: true,
          click: async (value) => {
            if (value.role == "main") {
              this.drawer = true;
              this.userMessage = value;
              this.getBenefitData(value.id);
              // this.getRechargeDetail(value.id);
            }
          },
          trans: (value) => {
            if (value.role == "main") {
              return `color: blue;font-weight: bold;cursor: pointer;`;
            }
          },
        },
        {
          label: `用户名`,
          key: `userName`,
          slot: "copy",
          width: '160px'
        },
        {
          label: `邮箱`,
          key: `email`,
          slot: "copy",
          width: '180px'
        },
        {
          label: `用户身份`,
          key: `identity`,
          transValue: (val) => {
            return userIdentityMap[val] || "-";
          },
        },
        {
          label: `链接等级`,
          key: `linkLevel`,
          transValue: (val) => {
            return linkLevelMap[val] || "-";
          },
        },
        {
          label: `折扣(%)`,
          key: `discount`,
          transValue: (val) => {
            return `${val}%` || "-";
          },
        },
        {
          label: `注册类型`,
          key: `regType`,
          transValue: (val) => {
            return regTypeMap[val] || "-";
          },
        },
        {
          label: `今日可打开总数`,
          key: `maxOpenCount`,
        },
        {
          label: `今日已打开总数`,
          key: `useOpenCount`,
        },

        {
          label: `最大可创建空间数量`,
          key: `maxWorkspaceCount`,
        },
        {
          label: `开户时间`,
          key: `createTime`,
          slot: `createTime`,
          width: "160px",
        },
        {
          label: `总充值($)`,
          key: `rechargeAmount`,
          transValue: (val) => {
            return val / 100;
          },
        },
        {
          label: `余额($) `,
          key: `walletBalance`,
          transValue: (val) => {
            return val / 100;
          },
        },
        {
          label: `最近1个月活跃状态`,
          key: `lastLoginStatus`,
          transValue: (val) => {
            return lastLoginStatusMap[val] || "-";
          },
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "200px",
        },
      ],
      benefitData: {},
      benefits: [
        {
          label: `最大空间数量(含免费)`,
          key: `maxWorkspaceCount`,
        },
        {
          label: `已用空间数量`,
          key: `useWorkspaceCount`,
        },
        {
          label: `最大成员数量(含免费)`,
          key: `maxMemberCount`,
        },
        {
          label: `已用成员数量`,
          key: `useMemberCount`,
        },
        {
          label: `最大窗口数量(含免费)`,
          key: `maxWindowCount`,
        },
        {
          label: `已用窗口数量`,
          key: `useWindowCount`,
        },
        {
          label: `今日可创建次数`,
          key: `totalCreateWindowTimes`,
        },
        {
          label: `今日已创建次数`,
          key: `createWindowTimes`,
        },
        {
          label: `今日可打开次数`,
          key: `totalOpenWindowTimes`,
        },
        {
          label: `今日已打开次数`,
          key: `openWindowTimes`,
        },
        {
          label: `已使用项目数量`,
          key: `useProjectCount`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      // 子用户信息弹窗
      checkValue: "staff",
      checkValueList: ["staff", "manager", "admin"],
      child_table: new tableObj(),
      // 操作表单
      from_type: "add",
      title: "",
      show: false,
      from: {},
      from_items: [
        {
          label: `ID`,
          key: `id`,
          model: "id",
          disabled: true,
        },
        {
          label: `用户名`,
          key: `userName`,
          model: "edit",
          disabled: true,
        },
        {
          label: `余额($)`,
          key: `leftAmount`,
          model: "edit",
          disabled: true,
        },
        {
          label: `总充值`,
          key: `totalAmount`,
          model: "edit",
          disabled: true,
        },
        // {
        //   label: `住宅IP单价`,
        //   key: `price`,
        //   model: "edit",
        //   disabled: true,
        // },
        {
          label: "金额($)",
          key: "amount",
          model: "recharge",
        },
        {
          label: "备注",
          key: "remark",
          type: "textarea",
          model: "recharge",
        },
        {
          label: "输入密码",
          key: "password",
          model: "edit",
        },
        {
          label: "已创建员工总数",
          key: "useUserCount",
          disabled: true,
        },
        {
          label: "今日可打开总数",
          key: "maxOpenCount",
        },
        {
          label: "今日已打开总数",
          key: "useOpenCount",
          disabled: true,
        },
        {
          label: "开户时间",
          key: "createTime",
          disabled: true,
        },
        {
          label: "输入手机号",
          key: "phone",
        },
        {
          label: "输入邮箱",
          key: "email",
          model: "edit",
        },
        {
          label: "输入备注",
          key: "remark",
          type: "textarea",
          model: "edit",
        },
        {
          label: "住宅IP单价($)",
          key: "price",
          model: "price",
        },
        {
          label: "城市IP单价($)",
          key: "cityPrice",
          model: "price",
        },
        {
          label: "手机IP单价($)",
          key: "mobilePrice",
          model: "price",
        },
        {
          label: "机房IP单价($)",
          key: "dcPrice",
          model: "price",
        },
        {
          label: `token`,
          key: `token`,
          model: "edit",
          disabled: true,
        },
        {
          label: `邀请链接`,
          key: `inviteLink`,
          model: "edit",
          disabled: true,
        },
      ],
      rules: {},
      // 充值明细表
      detailShow: false,
      rechargeLoading: false,
      rechargeDetailList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: "充值类型",
          key: "rechargeType",
          transValue: (val) => {
            return rechargeTypeMap[val] || "-";
          },
        },
        {
          label: "充值方式",
          key: "rechargeMethod",
          transValue: (val) => {
            return rechargeMethodMap[val] || "-";
          },
        },
        {
          label: "金额",
          key: "amount",
          transValue: (val) => {
            return formatCurrency(val / 100);
          },
        },
        {
          label: "备注",
          key: "remark",
        },
        {
          label: "创建时间",
          key: "createTime",
        },
      ],
      rechargeDetailData: [],
      rechargeDetailTotal: 0,
      key: "",
      drawerList: [
        {
          label: "姓名",
          key: "userName",
        },
        {
          label: "密码",
          key: "password",
        },
        {
          label: "备注",
          key: "remark",
          solt: "copy",
        },
        {
          label: "手机号",
          key: "phone",
        },
        {
          label: "邮箱",
          key: "email",
        },
      ],
      notifyForm: {
        id: "",
        notify: "",
      },
    };
  },
  created() {
    this.$dicts.getSystemDict();
  },
  async mounted() {
    this.getMessage();
    this.reSetParmes();
  },

  watch: {},
  methods: {
    async getBenefitData(userId) {
      const { code, data, msg } = await this.$apis.get(`/admin_get_privilege_info`, {
        userId,
      });
      if (code == 0) {
        this.benefitData = data;
      } else {
        this.$message.error(msg);
      }
    },
    async addRecharge() {
      console.log('this.rechargeForm', this.rechargeForm)
      if (!this.rechargeForm.amount || this.rechargeForm.amount <= 0) {
        this.$message.error("充值金额必须大于0");
        return;
      }

      const { code, msg } = await this.$apis.post("/admin_recharge", {
        ...this.rechargeForm,
        amount: this.rechargeForm.amount * 100,
      }).catch((e) => ({
        code: 1,
      }));
      if (code == 0) {
        this.$message.success("充值成功");
        this.getMessage();
        this.isShowRechargeDialog = false;
        this.rechargeForm = {};
      } else {
        this.$message.error(msg);
      }
    },
    async changeList(obj, type, title) {
      if (type == "recharge") {
        this.isShowRechargeDialog = true;
        this.rechargeForm = {
          userId: obj.id,
        };
        return;
      }
      if (type == "word") {
        this.show = true;
        this.title = title;
        this.from_type = type;
        this.notifyForm.id = obj.id;
      }
      if (type != "freeze" && type != "detail") {
        this.show = true;
        this.title = title;
        this.from = obj;
        this.from_type = type;
      }
      if (type == "detail") {
        this.detailShow = true;
        this.title = title;
        this.from = obj;
        this.from_type = type;
        this.getRechargeDetail(obj.id);
      }
      if (type == "freeze") {
        const {
          code,
          data: { rows, total },
          msg,
        } = await this.$apis
          .get(
            `/${obj.status == 1 ? "admin_close_user" : "admin_active_user"}`,
            {
              id: obj.id,
            }
          )
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
        } else {
          this.$message.error(msg);
        }
      }
    },
    fromInit() {
      this.from = {
        password: "",
        phone: "",
        email: "",
        remark: "",
      };
    },
    async submit() {
      if (this.from_type == "word") {
        const { code, data, msg } = await this.$apis
          .post(`/admin_user_notify`, this.notifyForm)
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
          this.show = false;
        } else {
          this.$message.error(msg);
        }
      } else {
        const { data, code, msg } = await this.$apis
          .post(
            `/${this.from_type == `add` ? `admin_create_user` : `admin_mdf_user`
            }`,
            this.from
          )
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
          this.show = false;
        } else {
          this.$message.error(msg);
        }
      }
    },
    // 修改单价
    async changePrice() {
      let { cityPrice, dcPrice, id, mobilePrice, price } = this.from;
      cityPrice = cityPrice - 0;
      dcPrice = dcPrice - 0;
      mobilePrice = mobilePrice - 0;
      price = price - 0;
      const { data, code, msg } = await this.$apis
        .post(`/admin_mdf_main_wallet`, {
          cityPrice,
          dcPrice,
          id,
          mobilePrice,
          price,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      console.log(data, code);
      if (code == 0) {
        this.$message.success(`成功`);
        this.getMessage();
        this.show = false;
      } else {
        this.$message.error(msg);
      }
    },
    reSetParmes() {
      this.parmes = {
        username: "",
        role: `main`,
      };
    },
    handleClose(done) {
      return done();
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 获取充值key
    async recharge() {
      const { code, data, msg } = await this.$apis
        .get(`/admin_get_recharge_key`, {
          id: this.from.id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.key = data;
        // this.from.signature = md5(data + localStorage.token + this.from.id + this.from.amount);
        this.from.signature = md5(data + this.from.amount * 100);
        this.addRecharge();
      } else {
        this.$message.error(msg);
      }
    },
    // 获取充值详情
    async getRechargeDetail(userId) {
      this.rechargeLoading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_recharge_record`, {
          page_index: 1,
          page_size: 15,
          userId,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.rechargeLoading = false;
        });
      if (code == 0) {
        this.rechargeDetailData = rows;
        this.rechargeDetailTotal = total;
      } else {
        this.$message.error(msg);
      }
      this.rechargeLoading = false;
    },
    // 获取列表
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_main_user`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;

        // let fromListMap = [];
        // Object.keys(rows[0]).forEach((key) => {
        //   fromListMap.push({
        //     label: key,
        //     key: key,
        //   });
        // });
        // this.fromList = fromListMap;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },

    // 获取用户信息
    async getUserMessage(id) {
      const {
        code,
        data: { user },
        msg,
      } = await this.$apis
        .get(`/admin_get_main_user_by_id`, {
          id: id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });

      console.log(code, user);
      if (code == 0) {
        return {
          info: true,
          data: user,
        };
      } else {
        this.$message.error(msg);
      }
      return {
        info: false,
      };
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.h30 {
  height: 30px;
}

.openingTime {
  white-space: normal;
  margin: 0;
}
</style>
