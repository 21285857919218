export default {
  title: {
    text: "一月数据",
  },
  xAxis: {
    type: "category",
    data: [`每日新增客户数量`, `每日订单数量`, `每日营业统计`],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: [200, 400, 3000],
      type: "bar",
    },
  ],
};
