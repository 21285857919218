<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="角色名称" v-model.trim="parmes.role" style="width: 180px; margin-left: 15px"
        @keydown.enter.native="getMessage(true)" clearable></el-input>
        <el-button  type="success"
          size="mini" style="margin-left: 20px"
          icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
        type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">

        <el-button type="primary" size="mini" icon="el-icon-plus" @click="showDialog({})">新增角色</el-button>

      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    <template v-slot:operate="{ data }">
        <div class="operate flex-start-center">
          <el-link
            :underline="false"
            type="warning"
            style="margin-right: 20px"
            @click="showDialog(data.rows)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            style="margin-right: 20px"
            @click="deleteRule(data.rows.id)"
            >删除</el-link
          >
        </div>
      </template>
    </div-table>
    <el-dialog :title="form.id ? '编辑角色' : '新增角色'" :visible.sync="dialogFormVisible" @close="form = {}">
      <el-form :model="form"  :rules="rules"  ref="ruleForm">
        <el-form-item label="角色名称"  :label-width="formLabelWidth" prop="role">
          <el-input v-model="form.role" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单" type="number" :label-width="formLabelWidth" prop="menuId">
          <el-tree-select ref="treeSelect" v-model="form.menuId" :treeParams="treeParams" :selectParams="selectParams" :styles="{ width: '100%'}"  @searchFun="searchFun" />
        </el-form-item>
        <!-- <el-form-item label="状态" type="number" :label-width="formLabelWidth" prop="isActive">
          <el-switch v-model="form.isActive" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";
import ElTreeSelect from 'el-tree-select';

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // 保留两位小数
});

export default {
  components: {
    divTable,
    ['el-tree-select']: ElTreeSelect[0]
  },
  data() {
    return {
      formLabelWidth: '150px',
      menuData: [],
      drawer: false,
      rules: {
        role: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
      },
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,
      dialogFormVisible: false,
      form: {
        menuId: []
      },
      selectParams: {
          multiple: true,
          clearable: true,
          collapseTags: true,
      },
      subLevelList: [
        'Basic',
        'Pro',
        'Business',
        'Enterprise',
      ],

      fromList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: `角色名称`,
          key: `role`,
        },
        // {
        //   label: `状态`,
        //   key: `isActive`,
        //   transValue: (val) => {
        //     return val == 1 ? "激活" : "未激活";
        //   },
        // },
        {
          label: `创建时间`,
          key: `createTime`,
          width: 180,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
          width: 180,
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: 120,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
    this.getMenus();
  },
  computed: {
        treeParams() {
          return {
            "check-strictly": false,
            "default-expand-all": false,
            "expand-on-click-node": false,
            "clickParent": false,
            "includeHalfChecked": true,
            "leafOnly": false,
            "filterable": true,
            "data": [
              {
                "id": 0,
                "name": "根目录",
                "type": "dir",
                "parentId": 0,
                "children": this.menuData
              }
            ],
            props:  {
              "label": "name",
              "disabled": "disabled",
              "value": "id"
            }
          }
        }
  },
  watch: {},
  methods: {
    searchFun(value) {
          this.$refs.treeSelect.$refs.tree.filter(value)
        },
    async getMenus() {
          const { code, data, msg } = await this.$apis
            .get(`/admin_get_role_menu`, {
              ...this.parmes,
            })
          if (code === 0) {
            this.menuData = data
          } else {
            this.$message.error(msg)
          }
        },
    showDialog(row){
      this.dialogFormVisible = true;
      this.form = {
        ...row,
        menuId: row.menuId || []
      };
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    reSetParmes() {
      this.parmes = {
        role: "",
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis
        .get(`/admin_get_role`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
    handleSubmit(){
      this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            const ids = this.$refs.treeSelect.$refs.tree.getCheckedNodes(false, true).map(item => item.id)
            const url = this.form.id ? `/admin_mdf_role` : `/admin_add_role`
            const {code, msg} = await this.$apis.post(url, {
              ...this.form,
              menuId: ids.filter(item => item !== 0)
            })
            if(code == 0){
              this.$message.success(msg);
              this.dialogFormVisible = false;
              this.getMessage();
            } else {
              this.$message.error(msg);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    deleteRule(id){
      this.$confirm('确定删除该角色吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await this.$apis.get(`/admin_del_role`, {id})
        if(code == 0){
          this.$message.success(msg);
          this.getMessage();
        } else {
          this.$message.error(msg);
        }
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
