<template>
  <div class="home pages-store">
    <el-descriptions class="margin-top" title="系统设置" :column="4" direction="vertical" border>
      <el-descriptions-item v-for="(value, index) in window_details_keys" :key="index" :label="value.lable">
        <el-select v-if="value.type == 'select'" style="width: 100%" placeholder="扩展搜索" v-model="window_details_obj[value.key]" filterable clearable @change="
          infoEdit = false;
        value.change && value.change();
        ">
          <el-option v-for="(item, item_index) in value.selectList || []" :key="item_index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-date-picker @change="infoEdit = false" style="width: 100%" v-else-if="value.type == 'date'" v-model="window_details_obj[value.key]" type="date" placeholder="Pick a Date"
          value-format="yyyy-MM-dd hh:mm:ss" :disabled="value.disabled" />
        <div class="flex-d" v-else-if="value.type == 'list'" style="
            min-width: 400px;
            width: 100%;
            max-height: 300px;
            overflow-y: auto;
            padding-right: 10px;
          ">
          <div class="flex-start-center" style="
              position: sticky;
              top: 0;
              left: 0;
              min-width: 400px;
              width: 100%;
              z-index: 99;
              height: 30px;
              background-color: #fff;
            ">
            <el-button :disabled="window_details_obj[value.key] &&
              window_details_obj[value.key].length > 100
              " style="width: 29px; flex-shrink: 0" @click="
                window_details_obj[value.key].push(``);
              infoEdit = false;
              " type="primary" icon="el-icon-plus" circle size="mini"></el-button>

            <span style="margin-left: 10px">
              当前数量
              {{
                window_details_obj[value.key] &&
                window_details_obj[value.key].length
              }}/100</span>
          </div>
          <div class="flex-start-center" v-for="(item, item_index) in window_details_obj[value.key]" style="min-width: 400px; width: 100%; margin-top: 10px" :key="item_index">
            <el-input @input="infoEdit = false" v-model="window_details_obj[value.key][item_index]" placeholder="主机"></el-input>

            <el-button @click="
              window_details_obj[value.key].splice(item_index, 1);
            infoEdit = false;
            " style="width: 29px; flex-shrink: 0; margin-left: 10px" type="primary" icon="el-icon-delete" circle size="mini"></el-button>
          </div>
        </div>
        <el-input v-else @input="infoEdit = false" :disabled="value.disabled" v-model="window_details_obj[value.key]"></el-input>
      </el-descriptions-item>
    </el-descriptions>

    <div class="flex-end-center" style="margin-top: 15px">
      <el-button :disabled="infoEdit" icon="el-icon-edit" @click="submit()">
        修改
      </el-button>
    </div>
  </div>
</template>

<script>
import divTable from "@/views/huoshan/components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data () {
    return {
      infoEdit: true,
      window_details_obj: {},
      window_details_keys: [
        {
          lable: "浏览器窗口打开形式",
          key: "winOpenType",
          type: "select",
          selectList: [
            {
              label: `定位置打开`,
              value: `pos`,
            },
            {
              label: `最小化`,
              value: `min`,
            },
            {
              label: `最大化`,
              value: `max`,
            },
          ],
        },
        {
          lable: "指定位置X值",
          key: "OpenPositonX",
        },
        {
          lable: "指定位置Y值",
          key: "OpenPositonY",
        },
        {
          lable: "本地接口",
          key: "localPort",
        },
        {
          lable: "浏览器缓存目录",
          key: "cachePosition",
        },
        {
          lable: "自动删除此天数之前的缓存",
          key: "autoDelDays",
        },
        {
          lable: "性能监控",
          key: "isMonitor",
          type: "select",
          selectList: [
            {
              label: `不开启`,
              value: 0,
            },
            {
              label: `开启`,
              value: 1,
            },
          ],
        },
        {
          lable: "性能监控触发极限数值",
          key: "memoryLimit",
        },
        {
          lable: "关闭软件时",
          key: "closeSoft",
          type: "select",
          selectList: [
            {
              label: `弹出提示`,
              value: `pop`,
            },
            {
              label: `最小化至托盘`,
              value: `min`,
            },
            {
              label: `退出软件`,
              value: `exit`,
            },
          ],
        },
        {
          lable: "代理域名黑名单列表",
          key: "ipBlackList",
          type: "list",
        },
        {
          lable: "Api Token",
          key: "apiToken",
        },
        {
          lable: "创建时间",
          key: "createTime",
          disabled: true,
          type: "date",
        },
        {
          lable: "修改时间",
          key: "updateTime",
          disabled: true,
          type: "date",
        },
      ],
      drawer: false,
      direction: "rtl",
      loading: false,
    };
  },
  async mounted () {
    this.getMessage();
  },

  watch: {},
  methods: {
    async getMessage (boole = false) {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .get(`/admin_config_list`, {})
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        let ipBlackList =
          data.ipBlackList instanceof Array ? data.ipBlackList : [];
        this.window_details_obj = { ...data, ipBlackList };
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    async submit (boole = false) {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(`/admin_mdf_config`, this.window_details_obj)
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.$message.success(`成功`);
      } else {
        this.$modal.msgError(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
