import { getToken, setToken, removeToken } from "@/utils/auth";
import $apis from "@/api/index";
import {views} from "@/router"
import Layout from "@/layout";

const generateRoutes = (menus) => {
  return menus.reduce((acc, menu) => {
    if (menu.type === 'dir') {
      const route = {
        name: menu.name,
        path: menu.route,
         component: Layout,
         noCache: true,
          meta: {
             title: menu.name,
             icon: menu.icon,
             affix: true
            }
        }

    if (menu.children?.length > 0) {
      route.children = generateRoutes(menu.children)
    }
      acc.push(route)
    } else if (menu.type === 'page') {
      const target = views.find(v => v.fileName === `./${menu.comPath}`)
      if (target) {
        if (menu.parentId === 0) {
        const route = {
          name: menu.name,
          path: menu.route,
           component: Layout,
           noCache: true,
           redirect: { name: `${menu.name}-${menu.id}` },
            meta: {
               title: menu.name,
               icon: menu.icon,
               affix: true
              },
              children: [
                {
                  name: `${menu.name}-${menu.id}`,
                  path: '',
                  component: target.component,
                  noCache: true,
                  meta: { title: menu.name, icon: menu.icon,affix: true }
                }
              ]
          }
          acc.push(route)
      } else {
          const route = {
            name: menu.name,
            path: menu.route,
            component: target.component,
            noCache: true,
            meta: { title: menu.name, icon: menu.icon, affix: true }
          }
          acc.push(route)
        }
      }
    }


    return acc
  }, [])
}

const user = {
  state: {
    token: getToken(),
    id: "",
    name: "",
    avatar: "",
    roles: [],
    permissions: [],
    menus: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus;
    },
  },

  actions: {
    // 获取角色菜单
    GetMenus({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        const { code, data, msg } = await $apis
            .get(`/admin_get_user_role_menu`, {
              ...this.parmes,
            }).catch((error) => {
              reject(error);
            });
          if (code === 0) {
            const menus = generateRoutes(data)
            commit("SET_MENUS", menus)
            resolve(menus);
          } else {
            reject(msg)
          }

        // getInfo()
        //   .then((res) => {
        //     const user = res.user;
        //     const avatar =
        //       user.avatar == "" || user.avatar == null
        //         ? require("@/assets/images/profile.jpg")
        //         : process.env.VUE_APP_BASE_API + user.avatar;
        //     if (res.roles && res.roles.length > 0) {
        //       // 验证返回的roles是否是一个非空数组
        //       commit("SET_ROLES", res.roles);
        //       commit("SET_PERMISSIONS", res.permissions);
        //     } else {
        //       commit("SET_ROLES", ["ROLE_DEFAULT"]);
        //     }
        //     commit("SET_ID", user.userId);
        //     commit("SET_NAME", user.userName);
        //     commit("SET_AVATAR", avatar);
        //     resolve(res);
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
      });
    },
    },
};

export default user;
