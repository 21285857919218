<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    updateSize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    config: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    },
    updateSize (value) {
      if (value) {
        this.$_resizeHandler?.()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.on('click', (params) => {
        this.$emit('pie-click', params)
      })
      this.setOptions(this.config)
    },
    setOptions (config = {}) {
      this.chart.setOption(config)
    }
  }
}
</script>
