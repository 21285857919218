class tableObj {
  constructor(obj = {}) {
    obj = {
      fromList: [],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      loading: false,
      ...obj,
    };

    Object.keys(obj).forEach((key) => {
      this[key] = obj[key];
    });
  }
}
export { tableObj };
