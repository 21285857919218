var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabPanes, function (item, index) {
          return _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: !_vm.data,
                  expression: "!data",
                },
              ],
              key: index,
              staticClass: "tab-pane",
              attrs: { lazy: "", label: item.label, name: item.name },
            },
            [
              item.component
                ? [
                    _c(item.component, {
                      ref: item.name,
                      refInFor: true,
                      tag: "components",
                      attrs: { data: _vm.data, updateSize: item.updateSize },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }