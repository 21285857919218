<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input
          placeholder="所属主账号"
          v-model.trim="parmes.mainUserName"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.username"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-input
          placeholder="窗口名"
          v-model.trim="parmes.window_name"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-button
          class="h30"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="showPageList(fromList, showList)"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <!-- <template v-slot:windowSortNum="{ data }">
        {{ data.rows.windowSortNum }}
      </template> -->
    </div-table>

    <el-drawer
      title="窗口详情"
      :visible.sync="drawer"
      :before-close="handleClose"
      size="50%"
    >
      <div
        ref="drawer"
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          title="详细信息"
          direction="vertical"
          :column="windowWidth >= 1912 ? 3 : windowWidth >= 1600 ? 2 : 1"
          border
        >
          <el-descriptions-item
            v-for="(item, index) in fromList"
            :key="index"
            :label="item.label"
            :label-class-name="
              (item.label == 'cookie' || item.label == 'User Agent')
                ? 'bright'
                : 'label-color'
            "
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :content="
                item.transValue == undefined
                  ? toStringWord(windowMessage[item.key])
                  : toStringWord(item.transValue(windowMessage[item.key]))
              "
            >
              <span :style="item.trans && item.trans(windowMessage[item.key])">
                {{
                  item.transValue == undefined
                    ? windowMessage[item.key] || "-"
                    : item.transValue(windowMessage[item.key]) || "-"
                }}
              </span>
            </el-tooltip>

            <i
              v-show="!isEmpty(windowMessage[item.key])"
              class="el-icon-document-copy"
              @click="copy(windowMessage[item.key])"
            ></i>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
import { clipboardText } from '@/utils';

export default {
  components: {
    divTable,
  },
  data() {
    return {
      drawer: false,
      parmes: {},

      windowMessage: {},
      loading: false,
      fromList: [
        {
          label: `ID`,
          key: `id`,
          width: `80px`,
          slot: "id",
          click: (value) => {
            this.drawer = true;
            this.windowMessage = value;
          },
          trans: (value) => {
            return `color: blue;font-weight: bold;cursor: pointer;`;
          },
        },
        {
          label: `用户名`,
          key: `userName`,
          slot: "copy",
        },
        {
          label: `所属主账号`,
          key: `mainUserName`,
          slot: "copy",
        },
        {
          label: `创建类型`,
          key: `createType`,
          transValue: (value) => value === 'single' ? '单个创建' : '批量创建'
        },
        {
          label: `窗口目录`,
          key: `dirId`,
          slot: "copy",
          minWidth:'100px'
        },
        {
          label: `窗口序号`,
          key: `windowSortNum`,
        },
        {
          label: `窗口名称`,
          key: `windowName`,
          slot: "copy",
        },
        {
          label: `标题栏窗口名显示开关`,
          key: `isDisplayName`,
          transValue: (value) => value === 1 ? '显示' : '不显示'
        },
        {
          label: `分组名称`,
          key: `groupName`,
          slot: "copy",
        },
        {
          label: `业务平台URL`,
          key: `platformUrl`,
          slot: "copy",
        },
        {
          label: `平台账号`,
          key: `platformUserName`,
          slot: "copy",
        },
        {
          label: `平台密码`,
          key: `platformPassword`,
          slot: "copy",
        },
        {
          label: `打开指定网址`,
          key: `defaultOpenUrl`,
          slot: "copy",
          transValue: (row) => {
            return row.length > 0 ? row.join(",") : "-";
          },
        },
        {
          label: `是否在回收站 `,
          key: `isDelete`,
          trans: (val) => {
            return val ? "color:red;" : "";
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `操作系统`,
          key: `os`,
        },
        {
          label: `操作系统版本`,
          key: `osVersion`,
        },
        {
          label: `浏览器版本`,
          key: `browserVersion`,
          slot: "copy",
        },
        {
          label: `User Agent`,
          key: `userAgent`,
          slot: "copy",
        },
        {
          label: `cookie`,
          key: `cookie`,
          slot: "copy",
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `窗口备注`,
          key: `windowRemark`,
          slot: "copy",
        },
        {
          label: `是否常用`,
          key: `isOften`,
          trans: (val) => {
            return val ? "color:#2692ff;" : "color: red;";
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `是否已打开`,
          key: `openStatus`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `窗口打开状态信息`,
          key: `statusInfo`,
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `标签id`,
          key: `labelId`,
          transValue: (row) => {
            return row + "" || "-";
          },
        },
        {
          label: `标签颜色`,
          key: `labelColor`,
        },
        {
          label: `标签名称`,
          key: `labelName`,
        },
        {
          label: `登录信息`,
          key: `loginData`,
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `指纹登录信息`,
          key: `lumiLogin`,
        },
        {
          label: `窗口创建时间`,
          key: `createTime`,
        },
        {
          label: `窗口修改时间`,
          key: `updateTime`,
        },
        {
          label: `用户姓名`,
          key: `realName`,
        },
        {
          label: `角色`,
          key: `role`,
          transValue: (row) => {
            if (row == "main") {
              return "主账号";
            } else if (row == "admin") {
              return "管理员";
            } else if (row == "manager") {
              return "经理";
            } else if (row == "staff") {
              return "成员";
            }
          },
        },
        {
          label: `代理IP的id`,
          key: `moduleId`,
          transValue: (row) => {
            return row + "" || "-";
          },
        },
        {
          label: `代理方式`,
          key: `proxyMethod`,
          transValue: (row) => {
            return this.getSystemDict("proxyMethod", row);
          },
        },
        {
          label: `代理类别`,
          key: `proxyCategory`,
        },
        {
          label: `代理名称`,
          key: `moduleName`,
        },
        {
          label: `代理服务商`,
          key: `proxyProvider`,
        },
        {
          label: `国家`,
          key: `country`,
          transValue: (row) => {
            return this.getSystemDict("country", row);
          },
        },
        {
          label: `州`,
          key: `state`,
        },
        {
          label: `城市`,
          key: `city`,
        },
        {
          label: `网络协议`,
          key: `ipType`,
        },
        {
          label: `代理协议`,
          key: `protocol`,
        },
        {
          label: `代理主机`,
          key: `host`,
        },
        {
          label: `代理端口`,
          key: `port`,
        },
        {
          label: `代理账号`,
          key: `proxyUserName`,
        },
        {
          label: `代理密码`,
          key: `proxyPassword`,
        },
        {
          label: `ip时长(分钟)`,
          key: `proxyTime`,
        },
        {
          label: `刷新URL`,
          key: `refreshUrl`,
        },
        {
          label: `查询渠道`,
          key: `checkChannel`,
        },
        {
          label: `提取链接的服务商`,
          key: `extraChannel`,
        },
        {
          label: `每次打开都提取新IP`,
          key: `isExtraNewIp`,
          trans: (val) => {
            return !val.isExtraNewIp ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "否" : "是";
          },
        },
        {
          label: `提取IP校验重复`,
          key: `isRepectCheck`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "校验" : "不校验";
          },
        },
        {
          label: `提取IP链接`,
          key: `extraUrl`,
        },
        {
          label: `出口IP`,
          key: `lastIp`,
        },
        {
          label: `出口国家`,
          key: `lastCountry`,
        },
        {
          label: `语言`,
          key: `isLanguageBaseIp`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "跟随IP匹配" : "自定义";
          },
        },
        {
          label: `自定义语言`,
          key: `language`,
        },
        {
          label: `界面语言`,
          key: `isDisplayLanguageBaseIp`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "自定义" : "跟随IP匹配";
          },
        },
        {
          label: `自定义界面语言`,
          key: `displayLanguage`,
        },
        {
          label: `时区`,
          key: `isTimeZone`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "自定义" : "跟随IP匹配";
          },
        },
        {
          label: `自定义时区`,
          key: `timeZone`,
        },
        {
          label: `地理位置`,
          key: `position`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            if (row == 1) return "允许";
            else if (row == 2) return "禁止";
            else return "询问";
          },
        },
        {
          label: `基于IP生成对应的地理位置`,
          key: `isPositionBaseIp`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "自定义" : "跟随IP匹配";
          },
        },
        {
          label: `经度`,
          key: `longitude`,
        },
        {
          label: `纬度`,
          key: `latitude`,
        },
        {
          label: `精度米`,
          key: `precisionPos`,
        },
        {
          label: `声音`,
          key: `forbidAudio`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `图片`,
          key: `forbidImage`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "禁止加载" : "允许加载";
          },
        },
        {
          label: `自定义加载图片尺寸`,
          key: `forbiddenPictureSize`,
        },
        {
          label: `视频`,
          key: `forbidMedia`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "禁止加载" : "允许加载";
          },
        },
        {
          label: `同步标签页`,
          key: `syncTab`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `tab密钥`,
          key: "localState",
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return Object.keys(row).length != 0 ? row : "-";
          },
        },
        {
          label: `同步Cookie`,
          key: `syncCookie`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `多开设置`,
          key: `openMult`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "开启" : "关闭";
          },
        },
        {
          label: `弹出保存密码`,
          key: `forbidSavePassword`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "否" : "是";
          },
        },
        {
          label: `网络不通停止打开`,
          key: `stopOpenNet`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "否" : "是";
          },
        },
        {
          label: `IP发生变化停止打开`,
          key: `stopOpenIP`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `IP对应国家/地区发生变化停止打开`,
          key: `stopOpenPosition`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `同步IndexedDB`,
          key: `syncIndexedDb`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `同步Local Storage`,
          key: `syncLocalStorage`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `同步书签`,
          key: `syncBookmark`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `同步已保存的用户名密码`,
          key: `syncPassword`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "否" : "是";
          },
        },
        {
          label: `同步历史记录`,
          key: `syncHistory`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `同步扩展应用数据`,
          key: `syncExtensions`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `同步md5`,
          key: `syncValue`,
          transValue: (row) => {
            return Object.keys(row).length != 0 ? row : "-";
          },
        },
        {
          label: `启动浏览器前删除缓存文件`,
          key: `clearCacheFile`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `启动浏览器前删除Cookie`,
          key: `clearCookie`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `启动浏览器前删除历史记录`,
          key: `clearHistory`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `启动浏览时随机指纹`,
          key: `randomFingerprint`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        // {
        //   label: `允许登录Chrome`,
        //   key: `allowLoginChrome`,
        //   trans: (val) => {
        //     return val ? `color:#2692ff;` : `color: red;`;
        //   },
        //   transValue: (row) => {
        //     return row ? "是" : "否";
        //   },
        // },
        {
          label: `关闭浏览器后继续运行应用`,
          key: `allowRunApplication`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: `使用硬件加速模式`,
          key: `useGpu`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "否" : "是";
          },
        },
        {
          label: `webRtc`,
          key: `webrtc`,
          transValue: (row) => {
            if (row == 1) return "真实";
            else if (row == 2) return "禁用";
            else return "替换";
          },
        },
        {
          label: `忽略https证书错误`,
          key: `ignoreHttpsError`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "否" : "是";
          },
        },
        {
          label: `窗口尺寸，宽度`,
          key: `openWidth`,
        },
        {
          label: `窗口尺寸，高度`,
          key: `openHeight`,
        },
        {
          label: `分辨率`,
          key: `resolutionType`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "自定义" : "跟随电脑";
          },
        },
        {
          label: `分辨率X值`,
          key: `resolutionX`,
        },
        {
          label: `分辨率Y值`,
          key: `resolutionY`,
        },
        {
          label: `字体`,
          key: `fontType`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "默认" : "随机";
          },
        },
        {
          label: `字体值`,
          key: `font`,
        },
        {
          label: `canvas`,
          key: `canvas`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "随机";
          },
        },
        {
          label: `canvas值`,
          key: `canvasValue`,
        },
        {
          label: `webGL图像`,
          key: `webGL`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "随机";
          },
        },
        {
          label: `webGLInfo开关`,
          key: `webGL`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (value) => value === 1 ? '打开' : '关闭',
        },
        {
          label: `webGL值`,
          key: `webGLValue`,
        },
        {
          label: `webgl元数据`,
          key: `webGLMeta`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "自定义";
          },
        },
        {
          label: `webGL厂商值`,
          key: `webGLManufacturer`,
        },
        {
          label: `webGL渲染值`,
          key: `webGLRender`,
        },
        {
          label: `webGpu`,
          key: `webGpu`,
          transValue: (row) => {
            if (row == "real") return "真实";
            else if (row == "block") return "禁止";
            else return "基于webgl匹配";
          },
        },
        {
          label: `audioContext`,
          key: `audioContext`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "随机";
          },
        },
        {
          label: `audioContext噪音值`,
          key: `audioContextValue`,
        },
        {
          label: `Speech Voices`,
          key: `speechVoices`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "随机";
          },
        },
        {
          label: `Speech Voices值`,
          key: `speechVoicesValue`,
        },
        {
          label: `doNotTrack`,
          key: `doNotTrack`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `ClientRects`,
          key: `clientRects`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "随机";
          },
        },
        {
          label: `ClientRects值`,
          key: `clientRectsValue`,
        },
        {
          label: `批量导入`,
          key: `import`,
        },
        {
          label: `设备信息`,
          key: `deviceInfo`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "自定义";
          },
        },
        {
          label: `设备名称`,
          key: `deviceName`,
        },
        {
          label: `设备名称开关`,
          key: `deviceNameSwitch`,
          transValue: (value) => value === 1 ? '打开' : '关闭',
        },
        {
          label: `webGLInfo开关`,
          key: `webGL`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (value) => value === 1 ? '打开' : '关闭',
        },
        {
          label: `mac地址`,
          key: `macAddr`,
        },
        {
          label: `端口扫描保护`,
          key: `portScanProtect`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `端口扫描保护开启时的白名单`,
          key: `portScanList`,
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `ssl指纹设置`,
          key: `disableSsl`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `序列化的ssl特性值`,
          key: `disableSslList`,
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `自定义插件指纹`,
          key: `enablePlugin`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return !row ? "关闭" : "开启";
          },
        },
        {
          label: `序列化的插件值`,
          key: `enablePluginList`,
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `媒体设备`,
          key: `mediaDevice`,
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
          transValue: (row) => {
            return row ? "开启" : "关闭";
          },
        },
        {
          label: `媒体设备列表`,
          key: `mediaDeviceList`,
          transValue: (row) => {
            return row.length > 0 ? row : "-";
          },
        },
        {
          label: `硬件并发数`,
          key: `hardwareConcurrent`,
        },
        {
          label: `设备内存`,
          key: `deviceMemory`,
        },
        {
          label: `启用的插件`,
          key: `usePlugin`,
        },
        {
          label: `user agent`,
          key: `userAgentNew`,
        },
        {
          label: `user agent版本号`,
          key: `userAgentVersion`,
        },
        {
          label: `平台`,
          key: `platform`,
        },
        {
          label: `导航平台`,
          key: `navigatorPlatform`,
        },
        {
          label: `像素`,
          key: `pixelDepth`,
        },
        {
          label: `颜色`,
          key: `colorDepth`,
        },
        {
          label: `是否禁用沙盒`,
          key: `sandboxPermission`,
          transValue: (value) => {
            return value ? '是' : '否'
          },
        },
        {
          label: `启动参数`,
          key: `startupParam`,
        },
        {
          label: `上报时间`,
          key: `reportTime`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,

      show: false,
      from: {},
      windowWidth: document.body.clientWidth,
      showList: [
        "id",
        "userName",
        "mainUserName",
        "createType",
        "dirId",
        "windowSortNum",
        "windowName",
        "platformUrl",
        "defaultOpenUrl",
        "isDelete"
      ],
    };
  },
  created() {
    this.$dicts.getSystemDict();
  },
  async mounted() {
    this.getMessage();
    this.reSetParmes();
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth; // 宽
      })();
    };
  },
  watch: {},
  methods: {
    // 为空方法
    isEmpty(target) {
      const res = Object.prototype.toString.call(target);
      if (res === "[object Object]") {
        return !(Object.keys(target).length > 0);
      }
      if (res === "[object Array]" || res === "[object String]") {
        return !(target.length > 0);
      }
    },
    showPageList(arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    getSystemDict(type, row) {
      if (type !== "" && row !== "") {
        let arr = JSON.parse(window.localStorage.getItem(`dicts`));
        let str = arr[type].find((x) => x.value == row).label;
        return str;
      } else {
        return row;
      }
    },
    copy(val) {
      clipboardText(val)
    },
    toStringWord(val) {
      return JSON.stringify(val) || "-";
    },
    changeValue(value) {
      console.log(Object.prototype.toString.call(value) == "[object Boolean]");

      if (value instanceof Array && !(value[0] instanceof Object)) {
        return value.join(",");
      } else if (Object.prototype.toString.call(value) == "[object Boolean]") {
        return value ? "是" : "否";
      } else {
        return value || "-";
      }
    },
    reSetParmes() {
      this.parmes = {
        username: "",
        window_name: "",
      };
    },
    handleClose(done) {
      return done();
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_window_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    startResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          console.log(
            `Size changed. New width: ${width}, New height: ${height}`
          );
          // 处理宽度和高度变化的逻辑
        }
      });
      this.resizeObserver.observe(this.$refs.drawer);
    },
    stopResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

::v-deep .el-descriptions--medium.is-bordered .el-descriptions-item__cell {
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 300px;
  min-height: 43px;
  padding-right: 20px;

  i {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-8px);
    cursor: pointer;
  }
}

.h30 {
  height: 30px;
}

::v-deep
  .el-descriptions--medium.is-bordered
  .el-descriptions-item__cell.bright {
  color: white;
  background-color: #cb7b2d;
}

:deep(.label-color) {
  color: #000000!important;
  font-weight: bold!important;
}
</style>
