<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <el-input
        clearable
        placeholder="用户名搜索"
        v-model.trim="parmes.username"
        style="width: 180px"
        @keydown.enter.native="getMessage()"
      ></el-input>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="handleSizeChange"
      @get-message="handleCurrentChange"
      @up-page-value="pageValue = $event"
    >
    </div-table>
    <el-drawer
      title="窗口详情"
      :visible.sync="drawer"
      :direction="direction"
      size="50%"
    >
      <div
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          class="margin-top"
          title="详细信息"
          :column="4"
          direction="vertical"
          border
        >
          <el-descriptions-item
            v-for="(key, index) in window_details_keys"
            :key="index"
            :label="key"
          >
            {{ window_details_obj[key] }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data() {
    return {
      window_details_obj: {},
      window_details_keys: [],
      drawer: false,
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,

      fromList: [
        {
          label: `分组名称`,
          key: `name`,
        },
        {
          label: `分组排序`,
          key: `sortNum`,
        },
        {
          label: `归属用户`,
          key: `userName`,
        },
        {
          label: `备注`,
          key: `remark`,
        },
        {
          label: `创建时间`,
          key: `createTime`,
        },
        {
          label: `修改时间`,
          key: `updateTime`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    reSetParmes() {
      this.parmes = {
        username: "",
      };
    },
    // 获取窗口详情
    async getWindowsMessage(id) {
      const { code, data, msg } = await this.$apis.get(`/admin_window_by_id`, {
        id: id,
      });

      if (code == 0) {
        return {
          info: true,
          data,
        };
      }
      return {
        info: false,
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
      } = await this.$apis.get(`/admin_group`, {
        page_index: this.pageValue,
        page_size: this.pageSize,
        ...this.parmes,
      });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
