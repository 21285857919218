import OSS from 'ali-oss';
import { ossConfig } from "@/constants";

export default {
  data () {
    return {
      ossClient: null,
      ossConfig: null
    }
  },
  mounted () {
    this.initOSS();
  },
  methods: {
    async initOSS () {
      const { data, code } = await this.$apis.get("/admin_get_oss_credential")
      if (code == 0) {
        this.ossConfig = { ...ossConfig, ...data };
        const { region, accessKeyId, accessKeySecret, bucket, securityToken } = this.ossConfig;
        this.ossClient = new OSS({
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          stsToken: securityToken,
        })
      }
    }

  }
}