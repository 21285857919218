<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <el-input placeholder="用户名" v-model.trim="parmes.userName" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="批次号" v-model.trim="parmes.batchNo" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="getMessage(true)">查询</el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="reSetParmes">重置</el-button>
      </div>
    </div>

    <div-table :loading="loading" :fromList="fromList" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event" @get-message="getMessage($event)">
    </div-table>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
const colums = [
  {
    key: "userId",
    label: "用户ID",
    slot: "copy",
  },
  {
    key: "userName",
    label: "用户名",
    slot: "copy",
    minWidth: "180px",
  },
  {
    key: "batchNo",
    label: "批次号",
    slot: "copy",
    minWidth: "180px",
  },
  {
    key: "templateType",
    label: "模板类型",
  },
  {
    key: "fileName",
    label: "文件名称",
    minWidth: "180px",
  },
  {
    key: "count",
    label: "导入数量",
  },
  {
    key: "status",
    label: "状态",
    transValue: (row) => {
        return row == 1 ? "已完成" : "执行中";
          },
  },
  {
    key: 'dataType',
    label: '数据类型',
  },
  {
    key: 'dataDetail',
    label: '数据详情',
    slot: "copy",
    showTooltip: false,
    minWidth: "150px",
  },
  {
    key: "rstMsg",
    label: "返回信息",
  },
  {
    key: "createTime",
    label: "创建时间",
    minWidth: "170px",
  },
]
export default {
  components: {
    divTable,
  },
  data () {
    return {
      parmes: {},
      loading: false,
      fromList: colums,
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0
    };
  },
  created () { },
  mounted () {
    this.getMessage();
  },

  watch: {},
  methods: {
    reSetParmes () {
      this.parmes = {};
      this.getMessage(true);
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_user_import_window`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total || 0;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.operate {
  justify-content: space-around;
}

.h30 {
  height: 30px;
}

.avatar {
  width: 36px;
}
</style>
