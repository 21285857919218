<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.userName"
           style="width: 180px; margin-right: 15px"
           clearable
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-select v-model="parmes.withdrawType" placeholder="提现类型" clearable @change="getMessage(true)">
          <el-option v-for="item in withdrawTypeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="flex-start-center" style="margin-left: 20px">
        <el-button  type="success"
          size="mini"
          icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
        type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
      </div>


    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    </div-table>
    <el-drawer
      title="窗口详情"
      :visible.sync="drawer"
      :direction="direction"
      size="50%"
    >
      <div
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          class="margin-top"
          title="详细信息"
          :column="4"
          direction="vertical"
          border
        >
          <el-descriptions-item
            v-for="(key, index) in window_details_keys"
            :key="index"
            :label="key"
          >
            {{ window_details_obj[key] }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // 保留两位小数
});

const withdrawStatusMap = {
  Success: "提现成功",
  Pending: "审核中",
  Fail: "提现失败",
};

export default {
  components: {
    divTable,
  },
  data() {
    return {
      window_details_obj: {},
      window_details_keys: [],
      drawer: false,
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,
      windowsMessage: {},
      withdrawTypeList: [
        {
          value: "Balance",
          label: "Roxy 钱包",
        },
        {
          value: "USDT",
          label: "USDT",
        },
      ],

      fromList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: `用户名`,
          key: `userName`,
          width: 200,
        },
        {
          label: `提现类型`,
          key: `withdrawType`,
          transValue: (val) => {
            return {
              Balance: "Roxy 钱包",
              USDT: "USDT",
            }[val]
          },
        },
        {
          label: `提现地址`,
          key: `withdrawAdd`,
          slot: "copy",
        },
        {
          label: `提现状态`,
          key: `withdrawStatus`,
          transValue: (val) => {
            return withdrawStatusMap[val] || val;
          },
        },
        {
          label: `金额`,
          key: `withdrawAmount`,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `备注`,
          key: `remark`,
        },
        {
          label: `创建时间`,
          key: `createTime`,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    reSetParmes() {
      this.parmes = {
        userName: "",
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis
        .get(`/admin_get_withdraw_record`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
