<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.userName"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-input
          placeholder="项目名"
          v-model.trim="parmes.projectName"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-input
          placeholder="空间名"
          v-model.trim="parmes.workspaceName"
          style="width: 180px;"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">

      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link
            :underline="false"
            type="warning"
            @click="changeList(data.rows, 'edit')"
            >编辑</el-link
          >
          <el-link
            :underline="false"
            type="danger"
            @click="changeList(data.rows, 'del')"
            >删除</el-link
          >
        </div>
      </template>
      <template #user_details="{data}">
        <el-popover
          placement="right"
          trigger="hover">
          <el-table :data="data.rows.user_details">
            <el-table-column width="150" property="userName" label="参与者用户名"></el-table-column>
            <el-table-column width="100" property="role" label="参与者角色">
              <template slot-scope="scope">
                {{ roleMap[scope.row.role] }}
              </template>
            </el-table-column>
            <el-table-column width="100" property="status" label="激活状态">
              <template slot-scope="scope">
                {{ scope.row.status ? '激活' : '未激活' }}
              </template>
            </el-table-column>
            <el-table-column width="120" property="expireTime" label="邀请过期时间"></el-table-column>
          </el-table>
          <span slot="reference">
            {{ data.rows.user_details.map((x) => x.userName).join(",") }}
          </span>
        </el-popover>

      </template>
    </div-table>

    <el-dialog
      :title="from_type == 'add' ? '新增' : '编辑'"
      :visible.sync="dialogShow"
      width="30%"
    >
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item
          v-for="(value, index) in showPageList(
            dialogList,
            from_type == 'add' ? addList : editList
          )"
          :key="index"
          :label="value.label"
        >
          <el-select
            clearable
            v-if="value.type == 'select'"
            v-model="dialogFrom[value.key]"
            @change="changeType($event)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <template v-else-if="value.type == 'radio'">
            <el-radio v-model="dialogFrom[value.key]" :label="true">有效</el-radio>
            <el-radio v-model="dialogFrom[value.key]" :label="false">无效</el-radio>
          </template>
          <el-input
            v-else
            style="margin-bottom: 10px"
            v-model="dialogFrom[value.key]"
            placeholder="请输入"
            :disabled="value.disabled || false"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>

    <el-drawer
      :visible.sync="drawer"
      size="50%"
    >
      <div
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          title="详细信息"
          direction="vertical"
          border
          style="margin-bottom: 10px"
          :column="5"
        >
          <el-descriptions-item
            v-for="(item, index) in fromList"
            :key="index"
            :label="item.label"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :content="
                item.transValue == undefined
                  ? JSON.stringify(selectRow[item.key]) || `-`
                  : JSON.stringify(
                      item.transValue(selectRow[item.key], selectRow)
                    )
              "
            >
              <span>{{
                item.transValue == undefined
                  ? selectRow[item.key] || "-"
                  : item.transValue(selectRow[item.key], selectRow)
              }}</span>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
        <h1>
          项目成员
        </h1>
        <el-table
          :data="selectRow.user_details"
          style="width: 100%">
          <el-table-column
            prop="userName"
            label="用户名">
          </el-table-column>
          <el-table-column
            prop="role"
            label="角色">
            <template slot-scope="scope">
              {{ roleMap[scope.row.role] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态">
            <template slot-scope="scope">
              {{ scope.row.status ? '已加入' : '待加入' }}
            </template>
          </el-table-column>
        </el-table>
        <h1>
          插件列表
        </h1>
        <el-table
          :data="selectRow.pluginList"
          style="width: 100%">
          <el-table-column
            prop="pluginName"
            label="插件名">
          </el-table-column>
          <el-table-column
            prop="pluginDir"
            label="插件 ID">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import divTable from "./components/div-table.vue";

const roleMap = {
  0: '查看者',
   1: '编辑者',
    2: '管理者',
    3: '所有者',
}
export default {
  components: {
    divTable,
  },
  data() {
    return {
      roleMap,
      drawer: false,
      uploadUrl: "",
      parmes: {},
      selectRow: {},
      loading: false,
      fromList: [
        {
          label: "ID",
          key: "project_id",
          disabled: true,
          slot: "id",
          trans: (value) => {
              return `color: blue;font-weight: bold;cursor: pointer;`;
          },
          click: async (value) => {
              this.drawer = true;
              this.selectRow = value;
          },
        },
        {
          label: "用户名",
          key: "projectUserName",
          minWidth: "150px",
        },
        {
          label: "项目名称",
          key: "projectName",
          minWidth: "150px",
        },
        {
          label: "空间名",
          key: "workspaceName",
          minWidth: "150px",
        },
        {
          label: "是否默认项目",
          key: "projectDefault",
          transValue: (row) => {
            return row ? "是" : "否";
          },
        },
        {
          label: "项目状态",
          key: "projectStatus",
          transValue: (row) => {
            return row ? "启用" : "停用";
          },
        },
        {
          label: "备注",
          key: "projectRemarks",
        },
        {
          label: "创建时间",
          key: "createTime",
        },
        // {
        //   label: `操作`,
        //   key: `operate`,
        //   slot: `operate`,
        //   width: "120px",
        // },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "类型",
          key: "type",
          type: "select",
        },
        {
          label: "编码",
          key: "label",
        },
        {
          label: "名称",
          key: "sysValue",
        },
        {
          label: "排序",
          key: "sort",
        },
        {
          label: "是否有效",
          key: "flag",
          type: "radio",
        },
        {
          label: "版本时间戳",
          key: "version",
          disabled: true,
        },
      ],
      addList: ["type", "typeName", "label", "sysValue", "sort"],
      editList: [
        "id",
        "type",
        "typeName",
        "sysValue",
        "label",
        "sort",
        "flag",
        "version",
      ],
      dialogFrom: {},
      options: [],
    };
  },
  created() {},
  async mounted() {
    this.getMessage();
    this.initSelectList();
  },

  watch: {},
  methods: {
    changeType(val) {
      console.log("🚀 ~ changeType ~ val:", val);
      this.dialogFrom.typeName = this.options.find((x) => x.value == val).label;
    },
    showPageList(arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    async submit() {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add" ? "/admin_add_dict" : "/admin_mdf_dict"}`,
          this.from_type == "add"
            ? { ...this.dialogFrom }
            : {
                id: this.dialogFrom.id,
                type: this.dialogFrom.type,
                typeName: this.dialogFrom.typeName,
                label: this.dialogFrom.label,
                sysValue: this.dialogFrom.sysValue,
                sort: this.dialogFrom.sort,
                flag: this.dialogFrom.flag,
              }
        )
        .catch((err) => ({
          code: 1,
        }))
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.dialogShow = false;
        this.$message.success("操作成功");
        // this.reSetParmes();
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    delete(row, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code, data, msg } = await this.$apis
            .get("/admin_delete_dict", {
              id: row.id,
            })
            .catch((e) => ({
              code: 1,
            }))
            .finally(() => {
              this.loading = false;
            });
          if (code == 0) {
            this.$message.success("操作成功");
            this.getMessage();
          } else {
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async changeList(row, type) {
      if (type == `edit`) {
        this.from_type = "edit";
        this.dialogShow = true;
        this.dialogFrom = Object.assign({}, row);
      } else if (type == "del") {
        this.delete(row, type);
      }
    },
    async initSelectList() {
      const { data, code, msg } = await this.$apis.get(`/admin_dict_type`);
      if (code == 0) {
        this.options = data.map((elem) => ({
          label: elem.typeName,
          value: elem.type,
        }));
      }
    },
    reSetParmes() {
      this.parmes = {};
    },
    fromInit(parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = { flag: true };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_project_info_by_workspace`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        // this.fromData = rows.filter((x) => x.status == 1);
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
.operate {
  justify-content: space-around;
}
.h30 {
  height: 30px;
}
.avatar {
  width: 36px;
}
</style>
