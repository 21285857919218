import { render, staticRenderFns } from "./div-table-detail.vue?vue&type=template&id=d3957160&scoped=true"
import script from "./div-table-detail.vue?vue&type=script&lang=js"
export * from "./div-table-detail.vue?vue&type=script&lang=js"
import style0 from "./div-table-detail.vue?vue&type=style&index=0&id=d3957160&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3957160",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\fb_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d3957160')) {
      api.createRecord('d3957160', component.options)
    } else {
      api.reload('d3957160', component.options)
    }
    module.hot.accept("./div-table-detail.vue?vue&type=template&id=d3957160&scoped=true", function () {
      api.rerender('d3957160', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/huoshan/components/div-table-detail.vue"
export default component.exports