<template>
  <el-dialog :title="title" width="80%" :visible.sync="_visible" :close-on-click-modal="false" @close="close">
    <div class="flex-start-center">
      <el-input style="width: 180px" placeholder="用户名" v-model.trim="parmes.userName"
        @keydown.enter.native="getMessage(true)"></el-input>
      <el-button style="margin-left: 15px" type="success" size="mini" icon="el-icon-search"
        @click="getMessage(true)">查询</el-button>
      <el-button type="warning" size="mini" icon="el-icon-refresh" @click="reSetParams(true)">重置</el-button>
    </div>
    <div-table H="550px" :loading="list.lenght > 0" :fromList="columns" :fromData="list" :pageSize="pageSize"
      :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event" @get-message="getMessage($event)">
    </div-table>
  </el-dialog>
</template>

<script>
import divTable from "@/views/huoshan/components/div-table.vue";

export default {
  name: "chartList",
  components: { divTable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题"
    },
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => ({ rows: [], total: 0 })
    }
  },
  computed: {
    _visible: {
      get () {
        return this.visible
      },
      set (value) {
        this.$emit("update:visible", value)
      }
    },
    list ({ data }) {
      return data?.rows || []
    },
    total ({ data }) {
      return data?.total || 0
    },
  },
  data () {
    return {
      parmes: {},
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
    }
  },
  methods: {
    reSetParams (isReload = false) {
      this.parmes = {}
      if (isReload) {
        this.getMessage(true)
      }
    },
    getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.$emit("get-message", {
        page_index: this.pageValue,
        page_size: this.pageSize,
        ...this.parmes,
      })
    },
    close () {
      this.reSetParams()
    }
  }
}
</script>

<style lang="scss" scoped></style>