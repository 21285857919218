<template>
  <div class="home pages-store">
    <div class="flex-start-center" style="height: 50px; width: 100%">
      <el-input
        placeholder="用户名"
        v-model.trim="parmes.username"
        style="width: 180px"
        @keydown.enter.native="getMessage()"
      ></el-input>
      <el-button
        class="h30"
        style="margin-left: 15px"
        type="success"
        size="mini"
        icon="el-icon-search"
        @click="getMessage(true)"
      >
        查询
      </el-button>
      <el-button
        class="h30"
        type="warning"
        size="mini"
        icon="el-icon-refresh"
        @click="
          reSetParmes();
          getMessage(true);
        "
      >
        重置
      </el-button>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="handleSizeChange"
      @get-message="handleCurrentChange"
      @up-page-value="pageValue = $event"
    >
    </div-table>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data() {
    return {
      parmes: {},
      loading: false,
      fromList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: `用户名`,
          key: `userName`,
        },

        {
          label: `分组名称`,
          key: `name`,
        },
        {
          label: `创建时间`,
          key: `createTime`,
        },
        {
          label: `修改时间`,
          key: `updateTime`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    reSetParmes() {
      this.parmes = {
        username: "",
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_group`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.getMessage(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
.h30 {
  height: 30px;
}
</style>
