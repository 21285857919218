<template>
  <div class="home pages-store">
    <div
      class="flex-sw-center"
      style="height: 50px; width: 100%; margin-bottom: 10px"
    >
      <div class="flex-start-center">
        <el-select
          placeholder="代理网络"
          v-model="parmes.proxyNetwork"
          filterable
          clearable
          @change="getMessage(true)"
        >
          <el-option
            v-for="(item, index) in selectList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-left: 10px"
          placeholder="定制类型"
          v-model="parmes.isCustom"
          filterable
          clearable
          @change="getMessage(true)"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="
            fromInit();
            from_type = `add`;
            dialog = true;
          "
        >
          新增
        </el-button>
      </div>
    </div>

    <el-tabs v-model="level" type="card" @tab-click="handleClick">
      <el-tab-pane label="普通" name="1"></el-tab-pane>
      <el-tab-pane label="企业" name="2"></el-tab-pane>
    </el-tabs>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      :infoPage="false"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <template v-slot:rowWrap="{ data }">
        <p class="rowWrap">
          {{ `${data.rows.createTime} / ${data.rows.updateTime}` }}
        </p>
      </template>
      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link
            :underline="false"
            type="warning"
            @click="changeList(data.rows, 'edit')"
            >编辑</el-link
          >

          <!-- <el-link
            :underline="false"
            type="danger"
            @click="changeList(data.rows, 'del')"
            >删除</el-link
          > -->
        </div>
      </template>
    </div-table>

    <el-dialog
      :title="from_type == 'add' ? '新增' : '编辑'"
      :visible.sync="dialog"
      width="45%"
    >
      <el-form lable-postition="left" ref="from" label-width="100px">
        <el-form-item
          v-for="(value, index) in showPageList(
            dialogList,
            from_type == 'add' ? addList : editList
          )"
          :key="index"
          :label="value.label"
        >
          <div class="flex-d" v-if="value.type == 'list'">
            <div class="flex-start-center">
              <el-button
                :disabled="from[value.key] && from[value.key].length > 100"
                style="width: 29px; flex-shrink: 0"
                @click="
                  value.key == 'detail'
                    ? from[value.key].push({
                        price: '',
                        amount: '',
                        oriAmount: '',
                        days: '',
                      })
                    : from[value.key].push('')
                "
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
              ></el-button>
            </div>
            <div
              class="flex-start-center"
              v-for="(item, item_index) in from[value.key]"
              style="margin-top: 10px;align-items: flex-end;"
              :key="item_index"
            >
              <div v-if="value.key == 'remark'" style="width: 100%">
                <el-input
                  type="textarea"
                  :rows="1"
                  v-model="from[value.key][item_index]"
                  placeholder="请输入包裹介绍"
                ></el-input>
              </div>
              <div v-else class="packages flex-start-center">
                <div style="margin-left: 10px; width: 18%">
                  <div v-if="item_index==0">时长：</div>
                  <el-input v-model="item.days" placeholder="有效时长">
                    <template slot="append">天</template>
                  </el-input>
                </div>
                <div style="margin-left: 10px; width: 18%">
                  <div v-if="item_index==0">单价：</div>
                  <el-input v-model="item.price" placeholder="单价"
                    ><template slot="append">$</template></el-input
                  >
                </div>
                <div style="margin-left: 10px; width: 18%">
                  <div v-if="item_index==0">总价：</div>
                  <el-input v-model="item.amount" placeholder="总价"
                    ><template slot="append">$</template></el-input
                  >
                </div>
                <div style="margin-left: 10px; width: 18%">
                  <div v-if="item_index==0">原总价：</div>
                  <el-input v-model="item.oriAmount" placeholder="原总价"
                    ><template slot="append">$</template></el-input
                  >
                </div>
                <div style="margin-left: 10px; width: 18%">
                  <div v-if="item_index==0">折扣：</div>
                  <el-input v-model="item.discount" placeholder="折扣"
                    ><template slot="append">%</template></el-input
                  >
                </div>
              </div>

              <el-button
                v-show="from[value.key].length > 1"
                @click="from[value.key].splice(item_index, 1)"
                style="width: 29px; flex-shrink: 0; margin-bottom: 3px;margin-left: 10px;"
                type="primary"
                icon="el-icon-delete"
                circle
                size="mini"
              ></el-button>
            </div>
          </div>
          <div v-else-if="value.type == 'select'">
            <el-select
              placeholder="请选择"
              v-model="from[value.key]"
              clearable
              :disabled="from_type != 'add' ? value.disabled : false"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div v-else-if="value.type == 'radio'">
            <el-radio-group
              v-model="from[value.key]"
              :disabled="from_type != 'add' ? value.disabled : false"
            >
              <el-radio
                v-for="(item, index) in value.radioGroup"
                :key="index"
                :label="item.value"
                :disabled="from_type != 'add' ? value.disabled : false"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
          <el-input
            v-else-if="value.type == 'textarea'"
            type="textarea"
            :rows="2"
            style="margin-bottom: 10px"
            v-model="from[value.key]"
            placeholder="请输入"
            :disabled="from_type != 'add' ? value.disabled : false"
          ></el-input>
          <el-input
            v-else
            :type="value.type"
            style="width: 30%; margin-bottom: 10px"
            v-model="from[value.key]"
            placeholder="请输入"
            :disabled="from_type != 'add' ? value.disabled : false"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import divTable from "./components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data() {
    return {
      options: [
        { label: "普通", value: "0" },
        { label: "定制", value: "1" },
      ],
      selectList: [
        { label: "住宅IP", value: "resi" },
        { label: "城市IP", value: "city" },
        { label: "手机IP", value: "mobile" },
        { label: "机房IP", value: "dc" },
      ],
      level: "1",
      from_type: "",
      from: {},
      parmes: {
        level: "1",
      },
      loading: false,
      fromList: [
        {
          width: "50px",
          label: `ID`,
          key: `id`,
          disabled: true,
        },
        {
          label: `代理网络`,
          key: `proxyNetwork`,
          type: "select",
          transValue: (row) => {
            return this.selectList.find((item) => item.value === row).label;
          },
          disabled: true,
        },
        {
          label: `等级`,
          key: `level`,
          type: "radio",
          radioGroup: [
            { label: "普通", value: 1 },
            { label: "企业", value: 2 },
            { label: "高级", value: 3, disabled: true },
          ],
          transValue: (row) => {
            return row == 1 ? "普通" : row == 2 ? "企业" : "高级";
          },
          disabled: true,
        },
        {
          label: `流量大小(GB)`,
          key: `bandwidth`,
        },
        {
          label: `价格明细`,
          key: `detail`,
          type: "list",
          slot: "copy",
          defaultValue: [],
        },
        {
          label: `定制类型`,
          key: `isCustom`,
          type: "radio",
          radioGroup: [
            { label: "普通", value: 0 },
            { label: "定制", value: 1 },
          ],
          transValue: (row) => {
            return row == 0 ? "普通" : "定制";
          },
          trans: (val) => {
            return val ? `color:#22B14C;` : ``;
          },
          disabled: true,
        },
        {
          label: `包裹介绍`,
          key: `remark`,
          type: "list",
          slot: "copy",
          defaultValue: [],
        },
        {
          label: `状态`,
          key: `status`,
          type: "radio",
          radioGroup: [
            { label: "上线", value: 1 },
            { label: "下线", value: 0 },
          ],
          transValue: (row) => {
            return row == 1 ? "上线" : "下线";
          },
          trans: (val) => {
            return val ? `color:#2692ff;` : `color: red;`;
          },
        },
        {
          label: `管理员备注`,
          key: `adminDesc`,
          type: `textarea`,
        },
        {
          label: `创建时间/更新时间`,
          key: `rowWrap`,
          slot: "rowWrap",
          disabled: true,
          width: "158px",
        },
        // {
        //   label: `创建时间`,
        //   key: `createTime`,
        //   disabled: true,
        //   width: "150px",
        // },
        // {
        //   label: `更新时间`,
        //   key: `updateTime`,
        //   disabled: true,
        //   width: "150px",
        // },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "100px",
        },
      ],
      dialogList: [
        {
          label: `ID`,
          key: `id`,
          disabled: true,
        },
        {
          label: `代理网络`,
          key: `proxyNetwork`,
          type: "select",
          disabled: true,
        },
        {
          label: `等级`,
          key: `level`,
          type: "radio",
          radioGroup: [
            { label: "普通", value: 1 },
            { label: "企业", value: 2 },
            { label: "高级", value: 3, disabled: true },
          ],
          disabled: true,
        },
        {
          label: `定制类型`,
          key: `isCustom`,
          type: "radio",
          radioGroup: [
            { label: "普通", value: 0 },
            { label: "定制", value: 1 },
          ],
          disabled: true,
        },
        {
          label: `状态`,
          key: `status`,
          type: "radio",
          radioGroup: [
            { label: "上线", value: 1 },
            { label: "下线", value: 0 },
          ],
        },
        {
          label: `流量大小(GB)`,
          key: `bandwidth`,
        },
        {
          label: `价格明细`,
          key: `detail`,
          type: "list",
          defaultValue: [],
        },
        {
          label: `包裹介绍`,
          key: `remark`,
          type: "list",
          slot: "copy",
          defaultValue: [],
        },
        {
          label: `管理员备注`,
          key: `adminDesc`,
          type: `textarea`,
        },
        // {
        //   label: `创建时间/更新时间`,
        //   key: `rowWrap`,
        //   slot: "rowWrap",
        //   disabled: true,
        //   width: "158px",
        // },
        // {
        //   label: `创建时间`,
        //   key: `createTime`,
        //   disabled: true,
        //   width: "150px",
        // },
        // {
        //   label: `更新时间`,
        //   key: `updateTime`,
        //   disabled: true,
        //   width: "150px",
        // },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      // 弹窗
      dialog: false,
      addList: [
        "proxyNetwork",
        "level",
        "bandwidth",
        "detail",
        "isCustom",
        "remark",
        "status",
        "adminDesc",
      ],
      editList: [
        "proxyNetwork",
        "level",
        "bandwidth",
        "detail",
        "isCustom",
        "remark",
        "status",
        "adminDesc",
      ],
    };
  },
  created() {
    this.$dicts.getSystemDict();
  },
  async mounted() {
    this.getMessage(true);
  },

  watch: {},
  methods: {
    showPageList(arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    getSystemDict(type, row) {
      let arr = JSON.parse(window.localStorage.getItem(`dicts`));
      if (row) {
        return arr[type].find((x) => x.value == row)?.label;
      } else {
        return arr[type];
      }
    },
    handleClick(val) {
      this.parmes.level = this.level;
      this.getMessage(true);
    },
    changeList(obj, type) {
      this.from_type = type;
      if (type == `edit`) {
        this.dialog = true;
        this.from = Object.assign({}, obj);
      } else if (type == `del`) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delete(obj);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // async delete(obj) {
    //   const { code, data, msg } = await this.$apis
    //     .get(`/`, {
    //       id: obj.id,
    //     })
    //     .catch((e) => ({
    //       code: 1,
    //     }))
    //     .finally(() => {
    //       this.loading = false;
    //     });
    //   if (code == 0) {
    //     this.$message.success("删除成功");
    //     this.getMessage();
    //   } else {
    //     this.$message.error(msg);
    //   }
    // },
    async submit() {
      this.from.detail = this.from.detail.map((item, index) => {
        return {
          ...item,
          subId: index + 1,
          amount: item.amount * 100 || 0,
          discount: item.discount || 0,
          oriAmount: item.oriAmount * 100 || 0,
          price: item.price * 100 || 0,
        };
      });
      const { code, data, msg } = await this.$apis
        .post(
          `${
            this.from_type == "add"
              ? "/admin_add_rotate_package"
              : "/admin_mdf_rotate_package"
          }`,
          { ...this.from }
        )
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.getMessage();
        this.dialog = false;
        this.$message.success("操作成功");
      } else {
        this.$message.error(msg);
      }
    },
    fromInit() {
      let obj = {};
      this.fromList.forEach((elem) => {
        obj[elem.key] =
          (elem.defaultValue instanceof Function
            ? elem.defaultValue()
            : elem.defaultValue) || ``;
        obj[elem.key].length > 0 ? (obj[elem.key] = []) : "";
        if (elem.type == "radio") {
          obj[elem.key] = elem.radioGroup[0].value;
        }
      });

      this.from = obj;
    },
    reSetParmes() {
      this.parmes = {
        proxyNetwork: "",
        isCustom: "",
        level: this.parmes.level,
      };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .get(`/admin_get_rotate_package`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = data;
        this.fromData.forEach((elem) => {
          elem.detail = elem.detail.map((item) => {
            return {
              ...item,
              amount: (item.amount / 100).toFixed(1),
              oriAmount: (item.oriAmount / 100).toFixed(1),
              price: (item.price / 100).toFixed(1),
            };
          });
        });
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
::v-deep .el-date-editor.el-input {
  width: 100%;
}
.h30 {
  height: 30px;
}
.packages {
  flex-wrap: wrap;
}
::v-deep .el-input-group__append {
  padding: 0 6px;
}
.rowWrap {
  white-space: normal;
  margin: 0;
  text-align: left;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  font-weight: bold;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 2px solid #cbcccf;
  border-bottom: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-bottom: 2px solid transparent;
}
::v-deep
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:nth-child(2) {
  border-left: 2px solid #cbcccf;
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
::v-deep .el-form-item__label {
  text-align: left;
}
</style>
