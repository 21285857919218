<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div style="display: flex; gap: 10px;">
        <el-input placeholder="扩展名" v-model.trim="parmes.name" style="width: 150px; margin-right: 15px"
          @keydown.enter.native="getMessage()"></el-input>
          <el-input placeholder="扩展目录" v-model.trim="parmes.pluginDir" style="width: 280px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
          <el-select clearable v-model="parmes.categoryId" placeholder="扩展分类" @change="getMessage()"
          style="width: 150px; margin-right: 15px">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select clearable v-model="parmes.source" placeholder="扩展来源" @change="getMessage()"
          style="width: 150px;margin-right: 15px"">
          <el-option v-for=" item in sources" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <div style="display: flex; gap: 40px;">
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search"
          @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
        <!-- <el-button class="h30" type="primary" size="mini" icon="el-icon-refresh" :loading="checkLoading"
          @click="checkPlugsVersion">{{ checkLoading ? '检测中...' : '批量检测升级' }}</el-button> -->
        <!-- <el-button class="h30" type="danger" size="mini" icon="el-icon-s-order" @click="showPlugUpgrade = true">待升级列表({{
          upgradeTotal }})</el-button> -->
        </div>
      </div>
      <!-- <div class="flex-start-center" style="margin-left: 20px">
        <el-button class="h30" type="primary" size="mini" icon="el-icon-plus"
          @click="fromInit(); from_type = `add`; dialogShow = true; hasPluginRoute = true;">
          新增
        </el-button>
      </div> -->
    </div>

    <div-table :loading="loading" :fromList="tableColumns" :fromData="fromData" :pageSize="pageSize"
      :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template v-slot:pluginIcon="{ data }">
        <el-image @click="copyLink(data.rows.pluginIcon)" style="width: 30%; height: auto; cursor: pointer"
          :src="data.rows.pluginIcon" fit="cover" title="点击复制链接"></el-image>
      </template>
      <template v-slot:rowWrap="{ data }">
        <p class="rowWrap">
          {{ `${data.rows.createTime} / ${data.rows.updateTime}` }}
        </p>
      </template>
      <template v-slot:rowWrapName="{ data }">
        <p class="rowWrap">
          {{ data.rows.pluginName }}
        </p>
      </template>
      <template v-slot:rowWrapcategoryNames="{ data }">
        <p style="text-align: center;">{{ data.rows.categoryNames.join(",") || "-" }}</p>
      </template>
      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link :underline="false" type="danger" @click="changeList(data.rows, 'del')">删除</el-link>
        </div>
      </template>
    </div-table>

    <el-dialog :visible.sync="dialogShow" width="50%" :close-on-click-modal="false">
      <div slot="title" class="dialog-title">
        <span>{{ from_type == "add" ? "新增" : "编辑" }}</span>
        <div style="
            color: #ebb563;
            font-size: 14px;
            line-height: 1.5;
            font-weight: bold;
            width: 90%;
            margin: 0 0 -20px 9%;
          ">
          温馨提示：在标准的chrome扩展文件夹中，会包含一个manifest.json文件，此文件中含有扩展名称与版本等信息，请选择manifest.json所在目录的上上层目录，如：fgmbnmjmbjenlhbefngfibmjkpbcljaj。
        </div>
      </div>
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item v-for="(value, index) in showPageList(
          dialogList,
          from_type == 'add' ? addList : editList
        )" :key="index" :label="value.label">
          <el-select v-if="value.type == 'select'" v-model="dialogFrom.categoryIds" multiple :placeholder="value.key == 'sortNum' || value.key == 'categoryNames'
            ? '请输入'
            : '自动生成'
            ">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-upload v-else-if="value.key == 'pluginIcon'" class="avatar-uploader" :action="uploadUrl"
            :show-file-list="false" :limit="1" :before-upload="beforeUpload" :on-success="handleSuccess"
            :on-error="handleError">
            <img v-if="dialogFrom.pluginIcon" :src="dialogFrom.pluginIcon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div v-else-if="value.key == 'pluginRoute'">
            <div clsss="upload-demo" style="
                position: relative;
                width: 100%;
                height: 36px;
                text-align: center;
                line-height: 36px;
                color: #2692ff;
                border: 1px dashed #2692ff;
                border-radius: 6px;
                overflow: hidden;
              ">
              <input v-if="hasPluginRoute" type="file" :key="inputKey" webkitdirectory directory
                @change="beforeAvatarUpload" multiple style="
                  position: absolute;
                  left: 0;
                  margin: auto;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  z-index: 9;
                  cursor: pointer;
                " />

              <span v-if="hasPluginRoute" style="position: absolute; right: 0; left: 0">点击此处选择扩展，系统会自动上传并识别扩展信息</span>
              <span v-else @click="hasPluginRoute = !hasPluginRoute" style="
                  display: inline-block;
                  width: 96%;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                ">
                {{ dialogFrom.pluginRoute }}
              </span>
            </div>
            <el-progress v-if="isShowProgress" :percentage="progress"></el-progress>
          </div>

          <el-input v-else style="margin-bottom: 10px" v-model="dialogFrom[value.key]" :placeholder="value.key == 'sortNum' || value.key == 'categoryNames'
            ? '请输入'
            : '自动生成'
            " :disabled="value.disabled || false"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
    <plug-upgrade ref="upgrade" :visible.sync="showPlugUpgrade" :total.sync="upgradeTotal"></plug-upgrade>
    <div-table-detail :list="fromList" :data="tableData" :visible.sync="tableDetailShow">
      <template #rowWrap="{ data }">
        <span>{{ `${data.createTime} / ${data.updateTime}` }}</span>
      </template>
    </div-table-detail>
  </div>
</template>

<script>
import { ossConfig } from "@/constants";
import divTable from "@/views/huoshan/components/div-table.vue";
import plugUpgrade from './components/plug-upgrade.vue';
import divTableDetail from '../components/div-table-detail.vue';
import { clipboardText } from '@/utils';
import JSZip from "jszip";
import OSS from 'ali-oss';

const sources = [
  {
    label: '扩展推荐',
    value: 'sys'
  },
  {
    label: 'chrome应用商店',
    value: 'store'
  }
]
export default {
  components: {
    divTable,
    plugUpgrade,
    divTableDetail
  },
  data () {
    return {
      inputKey: 0,
      progress: 0,
      isShowProgress: false,
      uploadUrl: "",
      parmes: {
        status: 1// 上下架状态 1上架 2下架
      },
      loading: false,
      sources,
      tableDetailShow: false,
      tableData: null,
      fromList: [
        {
          label: `ID`,
          key: `id`,
          width: `60px`,
          slot: "id",
          tableView: true,
          click: (value) => {
            this.tableDetailShow = true;
            this.tableData = value;
          },
          trans: () => {
            return `color: blue; font-weight: bold; cursor: pointer;`;
          },
        },
        {
          label: `扩展目录`,
          key: `pluginDir`,
          slot: "copy",
          minWidth: `180px`,
          tableView: true,
        },
        {
          label: `扩展名称`,
          key: `pluginName`,
          slot: "rowWrapName",
          tableView: true,
        },
        {
          label: `扩展来源`,
          key: `source`,
          transValue: (val) => sources.find(item => item.value == val)?.label || '未知',
          tableView: true,
        },
        {
          label: `扩展ICON`,
          key: `pluginIcon`,
          slot: "pluginIcon",
          tableView: true,
        },
        {
          label: `扩展路由`,
          key: `pluginRoute`,
          slot: "copy",
        },
        {
          label: `扩展版本`,
          key: `pluginVersion`,
          tableView: true,
        },
        {
          label: `扩展描述`,
          key: `pluginDesc`,
          slot: "copy",
        },
        {
          label: `提供方`,
          key: `provider`,
          slot: "copy",
        },
        {
          label: `支持内核`,
          key: `supportCore`,
        },
        {
          label: `扩展状态`,
          key: `status`,
          tableView: true,
          transValue: (val) => {
            return val == 1 ? "上架中" : "已下架";
          },
          trans: (val) => {
            return val == 1 ? "color:#2692ff;" : "color: red;";
          },
        },
        {
          label: `创建时间/更新时间`,
          key: `rowWrap`,
          slot: "rowWrap",
          disabled: true,
          width: "158px",
          tableView: true,
        },
        {
          label: `升级时间`,
          key: `upgradeTime`,
          minWidth: `120px`,
          tableView: true,
        },
        {
          label: `扩展分类名`,
          key: `categoryNames`,
          slot: "rowWrapcategoryNames",
          tableView: true,
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "180px",
          tableOnly: true,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "扩展路由",
          key: "pluginRoute",
          type: "upload",
        },
        {
          label: "扩展目录ID",
          key: "pluginDir",
          disabled: true,
        },
        // {
        //   label: "扩展来源",
        //   key: "source",
        // },
        {
          label: "扩展名称",
          key: "pluginName",
        },
        {
          label: "扩展ICON",
          key: "pluginIcon",
          type: "upload",
        },
        {
          label: "扩展版本",
          key: "pluginVersion",
          disabled: true,
        },
        {
          label: "扩展描述",
          key: "pluginDesc",
        },
        {
          label: "提供方",
          key: "provider",
        },
        {
          label: "支持内核",
          key: "supportCore",
        },
        {
          label: "排序",
          key: "sortNum",
        },
        {
          label: "扩展分类",
          key: "categoryNames",
          type: "select",
        },
      ],
      addList: [
        "pluginDir",
        "pluginName",
        "pluginIcon",
        "pluginRoute",
        "pluginVersion",
        "pluginDesc",
        "provider",
        "sortNum",
        "categoryNames",
      ],
      editList: [
        "id",
        "pluginDir",
        "pluginName",
        "pluginIcon",
        "pluginRoute",
        "pluginVersion",
        "pluginDesc",
        "provider",
        "sortNum",
        "categoryNames",
      ],
      dialogFrom: {
        categoryIds: [],
        categoryName: [],
        pluginDesc: "",
        pluginDir: "",
        pluginName: "",
        pluginIcon: "",
        pluginRoute: "",
        pluginVersion: "",
        provider: "",
        sortNum: "",
        categoryNames: "",
      },
      options: [],
      ossConfig: {
        ...ossConfig
      },
      client: null,
      uploadData: {
        author: "Chrome Store",
        version: "",
        name: "",
        description: "",
        default_locale: "",
        icons: "",
        pluginDir: "",
      },
      hasPluginRoute: false,
      checkLoading: false,
      showPlugUpgrade: false,
      upgradeTotal: 0,
    };
  },
  computed: {
    tableColumns ({ fromList }) {
      // tableView: 显示在表格中
      // tableOnly: 仅在表格中显示
      return fromList.filter((item) => item.tableView || item.tableOnly)
    }
  },
  created () {
    this.$dicts.getSystemDict();
  },
  async mounted () {
    this.getMessage();
    this.initSelectList();
    this.initOSS();
  },
  watch: {
    dialogShow (val) {
      if (!val) {
        this.isShowProgress = false
        this.progress = 0
      }
    }
  },
  methods: {
    async beforeAvatarUpload (files, fileLists) {
      setTimeout(() => {
        this.inputKey++;
      });
      this.isShowProgress = true
      files = files.target.files;
      // 获取文件内容信息
      this.getOssinfo(this.uploadData, files);

      const zip = new JSZip();
      const filePromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const path = file.webkitRelativePath; // 使用webkitRelativePath获取文件相对路径

        filePromises.push(zip.file(path, file));
      }

      try {
        await Promise.all(filePromises);
        await this.initOSS()
        zip.generateAsync({ type: "blob" }).then((content) => {
          // 这里可以使用content，它是一个blob对象，表示ZIP文件
          // 例如，可以用来创建一个下载链接
          console.log(`content`, content);
          const blob = new Blob([content]);
          this.uploadFile(
            `public/plugin/${this.uploadData.pluginDir}.png`,
            this.uploadData.icons
          );
          this.uploadZipFile(
            `public/plugin/${this.uploadData.pluginDir}.zip`,
            blob
          );
        });
      } catch (error) {
        console.error("Error compressing files:", error);
      }
      // console.log(URL.createObjectURL(file.raw));
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   console.log("文件内容：", e.target.result);
      // };
      // reader.readAsDataURL(file.raw); // 读取文件内容
    },
    getOssinfo (obj, fileLists) {
      // console.log("fileLists", fileLists);
      let this_ = this;
      for (const iterator of fileLists) {
        const reader = new FileReader();
        if (iterator?.name == "manifest.json") {
          reader.readAsText(iterator, "UTF-8");
          reader.onload = function () {
            let data = JSON.parse(this.result);
            obj.pluginDir = iterator.webkitRelativePath
              .split(`/${data.version}_0`)[0]
              .split("/")
              .at(-1);
            // console.log("iterator.webkitRelativePath", iterator.webkitRelativePath);
            // console.log("obj.pluginDir", obj.pluginDir);
            obj.version = data.version;
            // obj.pluginDir == obj.version ? "" : obj.pluginDir;
            obj.name = data.name.replace(/__MSG_/, "").replace(/__/, "");
            obj.description =
              data.description?.replace(/__MSG_/, "").replace(/__/, "") || "";
            obj.default_locale = data.default_locale;
            let iconsLast = Object.values(data.icons);
            obj.icons = iconsLast[iconsLast.length - 1];

            this_.dialogFrom.pluginVersion = obj.version;
            this_.dialogFrom.pluginName = obj.name;
            this_.dialogFrom.pluginDesc = obj.description;
            this_.dialogFrom.pluginDir = obj.pluginDir;
            this_.dialogFrom.provider = obj.author;
            this_.dialogFrom.supportCore = "chrome";
            this_.dialogFrom.sortNum = "1";
            this_.dialogFrom.categoryIds = [1];

            this_.changeOssObj(fileLists, obj);
            console.log("obj", obj);
          };
        }
      }
    },
    changeOssObj (fileLists, obj) {
      let this_ = this;
      for (const iterator of fileLists) {

        if (
          iterator?.webkitRelativePath.endsWith(
            `${obj.pluginDir}/${obj.version}_0/${obj.icons}`.replace("//", "/").replace("/./", "/")
          )
        ) {

          obj.icons = iterator;
        }
        if (
          iterator?.webkitRelativePath.endsWith(
            `${obj.pluginDir}/${obj.version}_0/_locales/${obj.default_locale}/messages.json`.replace(
              "//",
              "/"
            )
          )
        ) {
          const reader = new FileReader();
          reader.readAsText(iterator, "UTF-8");
          reader.onload = function () {
            let data = JSON.parse(this.result);
            obj.name = data[obj.name]?.message || obj.name
            obj.description = data[obj.description]?.message || obj.description;
            this_.uploadData = obj;
            this_.dialogFrom.pluginVersion = obj.version;
            this_.dialogFrom.pluginName = obj.name;
            this_.dialogFrom.pluginDesc = obj.description;
            this_.dialogFrom.pluginDir = obj.pluginDir;
            this_.dialogFrom.provider = obj.author;
            this_.dialogFrom.supportCore = "chrome";
          };
        }
      }
    },
    beforeUpload (file) {
      console.log("🚀 ~ beforeUpload ~ file:", file);
      const fileName = this.uploadData.pluginDir
        ? `public/plugin/${this.uploadData.pluginDir}.png`
        : `public/plugin/${file.name}`;
      this.client.put(fileName, file).then(
        ({ res }) => {
          console.log("🚀 ~ beforeUpload ~ this.uploadData:", this.uploadData);
          this.dialogFrom.pluginIcon = res.requestUrls[0]?.split("?")[0];
          this.dialogFrom = { ...this.dialogFrom };
          console.log("upload success: %j", res);
        },
        (err) => {
          this.$message.error("上传ICON失败");
          console.error("upload error: %j", err);
        }
      );
      // 阻止默认上传行为
      return false;
    },
    handleSuccess (response, file, fileList) {
      console.log(
        "🚀 ~ handleSuccess ~ response, file, fileList:",
        response,
        file,
        fileList
      );
      // 处理上传成功的响应
    },
    handleError (err, file, fileList) {
      console.log(
        "🚀 ~ handleError ~ err, file, fileList:",
        err,
        file,
        fileList
      );
      // 处理上传失败的错误
    },
    async uploadFile (name, file) {
      try {
        // 上传文件到OSS
        this.client.put(name, file).then(
          ({ res }) => {
            this.dialogFrom.pluginIcon = res.requestUrls[0];
            this.dialogFrom = { ...this.dialogFrom };
          },
          (err) => {
            this.$message.error(err);
            console.error("upload error: %j", err);
          }
        );
      } catch (e) {
        console.error(e);
      }
    },
    async uploadZipFile (name, file) {
      try {
        // 上传文件到OSS
        this.client
          .multipartUpload(name, file, {
            // 获取分片上传进度、断点和返回值。
            progress: (p, cpt, res) => {
              this.progress = Math.ceil(p * 100);
              // 打印上传进度
              // console.log('progress:', p);
            },
          }).then(
            ({ res }) => {
              this.$message.success("上传扩展成功");
              this.dialogFrom.pluginRoute = res.requestUrls[0]?.split("?")[0];
              this.hasPluginRoute = false;
              console.log("🚀 ~ beforeAvatarUpload ~ res:", res);
            },
            (err) => {
              this.$message.error(err);
              console.error("upload error: %j", err);
            }
          );
      } catch (e) {
        console.error(e);
      }
    },
    updataOssConfig () {
      if (
        new Date(this.ossConfig.expiration).getTime() < new Date().getTime()
      ) {
        this.initOSS();
      }
    },
    async initOSS () {
      const { data, code, msg } = await this.$apis
        .get("/admin_get_oss_credential")

      if (code == 0) {
        this.ossConfig = { ...this.ossConfig, ...data };
        const {
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          securityToken,
        } = this.ossConfig;
        this.client = new OSS({
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          stsToken: securityToken,
        });
        this.uploadUrl = `https://${bucket}.${region}.aliyuncs.com`;
      }
    },
    showPageList (arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    async submit () {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add"
            ? "/admin_system_create_plugin"
            : "/admin_system_mdf_plugin"
          }`,
          this.from_type == "add"
            ? { ...this.dialogFrom }
            : {
              id: this.dialogFrom.id,
              pluginDir: this.dialogFrom.pluginDir,
              pluginName: this.dialogFrom.pluginName,
              pluginIcon: this.dialogFrom.pluginIcon,
              pluginRoute: this.dialogFrom.pluginRoute,
              pluginVersion: this.dialogFrom.pluginVersion,
              pluginDesc: this.dialogFrom.pluginDesc,
              provider: this.dialogFrom.provider,
              supportCore: this.dialogFrom.supportCore,
              status: this.dialogFrom.status,
              sortNum: this.dialogFrom.sortNum - 0,
              categoryIds: this.dialogFrom.categoryIds,
            }
        )
        .catch((err) => ({
          code: 1,
        }))
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.from_type = "edit";
        this.dialogShow = false;
        this.$message.success("操作成功");
        this.reSetParmes();
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    soldOut (row, type)  {

      const mdfPlugin = async () => {
          this.loading = true;
        const { code, data, msg } = await this.$apis
          .post("/admin_system_mdf_plugin", {
            id: row.id,
            status: row.status == 1 ? 2 : 1,
          })
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success("操作成功");
          this.getMessage();
        } else {
          this.$message.error(msg);
        }
        }

      if (row.status === 2) {
        return mdfPlugin().finally(() => {
          this.loading = false;
        });
      }

      this.$confirm("确认下架该插件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(mdfPlugin)
        .finally(() => {
          this.loading = false;
        });
    },
    delete (row, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          const { code, data, msg } = await this.$apis
            .get("/admin_system_plugin_del", {
              id: row.id,
            })
            .catch((e) => ({
              code: 1,
            }))
            .finally(() => {
              this.loading = false;
            });
          if (code == 0) {
            this.$message.success("操作成功");
            this.getMessage();
          } else {
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async changeList (row, type) {
      this.updataOssConfig();
      if (type == "edit") {
        this.hasPluginRoute = false;
        this.from_type = "edit";
        this.dialogShow = true;
        this.dialogFrom = row;
        this.loading = false;
      } else if (type == "freeze") {
        this.soldOut(row, type);
      } else if (type == "del") {
        this.delete(row, type);
      } else if (type === "upgrade") {
        const { code, data, msg } = await this.$apis.get(
        `/admin_user_check_all_plugins`, {
          pluginId: row.id,
      }
      );
      this.$message.success(msg);
      }
    },
    async initSelectList () {
      const { data, code, msg } = await this.$apis.get(
        `/admin_plugin_category`
      );
      if (code == 0) {
        this.options = data.map((elem) => ({
          label: elem.name,
          value: elem.id,
        }));
      }
    },
    copyLink (val) {
      clipboardText(val)
    },
    reSetParmes () {
      this.parmes = { status: 1 };
    },
    fromInit (parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = {
        provider: "Chrome Store",
      };
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_system_plugin_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        // this.fromData = rows.filter((x) => x.status == 1);
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    async checkPlugsVersion () {
      this.checkLoading = true;
      const { code } = await this.$apis.get('/admin_user_check_all_plugins')
        .catch(e => ({ code: 1 }))
      this.checkLoading = false;
      if (code == 0) {
        this.$message.success('检测完成~');
        this.$refs.upgrade.reSet();
      } else {
        this.$message.error('检测异常！');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.operate {
  justify-content: space-around;
}

.h30 {
  height: 30px;
}

.avatar {
  width: 36px;
}

.rowWrap {
  white-space: normal;
  margin: 0;
  text-align: left;
}
</style>
