<template>
  <el-drawer title="窗口详情" :visible.sync="_visible" :before-close="handleClose" size="50%">
    <div ref="drawer" v-if="data" class="flex-d" style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px">
      <el-descriptions title="详细信息" direction="vertical" :column="2" border>
        <el-descriptions-item v-for="(item, index) in _list" :key="index" :label="item.label"
          :label-class-name="(item.label == 'cookie' || item.label == 'User Agent') ? 'bright' : 'label-color'">
          <slot :name="item.slot" :data="data">
            <el-tooltip class="item" effect="dark" placement="top-start"
              :content="JSON.stringify(item.transValue?.(data[item.key]) || data[item.key])">
              <span :style="item.trans && item.trans(data[item.key])">{{ item.transValue?.(data[item.key]) ||
                data[item.key] || "-" }}</span>
            </el-tooltip>
          </slot>
          <icon-copy class="icon-copy" :value="data[item.key]" v-if="!isEmpty(data[item.key])"></icon-copy>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>
<script>
import iconCopy from "@/views/huoshan/components/icon-copy.vue";
import { isEmpty } from '@/utils';

export default {
  name: "TableDetail",
  components: {
    iconCopy
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _list ({ list }) {
      return list.filter(item => !item.tableOnly)
    },
    _visible: {
      get ({ visible }) {
        return visible
      },
      set (value) {
        this.$emit("update:visible", value)
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleClose (done) {
      return done();
    },
    isEmpty (target) {
      return isEmpty(target)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.label-color) {
  color: #303133 !important;
  font-weight: bold !important;
}

.icon-copy {
  margin-left: 5px;
}
</style>