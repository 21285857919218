<template>
  <div class="home pages-store">

    <div class="flex-sw-center" style="height: 50px; width: 100%;">
      <div class="flex-start-center" style="gap: 20px;">
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.userName"
          clearable
          style="width: 180px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-input
          placeholder="订单号"
          v-model.trim="parmes.orderNo"
          clearable
          style="width: 220px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <!-- 支付状态 -->
        <el-select
          placeholder="支付状态"
          v-model.trim="parmes.payStatus"
          clearable
          style="width: 180px"
          @change="getMessage(true)"
        >
          <el-option
            v-for="item in payStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <!-- 订单类型 -->
        <el-select
          placeholder="订单类型"
          v-model.trim="parmes.orderType"
          clearable
          style="width: 180px"
          @change="getMessage(true)"
        >
          <el-option
            v-for="item in orderType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
      </el-select>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button  type="success"
          size="mini"
          icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button
        type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
      </div>

    </div>

    <div-table
      :loading="loading"
      :fromList="fromList.filter(item => !item.hideInTable)"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    </div-table>
    <el-drawer
      title="购买记录详情"
      :visible.sync="drawer"
      size="50%"
    >
      <div
        ref="drawer"
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          title="详细信息"
          direction="vertical"
          :column="windowWidth >= 1912 ? 3 : windowWidth >= 1600 ? 2 : 1"
          border
        >
          <el-descriptions-item
            v-for="(item, index) in fromList"
            :key="index"
            :label="item.label"
            :label-class-name="
              (item.label == 'cookie' || item.label == 'User Agent')
                ? 'bright'
                : 'label-color'
            "
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :content="
                item.transValue == undefined
                  ? toStringWord(recordObj[item.key])
                  : toStringWord(item.transValue(recordObj[item.key]))
              "
            >
              <div :style="item.trans && item.trans(recordObj[item.key])">
                <div v-html="item.transValue == undefined
                    ? recordObj[item.key] || '-'
                    : item.transValue(recordObj[item.key]) || '-'"></div>
              </div>
            </el-tooltip>

            <i
              v-show="!isEmpty(recordObj[item.key])"
              class="el-icon-document-copy"
              @click="copy(recordObj[item.key])"
            ></i>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { tableObj } from "@/utils/div";
import divTable from "@/views/huoshan/components/div-table.vue";
import { purchaseType, purchaseTypeMap, orderTypeMap, orderType } from './constants'

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // 保留两位小数
});

// 支付状态 TRADE_SUCCESS: 支付成功,TRADE_CLOSED：交易关闭,WAIT_BUYER_PAY：待支付,TRADE_FAIL：自动扣款失败
const payStatus = [
  {
    label: "支付成功",
    value: "TRADE_SUCCESS",
  },
  {
    label: "交易关闭",
    value: "TRADE_CLOSED",
  },
  {
    label: "待支付",
    value: "WAIT_BUYER_PAY",
  },
  {
    label: "自动扣款失败",
    value: "TRADE_FAIL",
  },
];

const payStatusMap = payStatus.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});


// 支付方式: Balance,Alipay,USDT,Wechat,Card
const payType = [
  {
    label: "Roxy钱包",
    value: "Balance",
  },
  {
    label: "支付宝",
    value: "Alipay",
  },
  {
    label: "USDT",
    value: "USDT",
  },
  {
    label: "微信",
    value: "Wechat",
  },
  {
    label: "信用卡",
    value: "Card",
  },
];

const payTypeMap = payType.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});


// orderStatus 0:处理中,1:已完成,2:关闭
const orderStatus = [
  {
    label: "处理中",
    value: "0",
  },
  {
    label: "已完成",
    value: "1",
  },
  {
    label: "关闭",
    value: "2",
  },
];

const orderStatusMap = orderStatus.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

// 是否自动购买和续费,1:系统自动 0:人工
const isAuto = [
  {
    label: "系统自动",
    value: "1",
  },
  {
    label: "人工",
    value: "0",
  },
];

const isAutoMap = isAuto.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

// 子订单类型upgrade:升级, purchase：购买
const subOrderType = [
  {
    label: "升级",
    value: "upgrade",
  },
  {
    label: "购买",
    value: "purchase",
  },
]

const subOrderTypeMap = subOrderType.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export default {
  components: {
    divTable,
  },
  data() {
    return {
      payStatus,
      orderType,
      windowWidth: document.body.clientWidth,
      recordObj: {},
      window_details_keys: [],
      drawer: false,
      direction: "rtl",
      child_table: new tableObj(),
      parmes: {},
      loading: false,
      windowsMessage: {},

      fromList: [
      {
          label: `ID`,
          key: `id`,
          width: `110px`,
          slot: "id",
          enableCopy: true,
          click: async (value) => {
              this.drawer = true;
              this.recordObj = value;
          },
          trans: (value) => {
              return `color: blue;font-weight: bold;cursor: pointer;`;
          },
        },
        {
          label: `用户名`,
          key: `userName`,
        },
        {
          label: `订单号`,
          key: `orderNo`,
          width: `200px`,
        },
        {
          label: `订单类型`,
          key: `orderType`,
          transValue: (val) => {
            return orderTypeMap[val] || val;
          },
        },
        {
          label: `订单子类型`,
          key: `subOrderType`,
          transValue: (val) => {
            return subOrderTypeMap[val] || val;
          },
        },
        {
          label: `订阅等级`,
          key: `subLevel`,
        },
        {
          label: `购买类型`,
          key: `purchaseType`,
          transValue: (val) => {
            return purchaseTypeMap[val] || val;
          },
        },
        {
          label: `购买月数`,
          key: `subscriptionMonths`,
        },
        {
          label: `购买详情`,
          key: `purchaseInfoCount`,
          slot: "purchaseInfoCount",
        },
        {
          label: '旧购买数量',
          key: 'oldPurchaseCount',
        },
        {
          label: `订阅开始时间`,
          key: `startDate`,
          width: 180,
        },
        {
          label: `订阅结束时间`,
          key: `endDate`,
          width: 180,
        },
        {
          label: `原始金额($)`,
          key: `originalAmount`,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `折扣比例(%)`,
          key: `discountPercentage`,
          transValue: (val, row) => {
            return row.purchaseType === 'wallet' ? '--' : `${val} %`
          },
        },
        {
          label: `折扣金额($)`,
          key: `discountAmount`,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `单价($)`,
          key: `singlePrice`,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `支付金额($)`,
          key: `actualAmount`,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `支付状态`,
          key: `payStatus`,
          transValue: (val) => {
            return payStatusMap[val] || val;
          },
        },
        {
          label: `支付方式`,
          key: `payType`,
          transValue: (val) => {
            return payTypeMap[val] || val;
          },
        },
        {
          label: `支付时间`,
          key: `payTime`,
        },
        {
          label: `订单状态`,
          key: `orderStatus`,
          transValue: (val) => {
            return orderStatusMap[val] || val;
          },
        },
        {
          label: `是否自动续费`,
          key: `isAuto`,
          transValue: (val) => {
            return isAutoMap[val] || val;
          },
        },
        {
          label: `是否激活`,
          key: `isEffective`,
          transValue: (val) => {
            return val === 1 ? '是' : '否';
          },
        },
        {
          label: `上游单号`,
          key: `sgdAlipayTradeNo`,
          hideInTable: true,
        },
        {
          label: `上游币种`,
          key: `orderCurrency`,
          hideInTable: true,
        },
        {
          label: `真实金额($)`,
          key: `realAmount`,
          hideInTable: true,
          transValue: (val) => {
            return formatter.format(val / 100);
          },
        },
        {
          label: `支付渠道`,
          key: `payChannel`,
          hideInTable: true,
        },
        {
          label: `订阅客户ID`,
          key: `customerId`,
          hideInTable: true,
        },
        {
          label: `订阅客户名称`,
          key: `customerName`,
          hideInTable: true,
        },
        {
          label: `订阅客户邮箱`,
          key: `customerEmail`,
          hideInTable: true,
        },
        {
          label: `下一次自动扣款时间`,
          key: `nextPaymentTime`,
          hideInTable: true,
        },
        {
          label: `信用卡品牌`,
          key: `cardBrand`,
          hideInTable: true,
        },
        {
          label: `信用卡后四位`,
          key: `cardLast4`,
          hideInTable: true,
        },
        {
          label: `备注`,
          key: `remarks`,
          hideInTable: true,
        },
        {
          label: `创建时间`,
          key: `createTime`,
          width: 180,
          hideInTable: true,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
          width: 180,
          hideInTable: true,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted() {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    toStringWord(val) {
      return JSON.stringify(val) || "-";
    },
    reSetParmes() {
      this.parmes = {
        userId: "",
      };
    },
     // 为空方法
     isEmpty(target) {
      const res = Object.prototype.toString.call(target);
      if (res === "[object Object]") {
        return !(Object.keys(target).length > 0);
      }
      if (res === "[object Array]" || res === "[object String]") {
        return !(target.length > 0);
      }
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis.get(`/admin_get_purchase_record`, {
        page_index: this.pageValue,
        page_size: this.pageSize,
        ...this.parmes,
      });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      }else{
        this.$message.error(msg);
      }

      this.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
</style>
