<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = [];
    if (icon) {
      vnodes.push(<svg-icon icon-class={icon} style="flex-shrink:0" />);
    }

    if (title) {
      // if (title.length > 5) {
      //   // vnodes.push(
      //   //   <span slot="title" title={title}>
      //   //     {title}
      //   //   </span>
      //   // );

      //   vnodes.push(
      //     <el-tooltip
      //       class="item"
      //       effect="dark"
      //       content={title}
      //       placement="right"
      //     >
      //       <div
      //         class="flex-start-center"
      //         style="max-width:5em;margin-left:3.5px"
      //       >
      //         {title}
      //       </div>
      //     </el-tooltip>
      //   );
      // } else {
      //   vnodes.push(<span slot="title">{title}</span>);
      // }
      vnodes.push(
        <span slot="title" title={title}>
          {title}
        </span>
      );
    }
    return vnodes;
  },
};
</script>
