var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-body" },
    [
      _c(
        "el-input",
        {
          staticClass: "icon-search",
          attrs: { clearable: "", placeholder: "请输入图标名称" },
          on: { clear: _vm.filterIcons, input: _vm.filterIcons },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v
            },
            expression: "name",
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-search el-input__icon",
            attrs: { slot: "suffix" },
            slot: "suffix",
          }),
        ]
      ),
      _c("div", { staticClass: "icon-list" }, [
        _c(
          "div",
          { staticClass: "list-container" },
          _vm._l(_vm.iconList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "icon-item-wrapper",
                on: {
                  click: function ($event) {
                    return _vm.selectedIcon(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  { class: ["icon-item", { active: _vm.activeIcon === item }] },
                  [
                    _c("svg-icon", {
                      staticStyle: { height: "25px", width: "16px" },
                      attrs: { "icon-class": item, "class-name": "icon" },
                    }),
                    _c("span", [_vm._v(_vm._s(item))]),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }